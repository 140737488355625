import React, { useState, useEffect, useCallback } from 'react';
import axios from "axios";
import { useMutation, gql } from '@apollo/client';
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Memory from '../../Utils/Memory';

// #138954
const styles = theme => ({
  main: {
    backgroundColor: '#54c6a4',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#34ab48',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#34ab48',
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
  },
  spendy: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    width: '100%',
  },
});

const UPDATE_USER = gql`mutation UpdateUser($uid: uuid!, $date: timestamptz!) {
  update_user_by_pk(pk_columns: {id: $uid}, _set: {last_login: $date}) {
    id
  }
}`;

export default function Login(props) {
  const { email, id, token } = Memory;
  const [disabled, setDisabled] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState(!!props.location && !!props.location.state && !!props.location.state.message ? props.location.state.message : null);
  const [input, setInput] = useState({email: '', password: ''});
  const [seconds, setSeconds] = useState(0);
  const today = new Date().toUTCString();

  const [updateUser] = useMutation(UPDATE_USER);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      setDisabled(true);
      signIn()
    }
  }, [input]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
      // attach the event listener
      document.addEventListener('keydown', handleKeyPress);

      // remove the event listener
      return () => {
          document.removeEventListener('keydown', handleKeyPress);
      };
  }, [handleKeyPress]);

  const handleSnackClose = () => {
    setRedirect(false);
    setMessage(null);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInput({ ...input, [name]: value});
  };

  async function signIn() {
    const login_response = await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/login`, {
      email: input.email,
      password: input.password
    }, { withCredentials: true });

    setDisabled(true);

    if (login_response.data.error) {
      setDisabled(false);
      setMessage(login_response.data.error);
    } else {
      const token_response = await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/token/refresh`, {}, { withCredentials: true });

      Memory.email = input.email;
      Memory.token = token_response.data.access_token;
      Memory.id = token_response.data.id;

      setDisabled(false);
      setRedirect(true);
      setInput({email: '', password: ''});
      updateUser({ variables: {date: today, uid: Memory.id }});
    }
  }

  if (redirect || (!!email && !!token && !!id)) {
    updateUser({ variables: {date: today, uid: id }});
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: '#2C3E50',
        }}
      >
        <Grid item='true' xs={10} sm={6} md={3}>
          <Fade in timeout={1200}>
            <Box>
              {/* <Typography variant="h3">
                Spendy
              </Typography> */}
              <Paper sx={{p:3}}>
                <Typography variant="h3" align='center'>
                  Login
                </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    key="email"
                    name="email"
                    type="email"
                    label="Email"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    key="password"
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    disabled={disabled}
                    type="submit"
                    fullWidth
                    variant="contained"
                   
                    onClick={signIn}
                  >
                    Login
                  </Button>
                  <Grid container>
                    <Grid item='true' xs>
                      <Link to="/signup">
                      <Button>
                        Sign Up
                      </Button>
                      </Link>
                    </Grid>
                    <Grid item='true'>
                      <Link to="/reset_password">
                      <Button>
                        reset password
                      </Button>
                      </Link>
                    </Grid>
                  </Grid>
              </Paper>
            </Box>
          </Fade>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!message}
          onClose={handleSnackClose}
          message={message}
        />
      </Grid>
    </Box>
  );
};