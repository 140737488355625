import * as React from 'react';
import axios from "axios";
import { useMutation, useQuery, gql } from '@apollo/client';
import { styled, useTheme } from '@mui/material/styles';
import Memory from '../../Utils/Memory';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IntegrationAuthInput from './IntegrationAuthInput';

const drawerWidth = 400;

const IMPORT_CLIENTS = gql`mutation ImportClients($orgId: uuid!) {
    importClients(args: {orgId: $orgId}) {
      error
      message
    }
  }`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function ImportClientsDrawer(props) {
    const { orgId, open, setOpen, refetchQueries } = props;
    const [processing, setProcessing] = React.useState(false);
    const [snack, setSnack] = React.useState(false);
  
    const theme = useTheme();

    const [importForms] = useMutation(IMPORT_CLIENTS, {
        refetchQueries: refetchQueries
    });

    const handleClose = () => {
        setProcessing(false);
        setOpen(false);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnack(false);
    };

    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                height: 'auto',
                width: drawerWidth,
                marginTop: '25vh',
            },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <Divider />
            <DrawerHeader>
                <IconButton onClick={handleClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Typography variant="h6">
                    Integrations
                </Typography>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem>
                    <Button variant="contained" size='small' sx={{ mr: 4 }} onClick={() => importForms({ variables: { orgId: orgId }})}>Import</Button>
                    <ListItemText primary='Platinum' />
                </ListItem>
            </List>
            <Divider />
            <Snackbar open={snack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {}
                </Alert>
            </Snackbar>
        </Drawer>               
    );
}