import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Send from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ClientsInput from '../Forms/ClientsInput';
import ClientAppointmentsTable from '../Widgets/ClientAppointmentsTable';
import ClientTransactionsTable from '../Widgets/ClientTransactionsTable';
import WaitingRoomResourceTable from '../Widgets/WaitingRoomResourceTable';
import WaitingRoomLobbyTable from '../Widgets/WaitingRoomLobbyTable';

const GET_ARRIVED = gql`
query GetArrivalQueue($orgId: uuid!, $start: timestamptz!, $end: timestamptz!) {
  resource_queue(where: {organization_id: {_eq: $orgId}, done_time: {_is_null: true}, called_time: {_is_null: true}, created_at: {_gte: $start, _lte: $end}}, order_by: {created_at: asc}) {
    id
    status
    start_time
    done_time
    called_time
    transaction_time
    group_code
    apt_code
    created_at
    first_name
    last_name
    client {
      id
      first_name
      last_name
    }
    appointment_type {
        id
        name
    }
    resource {
      id
    }
  }
  resource(where: {organization_id: {_eq: $orgId}, enable_routing: {_eq: true}}, order_by: {name: asc}) {
    id
    name
    status
    calling_text
    resource_queues(where: {called_time: {_is_null: false}, created_at: {_gte: $start, _lte: $end}, _or: [{done_time: {_is_null: true}}, {transaction_time: {_is_null: true}}]}) {
        id
        transaction_time
        called_time
        done_time
        first_name
        last_name
        client {
          first_name
          last_name
        }
        appointment_type {
            id
            name
        }
      }
  }
  getWaitTimes(args: {orgId: $orgId, start: $start, end: $end}) {
    avg_lobby_time_min
    avg_resource_time_min
  }
}`;

const GET_CLIENT_DETAILS = gql`
query GetClientDetails($id: uuid!) {
    clients_by_pk(id: $id) {
        id
        first_name
        last_name
        email
        phone
        status
        note
        medical_note
        source_id
        client_forms(order_by: {created_at: asc}) {
          id
          status
          submitted_date
          organization_id
          integration_provider_id
          form {
            name
          }
          integration_provider {
            name
          }
          questions(where: {attachments: {}}) {
            attachments {
              id
              file_name
              source_id
              content_type
              url
            }
          }
        }
        client_forms_aggregate {
          aggregate {
            count
          }
        }
        appointments(order_by: {appointment: {start: desc}}) {
            appointment {
              id
              start
              status
              organization_id
              source_id
              appointment_type {
                id
                name
                minute_duration
              }
            }
        }
        appointments_aggregate {
            aggregate {
                count
            }
        }
        transactions(order_by: {date: desc}) {
            id
            date
            amount
            insurance_charge_amount
            insurance_approved_amount
            service {
              name
            }
        }
        transactions_aggregate {
            aggregate {
                count
            }
        }
    }
}`;

const UPDATE_CLIENT_NOTE = gql`mutation UpdateClientNote($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {note: $text}) {
      created_at
    }
}`;

const CREATE_REMOTE_ACTION = gql`mutation SyncPatientNotes($orgId: uuid!, $name: String!, $status: String!, $data: String!) {
    insert_remote_action_one(object: {organization_id: $orgId, name: $name, status: $status, data: $data}) {
        id
    }
}`;

const GET_CLIENT_MESSAGES = gql`
query GetClientMessages($orgId: uuid!, $clientId: uuid!, $page: Int!, $perPage: Int!) {
    getClientMessages(args: {orgId: $orgId, clientId: $clientId, page: $page, perPage: $perPage}) {
        messages {
            text
            sentFromOrg
            createdAt
        }
    }
}`;

const SEND_SMS = gql`
mutation SendSMS($orgId: uuid!, $clientId: uuid!, $clientSourceId: String, $msg: String!) {
    sendSMS(args: {orgId: $orgId, clientId: $clientId, clientSourceId: $clientSourceId, msg: $msg}) {
        message
        error
    }
}`;

export default function SidePanel(props) {
    const { event, setEvent, setOpenSidePanel } = props;
    const chatAreaRef = React.useRef();
    const today = new Date();
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    today.setHours(0, 0, 0);
    tomorrow.setHours(0, 0, 0);
    const [start, setStart] = React.useState(today);
    const [end, setEnd] = React.useState(tomorrow);
    const [index, setIndex] = React.useState(0);
    const [clientDetailsIndex, setClientDetailsIndex] = React.useState(0);
    const [client, setClient] = React.useState(null);
    const [newMessage, setNewMessage] = React.useState('');

    const [sendSMS] = useMutation(SEND_SMS, {
        refetchQueries: [
          {
              query: GET_CLIENT_MESSAGES,
              variables: { orgId: Memory.orgId, clientId: client != null ? client.id : '', page: 1, perPage: 25 },
          }
        ]
    });

    const { data, startPolling } = useQuery(GET_ARRIVED, {
        variables: { orgId: Memory.orgId, start: start, end: end },
        fetchPolicy: "no-cache",
    });

    startPolling(500);

    const { data: clientData } = useQuery(GET_CLIENT_DETAILS, {
        variables: { id: client != null ? client.value : !!event && !!event.clientIds && event.clientIds.length > 0 ? event.clientIds[0].value : '' },
    });

    const { data: clientMessages, startPolling: pollingClientMessages } = useQuery(GET_CLIENT_MESSAGES, {
        variables: { orgId: Memory.orgId, clientId: !!event && !!event.clientIds && event.clientIds.length > 0 ? event.clientIds[0].value : client != null ? client.value : '', page: 1, perPage: 25 },
        fetchPolicy: "no-cache",
    });

    pollingClientMessages(5000);

    const [updateClientNote] = useMutation(UPDATE_CLIENT_NOTE, { refetchQueries: [
        {
            query: GET_CLIENT_DETAILS,
            variables: { id: client != null ? client.value : !!event && !!event.clientIds && event.clientIds.length > 0 ? event.clientIds[0].value : '' },
        }
    ]});

    const [addRemoteAction] = useMutation(CREATE_REMOTE_ACTION, { refetchQueries: [
        {
            query: GET_CLIENT_DETAILS,
            variables: { orgId: Memory.orgId },
        }
    ]});

    React.useEffect(() => {
        if (!!chatAreaRef && !!chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [clientMessages, clientDetailsIndex]);

    const handleSetClient = newValue => {
        setEvent(null);
        setClientDetailsIndex(0);
        setClient(newValue);
        setNewMessage('');
    }

    const getTimeText = time => {
        const now = new Date()
        const dateTime = new Date(time)

        if (Math.floor((now - dateTime)/1000/60) === 0) {
            return 'just now'
        } else if (Math.floor((now - dateTime)/1000/60) <= 20) {
            return `${Math.floor((now - dateTime)/1000/60)} min`
        } else if (Math.floor((now - dateTime)/1000/60/60) <= 23){
            return dateTime.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})
        }

        return dateTime.toLocaleTimeString([], { month: 'short', day: 'numeric' }).split(',')[0]
    }

    return (
        <React.Fragment>
            <Toolbar
                sx={{
                    mb: { xs: 2 },
                    pr: { xs: 0 },
                    pl: { xs: 0 },
                }}
            >
                <Button startIcon={<ChevronRightIcon /> } onClick={() => {setOpenSidePanel(false); setEvent(null)}} sx={{ width: 380 }}>
                    Hide Side Panel
                </Button>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                </Typography>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button variant={index === 0 ? 'contained' : 'outlined'} onClick={() => setIndex(0)}>Client</Button>
                    <Button variant={index === 1 ? 'contained' : 'outlined'} onClick={() => setIndex(1)}>Queue</Button>
                </ButtonGroup>
            </Toolbar>
            {index === 1 && 
                <React.Fragment>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        {`Waiting`}
                    </Typography>
                    <Typography
                        sx={{ flex: '1 1 100%', mb: 3 }}
                        color="inherit"
                        variant="h7"
                        component="div"
                    >
                        {!!data && !!data.getWaitTimes && !!data.getWaitTimes.avg_lobby_time_min ? `average time: ${data.getWaitTimes.avg_lobby_time_min} min` : ''}
                    </Typography>
                    <WaitingRoomLobbyTable data={!!data && !!data.resource_queue ? data.resource_queue : []} refetchQueries={[
                        {
                            query: GET_ARRIVED,
                            variables: { orgId: Memory.orgId, start: start, end: end },
                        }
                    ]}/>
                    <Typography
                        sx={{ flex: '1 1 100%', mt: 8 }}
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        {`In Progress`}
                    </Typography>
                    <Typography
                        sx={{ flex: '1 1 100%', mb: 3 }}
                        color="inherit"
                        variant="h7"
                        component="div"
                    >
                        {!!data && !!data.getWaitTimes && !!data.getWaitTimes.avg_resource_time_min ? `average room time: ${data.getWaitTimes.avg_resource_time_min} min` : ''}
                    </Typography>
                    <WaitingRoomResourceTable data={data.resource} refetchQueries={[
                        {
                            query: GET_ARRIVED,
                            variables: { orgId: Memory.orgId, start: start, end: end },
                        }
                    ]}/>
                </React.Fragment>
            }
            {index === 0 && 
                <React.Fragment>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <ClientsInput setClient={handleSetClient} />
                        </Box>
                    </Toolbar>
                    {/* {!!eventData && 

                    } */}
                    {!!clientData && 
                        <React.Fragment>
                            <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">First Name</TableCell>
                                            <TableCell align="left">Last Name</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Phone</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!!clientData && !!clientData.clients_by_pk && 
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={clientData.clients_by_pk.id}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {clientData.clients_by_pk.first_name}
                                                </TableCell>
                                                <TableCell align="left">{clientData.clients_by_pk.last_name}</TableCell>
                                                <TableCell align="left">{clientData.clients_by_pk.email}</TableCell>
                                                <TableCell align="left">{clientData.clients_by_pk.phone}</TableCell>
                                                <TableCell align="center">{clientData.clients_by_pk.status}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Button variant={'contained'} onClick={() => addRemoteAction({ variables: { orgId: Memory.orgId, name: "UPDATE_PATIENT_NOTE", status: "CREATED", data: `{"client_id":"${clientData.clients_by_pk.source_id}", "note": "${clientData.clients_by_pk.note}"}` }})}>Sync Notes</Button>

                            <TextField
                                id="filled-multiline-static"
                                label="Office Notes"
                                multiline
                                rows={10}
                                fullWidth
                                variant="filled"
                                color="warning"
                                focused
                                value={!!clientData && !!clientData.clients_by_pk && !!clientData.clients_by_pk.note ? clientData.clients_by_pk.note : ''}
                                onChange={(e) => {
                                    updateClientNote({ variables: { clientId: client != null ? client.value : !!event && !!event.clientIds && event.clientIds.length > 0 ? event.clientIds[0].value : '', text: e.target.value }});
                                }}
                                sx={{ mb: 4, mt: 2 }}
                            />

                            <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ mb: 4 }}>
                                <Button variant={clientDetailsIndex === 0 ? 'contained' : 'outlined'} onClick={() => setClientDetailsIndex(0)}>Appointments</Button>
                                <Button variant={clientDetailsIndex === 1 ? 'contained' : 'outlined'} onClick={() => setClientDetailsIndex(1)}>Transactions</Button>
                                <Button variant={clientDetailsIndex === 2 ? 'contained' : 'outlined'} onClick={() => setClientDetailsIndex(2)}>Messages</Button>
                            </ButtonGroup>

                            {clientDetailsIndex === 0 && 
                                <ClientAppointmentsTable
                                    data={clientData}
                                    refetchQueries={[{
                                        query: GET_CLIENT_DETAILS,
                                        variables: { id: client != null ? client.value : !!event && !!event.clientIds && event.clientIds.length > 0 ? event.clientIds[0].value : '' },
                                    }]}
                                    fullDetails={true}
                                    displayTitle={false}
                                />
                            }
                            {clientDetailsIndex === 1 && <ClientTransactionsTable data={clientData} fullDetails={true} displayTitle={false} />}
                            {clientDetailsIndex === 2 && 
                                <React.Fragment>
                                    <List ref={chatAreaRef} sx={{ bgcolor: '#e7e7e7', maxHeight: 500, overflowY: 'auto', p: 4, pb: 0}}>
                                        {!!clientMessages && !!clientMessages.getClientMessages && !!clientMessages.getClientMessages.messages && clientMessages.getClientMessages.messages.map((row) => {
                                            return (
                                                <React.Fragment>
                                                    {row.sentFromOrg === true ?
                                                        <React.Fragment>
                                                            <ListItem disablePadding>
                                                                <ListItemText />
                                                                <ListItemText align='left' primary={`${row.text}`} sx={{ p: 2, width: '20%', bgcolor: '#54c6a4' }} />
                                                            </ListItem>
                                                            <ListItem disablePadding sx={{ mb: 2}}>
                                                                <ListItemText align='right' secondary={getTimeText(row.createdAt)} sx={{ textAlign: 'end' }} />
                                                            </ListItem>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <ListItem disablePadding>
                                                                <ListItemText align='left' primary={`${row.text}`} sx={{ p: 2, width: '20%', bgcolor: '#fff' }} />
                                                                <ListItemText />
                                                            </ListItem>
                                                            <ListItem disablePadding sx={{ mb: 2}}>
                                                                <ListItemText align='left' secondary={getTimeText(row.createdAt)} sx={{ textAlign: 'start' }} />
                                                            </ListItem>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            )
                                        }).reverse()}
                                    </List>
                                    <Box sx={{ p: 4, pt: 0, bgcolor: '#e7e7e7'}}>
                                        <TextField
                                            fullWidth
                                            label='New Message'
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            sx={{ bgcolor: '#fff' }}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                sendSMS({ variables: { orgId: Memory.orgId, clientId: client.id, clientSourceId: client.source_id, msg: newMessage }});
                                                                setNewMessage("");
                                                            }}
                                                            onMouseDown={(event) => event.preventDefault()}
                                                        >
                                                            <Send />
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </Box>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}