import React, { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Memory from '../../Utils/Memory';

// #138954
const styles = theme => ({
  main: {
    backgroundColor: '#54c6a4',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#34ab48',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#34ab48',
    color: '#fff',
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
});

export default function AuthCode(props) {
  const email = Memory.email;
  const token = Memory.token;
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState(!!props.location && !!props.location.state && !!props.location.state.message ? props.location.state.message : null);
  const [input, setInput] = useState('');

  if (redirect || (!!email && !!token)) {
    return <Navigate exact to="/" />;
  }

  async function submitAuthCode() {
    await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/access_code`, {
      code: input
    });

    const token_response = await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/token/refresh`);

    Memory.email = token_response.data.email;
    Memory.token = token_response.data.access_token;

    setRedirect(true);
    setInput('');
  }

  const handleSnackClose = () => {
    setRedirect(false);
    setMessage(null);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    setInput(event.target.value);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: '#2C3E50',
      }}
    >
      <Grid item='true' xs={10} sm={6} md={3}>
        <Paper sx={{p:3}}>
          <Typography variant="p1">
            An email has be sent with an access code. Please check your email.
          </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              key="code"
              name="code"
              label="Access Code"
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
             
              onClick={submitAuthCode}
            >
              Submit
            </Button>
            <Grid container>
              <Grid item='true' xs>
                <Link to="/login">
                <Button>
                  Login
                </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup">
                <Button>
                  Sign Up
                </Button>
                </Link>
              </Grid>
            </Grid>
        </Paper>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        onClose={handleSnackClose}
        message={message}
      />
    </Grid>
  );
};