import React from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Memory from '../Utils/Memory';
import { Route, Routes } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FlareIcon from '@mui/icons-material/FlareOutlined';

import Authenticator from '../Containers/Authenticator'
import Login from '../Components/Login/Login';
import SignUp from '../Components/Login/SignUp';
import AuthCode from '../Components/Login/AuthCode';
import ResetPassword from '../Components/Login/ResetPassword';
import Calendar from '../Components/Pages/Calendar';
import UserSchedule from '../Components/Pages/UserSchedule';
import Availability from '../Components/Pages/Availability';
import Clients from '../Components/Pages/Clients';
import Forms from '../Components/Pages/Forms';
import AppointmentTypes from '../Components/Pages/AppointmentTypes';
import SideNav from '../Components/SideNav';
import Resources from '../Components/Pages/Resources';
import Team from '../Components/Pages/Team';
import WaitingRoom2 from '../Components/Pages/WaitingRoom2';
import LiveRoom from '../Components/Pages/LiveRoom';
import ClientDetails from '../Components/Pages/ClientDetails';
import Dashboard from '../Components/Pages/Dashboard';
import Analytics from '../Components/Pages/Analytics';
import Messages from '../Components/Pages/Messages';
import Services from '../Components/Pages/Services';
import Products from '../Components/Pages/Products';
import CarePlans from '../Components/Pages/CarePlans';
import Pricing from '../Components/Pages/Pricing';
import Billing from '../Components/Pages/Billing';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-end',
// }));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function Content() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/signup" element={<SignUp />}/>
        <Route path="/access_code" element={<AuthCode />}/>
        <Route path="/reset_password" element={<ResetPassword />}/>
        <Route path="/" element={<Layout><Calendar /></Layout>}/>
        <Route path="/dashboard" element={<Layout><Dashboard /></Layout>}/>
        <Route path="/analytics" element={<Layout><Analytics /></Layout>}/>
        <Route path="/schedule" element={<Layout><Calendar /></Layout>}/>
        <Route path="/schedule/availability" element={<Layout><Availability /></Layout>}/>
        <Route path="/queue" element={<Layout><WaitingRoom2 /></Layout>}/>
        <Route path="/rooms" element={<Layout><Resources /></Layout>}/>
        <Route path="/live_room" element={<Layout><LiveRoom /></Layout>}/>
        <Route path="/room/:id/live" element={<Layout><LiveRoom /></Layout>}/>
        <Route path="/clients" element={<Layout><Clients /></Layout>}/>
        <Route path="/client/:id/details" element={<Layout><ClientDetails /></Layout>}/>
        <Route path="/forms" element={<Layout><Forms /></Layout>}/>
        <Route path="/appointment_types" element={<Layout><AppointmentTypes /></Layout>}/>
        <Route path="/care_plans" element={<Layout><CarePlans /></Layout>}/>
        <Route path="/pricing" element={<Layout><Pricing /></Layout>}/>
        <Route path="/services" element={<Layout><Services /></Layout>}/>
        <Route path="/products" element={<Layout><Products /></Layout>}/>
        <Route path="/team" element={<Layout><Team /></Layout>}/>
        <Route path="/team/:id/schedule" element={<Layout><UserSchedule /></Layout>}/>
        <Route path="/messages" element={<Layout><Messages /></Layout>}/>
        <Route path="/billing" element={<Layout><Billing /></Layout>}/>
      </Routes>
    </React.Fragment>
  );
}

function Layout(props) {
  let navigate = useNavigate();
  const [index, setIndex] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(window.location.pathname === "/" ? "/schedule" : window.location.pathname);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [stripe_url, setStripeUrl] = React.useState('');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function logOut() {
    await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/logout`);

    Memory.email = undefined;
    Memory.token = undefined;
    Memory.id = undefined;
    Memory.orgId = undefined;
    window.location.reload(false);
  }

  async function manageSubscription() {
    let resp = await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/create-manage-subscription-session`, {
      id: Memory.orgId
    }, { withCredentials: true });

    if (!!resp && !!resp.data && !!resp.data.session_url) {
      window.open(resp.data.session_url, '_blank');
    }
  }

  return (
    <Authenticator>
      <Fade in timeout={1200}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar open={open} position="fixed" sx={{ bgcolor: '#2C3E50' }} elevation={2}>
            <Toolbar variant='dense'>
              <Stack spacing={4} direction="row">
                <Box sx={{ width: 50 }}/>
                <Button variant='text' sx={{ color: index === 0 ? '#fff' : '#9298a3' }} onClick={() => {setIndex(0); setSelectedIndex('/schedule'); navigate('../schedule');}}>Office</Button>
                <Button variant='text' sx={{ color: index === 1 ? '#fff' : '#9298a3' }} onClick={() => {setIndex(1); setSelectedIndex('/dashboard'); navigate('../dashboard');}}>Business</Button>
                <Button variant='text' sx={{ color: index === 2 ? '#fff' : '#9298a3' }} onClick={() => {setIndex(2); setSelectedIndex('/schedule'); navigate('../schedule/availability');}}>Marketing</Button>
                <Button variant='text' sx={{ color: index === 3 ? '#fff' : '#9298a3' }} onClick={() => {setIndex(3); setSelectedIndex('/billing'); navigate('../billing');}}>Billing</Button>
              </Stack>
              <Box style={{ flexGrow: 1 }}/>
              <div>
                <Button variant="text" sx={{ color: '#fff' }} onClick={handleMenu}>
                  {Memory.email}
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={(manageSubscription)}>Manage Subscription</MenuItem>
                  <MenuItem onClick={logOut}>Log Out</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ bgcolor: '#54c6a4' }}>
              <IconButton sx={{ color: '#fff'}} onClick={() => setOpen(!open)} color='inherit'>
                <FlareIcon />
              </IconButton>
            </DrawerHeader>
            <SideNav index={index} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}  />
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: selectedIndex === '/messages' ? 0 : 3 }}>
            <DrawerHeader />
            {props.children}
          </Box>
        </Box>
      </Fade>
    </Authenticator>
  );
}

export default Content;