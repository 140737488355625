import React from 'react';
import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScoreboardIcon from '@mui/icons-material/AssessmentOutlined';
import FormIcon from '@mui/icons-material/AssignmentOutlined';
import TeamIcon from '@mui/icons-material/Groups';
import MessagesIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ContactIcon from '@mui/icons-material/ContactPhoneOutlined';
import GridIcon from '@mui/icons-material/GridViewOutlined';
import EventIcon from '@mui/icons-material/EventOutlined';
import ServiceIcon from '@mui/icons-material/MedicalServicesOutlined';
import ProductsIcon from '@mui/icons-material/RedeemOutlined';
import CarePlanIcon from '@mui/icons-material/CreateNewFolderOutlined';
import PricingIcon from '@mui/icons-material/LocalAtmOutlined';
import WaitingRoomIcon from '@mui/icons-material/LivingOutlined';
import EhrScreenIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import Tooltip from '@mui/material/Tooltip';

function SideNav(props) {
  let navigate = useNavigate();
  const { index = 0, selectedIndex = '/schedule', setSelectedIndex } = props;

  const handleClick = (index) => {
    setSelectedIndex(index);
    navigate(`..${index}`);
  }

  return (
    <Grid
      container
      direction="column"
      // justifyContent="center"
      // alignItems="center"
      sx={{ height: '100%', bgcolor: '#54c6a4' }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <List component="nav">
          {index === 0 && 
            <React.Fragment>
              <ListItem disablePadding>
                <Tooltip title="Schedule" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/schedule" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/schedule")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/schedule" ? { color: '#fff' } : {  }) }}>
                      <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Schedule" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Waiting Room" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/queue" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/queue")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/queue" ? { color: '#fff' } : {  }) }}>
                      <WaitingRoomIcon />
                    </ListItemIcon>
                    <ListItemText primary="Waiting Room" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="EHR" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/live_room" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/live_room")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/live_room" ? { color: '#fff' } : {  }) }}>
                      <EhrScreenIcon />
                    </ListItemIcon>
                    <ListItemText primary="EHR" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Clients" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/clients" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/clients")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/clients" ? { color: '#fff' } : {  }) }}>
                      <ContactIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Messages" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/messages" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/messages")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/messages" ? { color: '#fff' } : {  }) }}>
                      <MessagesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Messages" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </React.Fragment>
          }
          {index === 1 && 
            <React.Fragment>
              <ListItem disablePadding>
                <Tooltip title="Dashboard" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/dashboard" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/dashboard")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/dashboard" ? { color: '#fff' } : {  }) }}>
                      <ScoreboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Analytics" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/analytics" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/analytics")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/analytics" ? { color: '#fff' } : {  }) }}>
                      <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Rooms" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/rooms" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/rooms")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/rooms" ? { color: '#fff' } : {  }) }}>
                      <GridIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rooms" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Forms" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/forms" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/forms")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/forms" ? { color: '#fff' } : {  }) }}>
                      <FormIcon />
                    </ListItemIcon>
                    <ListItemText primary="Forms" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Pricing" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/pricing" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/pricing")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/pricing" ? { color: '#fff' } : {  }) }}>
                      <PricingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pricing" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Services" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/services" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/services")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/services" ? { color: '#fff' } : {  }) }}>
                      <ServiceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Services" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Products" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/products" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/products")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/products" ? { color: '#fff' } : {  }) }} >
                      <ProductsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Care Plans" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/care_plans" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/care_plans")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/care_plans" ? { color: '#fff' } : {  }) }}>
                      <CarePlanIcon />
                    </ListItemIcon>
                    <ListItemText primary="Care Plans" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Apointment Types" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/appointment_types" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/appointment_types")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/appointment_types" ? { color: '#fff' } : {  }) }}>
                      <EventIcon />
                    </ListItemIcon>
                    <ListItemText primary="Appointment Types" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
              <ListItem disablePadding>
                <Tooltip title="Team" placement="right" followCursor>
                  <ListItemButton sx={{ ...(selectedIndex === "/team" ? { color: '#fff' } : {  }) }} onClick={() => handleClick("/team")}>
                    <ListItemIcon sx={{ ...(selectedIndex === "/team" ? { color: '#fff' } : {  }) }} >
                      <TeamIcon />
                    </ListItemIcon>
                    <ListItemText primary="Team" />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </React.Fragment>
          }
        </List>
        {/* <Typography variant="subtitle2" sx={{ mt: 20, mb: 2 }}>
          {email}
        </Typography>
        <Button variant="outlined" color="inherit" onClick={logOut}>
          Log Out
        </Button> */}
      </Grid>
    </Grid>
  );
}

export default SideNav;
