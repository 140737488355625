import * as React from 'react';
import { useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlined';

import ClientsInput from '../Forms/ClientsInput';

const BLACK = '#000000';
const RED = '#ff0000';
const GREEN = '#22c925';
const YELLOW = '#6b6666';
const ORANGE = '#fc8a00';
const PINK = '#f954e9';

const DOCTORS_BUTTONS = [
    {
        id: 1,
        label: 'SOAP',
        text: `Wellness Office Visit

S: Patient states no change in condition compared to last visit.

O: Exam shows contained subluxations that are not symptomatic.

A: Patient's condition is stable at this time (V70.9/Z00.8). Patient tolerated and responded well to treatment.

P: Continue as per discharge summary. Segments adjusted:`,
    },{
        id: 2,
        label: 'COVID Well',
        text: `Wellness Office Visit

S: Patient states no change in condition compared to last visit.

O: Exam shows contained subluxations that are not symptomatic.
The patient was questioned with regard to the recent travel and exposure to illness including COVID 19. The patint denies recent travel to any current region or country of outbreak nor been exposed to anyone at risk to their knowledge.
The patient denies any of the following: productive cough, non-productive cough, bronchitis, respiratory infection, sor throat, fever, nausea, vomiting, diarrhea, sever fatige not associated with travel, myalgia, and arthralgia or associeated with original complaints.

A: Patient's condition is stable at this time (V70.9/Z00.8). Patient tolerated and responded well to treatment. Care at this point is necessary in ensuring there is no interruption in care leading the patient to suffer from permanent disability, a deterioration in their condition or place them at undue risk for their current and future health.

P: Continue as per discharge summary. Segments adjusted:`,
    },{
        id: 3,
        label: 'Test',
        text: `Test`,
    },{
        id: 4,
        label: '1',
        text: `asdf asdfasdfasdf
        fdjfwpicsmkd fsdafewipkj
        falsjdfkeomjfaslkjlef`,
        color: BLACK,
    },{
        id: 5,
        label: '2',
        text: `asdf asdfasdfasdf
        fdjfwpicsmkd fsdafewipkj`,
        color: BLACK,
    },{
        id: 6,
        label: '3',
        text: `falsjdfkeomjfaslkjlef`,
        color: BLACK,
    },{
        id: 7,
        label: 'OCC',
        text: `Occiput`,
        color: PINK,
    },{
        id: 8,
        label: 'C1',
        text: `C1`,
        color: PINK,
    },{
        id: 9,
        label: 'C2',
        text: `C2`,
        color: PINK,
    },{
        id: 10,
        label: 'C3',
        text: `C3`,
        color: PINK,
    },{
        id: 11,
        label: 'C4',
        text: `C4`,
        color: PINK,
    },{
        id: 12,
        label: 'C5',
        text: `C5`,
        color: PINK,
    },{
        id: 13,
        label: 'C6',
        text: `C6`,
        color: PINK,
    },{
        id: 14,
        label: 'C7',
        text: `C7`,
        color: PINK,
    },{
        id: 15,
        label: 'T1',
        text: `T1`,
        color: ORANGE,
    },{
        id: 16,
        label: 'T2',
        text: `T2`,
        color: ORANGE,
    },{
        id: 17,
        label: 'T3',
        text: `T3`,
        color: ORANGE,
    },{
        id: 18,
        label: 'T4',
        text: `T4`,
        color: ORANGE,
    },{
        id: 19,
        label: 'T5',
        text: `T5`,
        color: ORANGE,
    },{
        id: 20,
        label: 'T6',
        text: `T6`,
        color: ORANGE,
    },{
        id: 21,
        label: 'T7',
        text: `T7`,
        color: ORANGE,
    },{
        id: 22,
        label: 'T8',
        text: `T8`,
        color: ORANGE,
    },{
        id: 23,
        label: 'T9',
        text: `T9`,
        color: ORANGE,
    },{
        id: 24,
        label: 'T10',
        text: `T10`,
        color: ORANGE,
    },{
        id: 25,
        label: 'T11',
        text: `T11`,
        color: ORANGE,
    },{
        id: 26,
        label: 'T12',
        text: `T12`,
        color: ORANGE,
    },{
        id: 27,
        label: 'L1',
        text: `L1`,
        color: GREEN,
    },{
        id: 28,
        label: 'L2',
        text: `L12`,
        color: GREEN,
    },{
        id: 29,
        label: 'L3',
        text: `L3`,
        color: GREEN,
    },{
        id: 30,
        label: 'L4',
        text: `L4`,
        color: GREEN,
    },{
        id: 31,
        label: 'L5',
        text: `L5`,
        color: GREEN,
    },{
        id: 32,
        label: 'SAC',
        text: `Sacrum`,
        color: GREEN,
    },{
        id: 33,
        label: 'L-SI',
        text: `Left sacro-iliac`,
        color: GREEN,
    },{
        id: 34,
        label: 'R-SI',
        text: `Right sacro-iliac`,
        color: GREEN,
    },{
        id: 35,
        label: 'Dr J',
        text: `Electronically signed
        by Dr. Josiah Fitzsimmons`,
        color: YELLOW,
    },{
        id: 36,
        label: 'Dr Chris',
        text: `Electronically signed
        by Dr. Christopher Robbins`,
        color: YELLOW,
    },{
        id: 37,
        label: 'Dr Carter',
        text: `Electronically signed
        by Dr. Carter`,
        color: YELLOW,
    },{
        id: 38,
        label: 'Dr Scott',
        text: `Electronically signed
        by Dr. Scott`,
        color: YELLOW,
    },{
        id: 39,
        label: 'Dr Doom',
        text: `Electronically signed
        by Doctor Doom`,
        color: YELLOW,
    },
];

const GET_ROOM_LIST = gql`
query GetRoomList($orgId: uuid!) {
    resource(where: {organization_id: {_eq: $orgId}}, order_by: {name: asc}) {
        id
        name
    }
    user(where: {organization_id: {_eq: $orgId}}, order_by: {last_name: asc}) {
        id
        first_name
        last_name
    }
}`;

const GET_ROOM_DETAILS = gql`
query GetRoomDetails($id: uuid!, $start: timestamptz!, $end: timestamptz!) {
    resource_by_pk(id: $id) {
        id
        name
        resource_queues(where: {called_time: {_is_null: false}, created_at: {_gte: $start, _lte: $end}, _or: [{done_time: {_is_null: true}}, {transaction_time: {_is_null: true}}]}) {
            id
            first_name
            last_name
            client {
                id
                first_name
                last_name
                email
                phone
            }
        }
    }
}`;

const GET_CLIENT_DETAILS = gql`
query GetClientRoomDetails($id: uuid!) {
    clients_by_pk(id: $id) {
        id
        source_id
        first_name
        last_name
        email
        phone
        note
        medical_note
    }
}`;

const GET_CLIENT_EHR_NOTES = gql`
query GetClientEhrNotes($id: uuid!, $orgId: uuid!) {
    getClientNotes(args: {clientId: $id, orgId: $orgId}) {
        clientNotes {
            id
            text
            created_at
            user {
                id
                first_name
                last_name
            }
        }
    }
}`;

const UPDATE_CLIENT_NOTE = gql`mutation UpdateRoomCurrentAppointment($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {note: $text}) {
      created_at
    }
}`;

const UPDATE_CLIENT_MEDICAL_NOTE = gql`mutation UpdateRoomCurrentAppointment($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {medical_note: $text}) {
      created_at
    }
}`;

const INSERT_CLIENT_EHR_NOTE = gql`
mutation InsertClientEhrNote($noteId: uuid, $orgId: uuid!, $clientId: uuid!, $userId: uuid!, $note: String!) {
    createClientNote(args: {noteId: $noteId, orgId: $orgId, clientId: $clientId, userId: $userId, note: $note}) {
        error
        message
    }
}`;

const DELETE_CLIENT_EHR_NOTE = gql`mutation DeleteClientEhrNote($id: uuid!) {
    delete_client_ehr_note_by_pk(id: $id) {
      id
    }
}`;

const UPDATE_RESOURCE_QUEUE = gql`mutation UpdateQueue($resourceQueueId: uuid!, $done_time: timestamptz!, $transaction_time: timestamptz!) {
    update_resource_queue_by_pk(pk_columns: {id: $resourceQueueId}, _set: {done_time: $done_time, transaction_time: $transaction_time}) {
      id
    }
}`;

export default function LiveRoom() {
    const { id } = useParams();
    const noteRef = React.useRef();
    const [roomId, setRoomId] = React.useState(id ?? '');
    const [newNote, setNewNote] = React.useState('');
    const [selected, setSelected] = React.useState({});
    const [selectedNote, setSelectedNote] = React.useState('');
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    today.setHours(0, 0, 0);
    tomorrow.setHours(0, 0, 0);
    const [start, setStart] = React.useState(today);
    const [end, setEnd] = React.useState(tomorrow);
    const [client, setClient] = React.useState(null);
    const [userId, setUserId] = React.useState('');

    React.useEffect(() => {
        if (!!noteRef && !!noteRef.current) {
            noteRef.current.scrollTop = noteRef.current.scrollHeight;
        }
    }, [newNote]);

    const { data, startPolling } = useQuery(GET_ROOM_DETAILS, {
        variables: { id: roomId, start: start, end: end },
    });

    startPolling(5000);

    const { data: roomListData } = useQuery(GET_ROOM_LIST, { variables: { orgId: Memory.orgId }});

    const { data: selectedClientData } = useQuery(GET_CLIENT_DETAILS, {
        variables: { id: !!selected && !!selected.client ? selected.client.id : ''},
    });

    const { data: clientNotes } = useQuery(GET_CLIENT_EHR_NOTES, {
        variables: { orgId: Memory.orgId, id: !!selected && !!selected.client ? selected.client.id : '' },
    });

    const refetchQueries = [
        {
            query: GET_ROOM_DETAILS,
            variables: { id: roomId, start: start, end: end },
        },
        {
            query: GET_CLIENT_DETAILS,
            variables: { id: !!selected && !!selected.client ? selected.client.id : '' },
        },
        {
            query: GET_CLIENT_EHR_NOTES,
            variables: { orgId: Memory.orgId, id: !!selected && !!selected.client ? selected.client.id : '' },
        }
    ]

    const [updateClientNote] = useMutation(UPDATE_CLIENT_NOTE, { refetchQueries: refetchQueries });

    const [updateClientMedicalNote] = useMutation(UPDATE_CLIENT_MEDICAL_NOTE, { refetchQueries: refetchQueries });

    const [insertClientEhrNote] = useMutation(INSERT_CLIENT_EHR_NOTE, { refetchQueries: refetchQueries });

    const [deleteClientEhrNote] = useMutation(DELETE_CLIENT_EHR_NOTE, { refetchQueries: refetchQueries });

    const [updateResourceQueue] = useMutation(UPDATE_RESOURCE_QUEUE, { refetchQueries: refetchQueries });

    const handleSelectClient = (rq) => {
        setSelected(!!rq.client ? rq : {});
        setSelectedNote('');
    };

    const handleSelectNote = (id, note, userId) => {
        if (selectedNote === id) {
            setSelectedNote('');
            setNewNote('');
            setUserId('');
        } else {
            setSelectedNote(id);
            setNewNote(note);
            setUserId(userId);
        }
    };

    const handleSubmitNote = () => {
        insertClientEhrNote({ variables: { orgId: Memory.orgId, clientId: selected.client.id, userId: userId, note: newNote }});
        if (!!selected.id) {
            setSelected({});
            updateResourceQueue({ variables: { resourceQueueId: selected.id, done_time: new Date().toUTCString(), transaction_time: new Date().toUTCString() }});
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
            }}
        >
            <Grid container spacing={4} sx={{ height: '100%' }}>
                <Grid xs={5}>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                mr: 2,
                            }}
                        >
                            <TextField
                                select
                                fullWidth
                                label="Room"
                                value={roomId}
                                onChange={(e) => {setRoomId(e.target.value); setClient(null); setSelected({}); setSelectedNote('');}}
                            >
                                {!!roomListData && !!roomListData.resource && roomListData.resource.map((room) => (
                                    <MenuItem key={room.id} value={room.id}>
                                        {`${room.name}`}
                                    </MenuItem>
                                ))}
                                <MenuItem key='none' value='' />
                            </TextField>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <ClientsInput setClient={(val) => {setClient(val); setSelected({client: val}); setSelectedNote('');}} />
                        </Box>
                    </Toolbar>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">First Name</TableCell>
                                    <TableCell align="left">Last Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!client && 
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={client.id}
                                        selected={!!selected && !!selected.client && selected.client.id === client.id}
                                    >
                                        <TableCell align="left">{client.first_name}</TableCell>
                                        <TableCell align="left">{client.last_name}</TableCell>
                                        <TableCell align="left">{client.email}</TableCell>
                                        <TableCell align="left">{client.phone}</TableCell>
                                    </TableRow>
                                }
                                {client === null && !!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length > 0 && data.resource_by_pk.resource_queues.map((rq, i) => {
                                    if (i === 0 && !!rq.client && (selected === null || !selected.client || !selected.client.id)) {
                                        handleSelectClient(rq);
                                    }
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={rq.client.id}
                                            selected={!!rq.client && !!selected && !!selected.client && selected.client.id === rq.client.id}
                                            onClick={() => handleSelectClient(rq)}
                                        >
                                            <TableCell align="left">{!!rq.client ? rq.client.first_name : rq.first_name}</TableCell>
                                            <TableCell align="left">{!!rq.client ? rq.client.last_name : rq.last_name}</TableCell>
                                            <TableCell align="left">{!!rq.client ? rq.client.email : ''}</TableCell>
                                            <TableCell align="left">{!!rq.client ? rq.client.phone : ''}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TextField
                        id="filled-multiline-static"
                        label="Office Notes"
                        multiline
                        minRows={4}
                        maxRows={8}
                        fullWidth
                        variant="filled"
                        color="warning"
                        focused
                        disabled={!selectedClientData || !selectedClientData.clients_by_pk || !selectedClientData.clients_by_pk.id || (client === null && !!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length === 0)}
                        value={!!selectedClientData && !!selectedClientData.clients_by_pk && !!selectedClientData.clients_by_pk.id && !!selectedClientData.clients_by_pk.note && (!!client || (!!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length > 0)) ? selectedClientData.clients_by_pk.note : ''}
                        onChange={(e) => updateClientNote({ variables: { clientId: selectedClientData.clients_by_pk.id, text: e.target.value }})}
                        sx={{ mb: 4 }}
                    />
                    <TextField
                        id="filled-multiline1-static"
                        label="Patient Notes"
                        multiline
                        minRows={4}
                        maxRows={8}
                        fullWidth
                        variant="filled"
                        color="secondary"
                        focused
                        disabled={!selectedClientData || !selectedClientData.clients_by_pk || !selectedClientData.clients_by_pk.id || (client === null && !!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length === 0)}
                        value={!!selectedClientData && !!selectedClientData.clients_by_pk && !!selectedClientData.clients_by_pk.id && !!selectedClientData.clients_by_pk.medical_note && (!!client || (!!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length > 0)) ? selectedClientData.clients_by_pk.medical_note : ''}
                        onChange={(e) => updateClientMedicalNote({ variables: { clientId: selectedClientData.clients_by_pk.id, text: e.target.value }})}
                        sx={{ mb: 4 }}
                    />
                    <Toolbar 
                        sx={{
                            pl: { xs: 0 },
                            pr: { xs: 1, sm: 1 },
                        }}
                    >
                        <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        >
                            Past Doctor's Notes
                        </Typography>
                    </Toolbar>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Doctor</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {((!!selected && !!selected.client && !!data && !!data.resource_by_pk && !!data.resource_by_pk.resource_queues && data.resource_by_pk.resource_queues.length > 0)
                                || !!client)
                                && !!clientNotes && !!clientNotes.getClientNotes && !!clientNotes.getClientNotes.clientNotes && clientNotes.getClientNotes.clientNotes.length > 0 && clientNotes.getClientNotes.clientNotes.map((note) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={note.id}
                                            selected={selectedNote === note.id}
                                        >
                                            <TableCell align='left' sx={{ p: 0 }}>
                                                <IconButton aria-label="delete-discount" sx={{ color: '#d10e0e' }} onClick={() => { deleteClientEhrNote({ variables: { id: note.id }}); setNewNote(''); setSelectedNote(''); setUserId(''); }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="left" onClick={() => handleSelectNote(note.id, note.text, note.user.id)}>{new Date(note.created_at).toLocaleDateString()}</TableCell>
                                            <TableCell align="left" onClick={() => handleSelectNote(note.id, note.text, note.user.id)}>{`${note.user.first_name} ${note.user.last_name}`}</TableCell>
                                            <TableCell align="right" onClick={() => handleSelectNote(note.id, note.text, note.user.id)}>{note.text}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid xs={7}>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Box sx={{ width: 250 }}>
                            <TextField
                                id="user-input"
                                select
                                fullWidth
                                label='Team Member'
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            >
                                {!!roomListData && !!roomListData.user && roomListData.user.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {`${user.first_name} ${user.last_name}`}
                                    </MenuItem>
                                ))}
                                <MenuItem key='none' value='' />
                            </TextField>
                        </Box>
                        <Typography sx={{ flexGrow: 1 }} />
                        {selectedNote === '' ?
                            <Button variant="outlined" size='large' disabled={newNote === '' || !selected || !selected.client || !selected.client.id} sx={{ width: 200, mr: 2, pt: 1.7, pb: 1.7 }} onClick={() => handleSubmitNote()}>Submit Note</Button>
                            :
                            <Button variant="outlined" size='large' disabled={newNote === '' || !selected || !selected.client || !selected.client.id} sx={{ width: 200, mr: 2, pt: 1.7, pb: 1.7 }} onClick={() => insertClientEhrNote({ variables: { noteId: selectedNote, orgId: Memory.orgId, clientId: selected.client.id, userId: userId, note: newNote }})}>Save</Button>
                        }
                        <Button variant="outlined" size='large' disabled={newNote === ''} sx={{ pt: 1.7, pb: 1.7 }} onClick={() => setNewNote('')}>Clear</Button>
                    </Toolbar>
                    <TextField
                        id="filled-multiline1-static"
                        label="New Doctor's Note"
                        multiline
                        rows={12}
                        fullWidth
                        variant="filled"
                        color="error"
                        focused
                        inputRef={noteRef}
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                        sx={{ mb: 4 }}
                    />
                    <Grid container spacing={2}>
                        {DOCTORS_BUTTONS.map((button) => (
                            <Grid xs={2} key={button.id}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    sx={{ bgcolor: button.color}}
                                    onClick={(e) => {setNewNote(newNote + button.text + '\r\n')}}
                                >
                                    {button.label}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}