import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const GET_ROOMS = gql`
query GetRoomsList($userId: uuid!) {
    resource(where: {organization: {users: {id: {_eq: $userId}}}}, order_by: {name: asc}) {
        id
        name
    }
}`;

export default function RoomInput(props) {
    const { setRoomId, roomId = '' } = props;
    const [value, setValue] = React.useState(roomId);

    const handleChange = (event) => {
        setValue(event.target.value);
        setRoomId(event.target.value);
    };

    const { data } = useQuery(GET_ROOMS, {
        variables: { userId: Memory.id },
    });

    return (
        <TextField
          id="room-input"
          select
          fullWidth
          label="Room"
          value={value}
          onChange={handleChange}
        >
            {!!data && data.resource.map((room) => (
                <MenuItem key={room.id} value={room.id}>
                    {room.name}
                </MenuItem>
            ))}
            <MenuItem key='none' value='' />
        </TextField>
    );
}