import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

function SimpleDialog(props) {
    const { imageItem, open, setOpen } = props;
  
    const handleClose = () => {
        setOpen(false);
    };
  
    return (
      <Dialog onClose={handleClose} open={open} maxWidth='md'>
        <DialogTitle>{imageItem.file_name}</DialogTitle>
        <DialogContent>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: '100%',
                }}
            >
                <img
                    src={`${process.env.REACT_APP_AUTH_SERVER_URI}/api/image/${imageItem.id}?orgId=${imageItem.organization_id}&ipId=${imageItem.integration_provider_id}`}
                    srcSet={`${process.env.REACT_APP_AUTH_SERVER_URI}/api/image/${imageItem.id}?orgId=${imageItem.organization_id}&ipId=${imageItem.integration_provider_id}`}
                    alt={imageItem.file_name}
                    loading="lazy"
                />
            </Box>
        </DialogContent>
      </Dialog>
    );
  }

const getImages = (data) => {
    let images = [];

    data.clients_by_pk.client_forms.forEach(cf =>{
        cf.questions.forEach(question => {
            question.attachments.forEach(a => {
                images.push({
                    id: a.id,
                    url: a.url,
                    file_name: a.file_name,
                    content_type: a.content_type,
                    source_id: a.source_id,
                    organization_id: cf.organization_id,
                    integration_provider_id: cf.integration_provider_id,
                    
                });
            })
        })
    });

    return images;
}

export default function ClientImagesList(props) {
    const { data } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [imageItem, setImageItem] = React.useState({});

    return (
        <React.Fragment>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
                >
                    Images
                </Typography>
            </Toolbar>
            <Paper>
            <ImageList cols={4} sx={{ maxHeight: 400 }}>
            {!!data
            && !!data.clients_by_pk
            && !!data.clients_by_pk.client_forms
            && getImages(data).map((item) => (
                <ImageListItem key={item.id} onClick={() => {setImageItem(item); setOpenDialog(true);}}>
                    <img
                        src={`${process.env.REACT_APP_AUTH_SERVER_URI}/api/image/${item.id}?orgId=${item.organization_id}&ipId=${item.integration_provider_id}`}
                        srcSet={`${process.env.REACT_APP_AUTH_SERVER_URI}/api/image/${item.id}?orgId=${item.organization_id}&ipId=${item.integration_provider_id}`}
                        alt={item.file_name}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={item.file_name}
                    />
                </ImageListItem>
            ))}
            </ImageList>
            <SimpleDialog
                imageItem={imageItem}
                open={openDialog}
                setOpen={setOpenDialog}
            />
            </Paper>
        </React.Fragment>
    );
}
