import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import { ResponsivePie } from '@nivo/pie';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

const GET_SERVICES = gql`
query GetServices($orgId: uuid!, $start: timestamptz!, $end: timestamptz!, $limit: Int!, $offset: Int!) {
    service(where: {organization_id: {_eq: $orgId}}, order_by: {transactions_aggregate: {sum: {amount: desc_nulls_last}}}) {
        id
        name
        description
        source_id
        charge_amount
        transactions_aggregate(where: {amount: {_gt: "0"}, date: {_gte: $start, _lte: $end}}) {
          aggregate {
            sum {
              amount
            }
          }
        }
    }
    transaction(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}}, order_by: {date: desc_nulls_last}) {
        id
        date
        amount
        insurance_charge_amount
        insurance_approved_amount
        service {
            id
            name
        }
        client {
            id
            first_name
            last_name
        }
    }
}`;

const EnhancedTableToolbar = (props) => {
    const { start, setStart, end, setEnd } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 0 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
            <Typography
                sx={{
                    mr: 2,
                    pl: { xs: 0 },
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Billing
            </Typography>

            <Typography sx={{ flexGrow: 1 }} />
            
            <Box
                sx={{
                    width: '200px',
                    mr: 2,
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Start"
                        inputFormat="MM/dd/yyy"
                        value={start}
                        onChange={setStart}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    width: '200px',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="End"
                        inputFormat="MM/dd/yyy"
                        value={end}
                        onChange={setEnd}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
        </React.Fragment>
    </Toolbar>
  );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach(datum => {
        total += datum.value;
    });

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill='black'
            style={{
                fontSize: '24px',
            }}
        >
            {total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
            })}
        </text>
    )
};

export default function Billing(props) {
    const today = new Date();
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const [start, setStart] = React.useState(firstOfMonth);
    const [end, setEnd] = React.useState(endOfMonth);
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    const { data } = useQuery(GET_SERVICES, {
        variables: { orgId: Memory.orgId, start: start.toISOString(), end: end.toISOString(), limit: limit, offset: offset },
        fetchPolicy: "no-cache",
    });

    const getRevenueData = (data) => {
        let chartData = [];
    
        if (!!data && !!data.service) {
            data.service.forEach(s => {
                if (!!s.transactions_aggregate.aggregate.sum.amount) {
                    chartData.push({
                        id: s.name,
                        label: s.name,
                        value: s.transactions_aggregate.aggregate.sum.amount,
                    });
                }
            });
        }
    
        return chartData;
    };

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

    return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
        <EnhancedTableToolbar start={start} setStart={setStart} end={end} setEnd={setEnd} dashboardId={!!data && !!data.dashboard ? data.dashboard[0].id : ''} data={data} />
        <Grid container spacing={3} sx={{ height: '100%' }}>
            <Grid xs={4} sx={{ maxHeight: 400, mb: 4 }}>
                <Toolbar
                    sx={{
                        mb: { xs: 2 },
                        pr: { xs: 0 },
                        pl: { xs: 0 },
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Revenue Breakdown
                    </Typography>
                </Toolbar>
                <ResponsivePie 
                    data={getRevenueData(data)}
                    margin={{ top: 20, right: 10, bottom: 10, left: 10 }}
                    innerRadius={0.7}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    colors={{ scheme: 'paired' }}
                    layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                    valueFormat={value =>
                        `${Number(value).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`
                    }
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#997279',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#49c145',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'giving'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'shopping'
                            },
                            id: 'lines'
                        }
                    ]}
                    // onClick={handleClick}
                />
            </Grid>
            <Grid xs={8}>
                {!!data && !!data.service && data.service.length > 0 && 
                    <React.Fragment>
                        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                            <Table
                                size="small"
                                aria-label="revenue table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="center">Description</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.service.map((r) => r).sort((a, b) => b.transactions_aggregate.aggregate.sum.amount - a.transactions_aggregate.aggregate.sum.amount).map((row, index) => (
                                            <TableRow
                                                key={row.id + index}
                                                hover
                                                sx={{ bgcolor: '#fff' }}
                                            >
                                                <TableCell align='left'>{row.name}</TableCell>
                                                <TableCell align='left'>{row.description}</TableCell>
                                                <TableCell align='right'>{!!row.transactions_aggregate && !!row.transactions_aggregate.aggregate && !!row.transactions_aggregate.aggregate.sum && !!row.transactions_aggregate.aggregate.sum.amount ? `$${row.transactions_aggregate.aggregate.sum.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                }
            </Grid>
            <Grid xs={12} sx={{ maxHeight: 400 }}>
                <Toolbar
                    sx={{
                        mb: { xs: 2 },
                        pr: { xs: 0 },
                        pl: { xs: 0 },
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                       Transactions
                    </Typography>
                </Toolbar>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Practice Member</TableCell>
                            <TableCell align="center">Service</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Insurance Charge Amount</TableCell>
                            <TableCell align="center">Insurance Approved Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!data && !!data.transaction && data.transaction.length > 0 && data.transaction.map((row, index) =>  (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell align='center'>{new Date(row.date).toLocaleDateString()}</TableCell>
                                    <TableCell align="center">{}</TableCell>
                                    <TableCell align="center">{!!row.client ? `${row.client.first_name} ${row.client.last_name}` : ''}</TableCell>
                                    <TableCell align="center">{row.service.name}</TableCell>
                                    <TableCell align="center">{row.amount}</TableCell>
                                    <TableCell align="center">{row.insurance_charge_amount}</TableCell>
                                    <TableCell align="center">{row.insurance_approved_amount}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[30, 60, 90]}
                    component="div"
                    count={!!data ? data.transaction.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    </Box>
  );
}