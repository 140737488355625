import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ADD_CHART = gql`mutation AddChart($name: String!, $type: String!, $data: String!, $orgId: uuid!, $dashboardId: uuid!) {
    insert_dashboard_chart_one(object: {name: $name, type: $type, data: $data, organization_id: $orgId, dashboard_id: $dashboardId}) {
      id
      created_at
    }
}`;

const ADD_TABLE = gql`mutation AddChart($name: String!, $type: String!, $data: String!, $orgId: uuid!, $dashboardId: uuid!) {
    insert_dashboard_table_one(object: {name: $name, type: $type, data: $data, organization_id: $orgId, dashboard_id: $dashboardId}) {
      id
      created_at
    }
}`;

const UPDATE_DASHBOARD = gql`mutation UpdateDashboard($orderData: String!, $dashboardId: uuid!) {
    update_dashboard_by_pk(pk_columns: {id: $dashboardId}, _set: {order_data: $orderData}) {
      created_at
    }
}`;

export default function NewDashboardItem(props) {
    const { dashboardId, orderData, data, refetchQueries } = props
    const [open, setOpen] = React.useState(false);
    const [addItemIndex, setAddItemIndex] = React.useState(0);
    const [itemData, setItemData] = React.useState([]);
    const [itemName, setItemName] = React.useState('');
    const [itemType, setItemType] = React.useState('');

    const [addChartItem] = useMutation(ADD_CHART, {
        refetchQueries: refetchQueries
    });

    const [addTableItem] = useMutation(ADD_TABLE, {
        refetchQueries: refetchQueries
    });

    const [updateDashboard] = useMutation(UPDATE_DASHBOARD, {
        refetchQueries: refetchQueries
    });

    const handleClose = () => {
        setOpen(false);
    };

    async function handleAddNewItem() {
        let response = null;

        if (addItemIndex === 0) {
            response = await addChartItem({ variables: { name: itemName, type: itemType, data: itemData.join(','), orgId: Memory.orgId, dashboardId: dashboardId }});
        } else {
            response = await addTableItem({ variables: { name: itemName, type: itemType, data: itemData.join(','), orgId: Memory.orgId, dashboardId: dashboardId }});
        }

        if(!!dashboardId && !!response && !!response.data) {
            let newItemId = !!orderData ? orderData + ',' : '';

            if (!!response.data.insert_dashboard_chart_one && !!response.data.insert_dashboard_chart_one.id) {
                newItemId = newItemId + response.data.insert_dashboard_chart_one.id;
                updateDashboard({ variables: {
                    dashboardId: dashboardId,
                    orderData: newItemId,
                }})
            } else if (!!response.data.insert_dashboard_table_one && !!response.data.insert_dashboard_table_one.id) {
                newItemId = newItemId + response.data.insert_dashboard_table_one.id;
                updateDashboard({ variables: {
                    dashboardId: dashboardId,
                    orderData: newItemId,
                }})
            }

            setAddItemIndex(0);
            setItemData([]);
            setItemName('');
            setItemType('');
            setOpen(false);
        }
    }

    return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
        <Button variant="contained" startIcon={<AddIcon />} onClick={setOpen}>
            Add Item
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button variant={addItemIndex === 0 ? 'contained' : 'outlined'} onClick={() => {setAddItemIndex(0); setItemType(''); setItemData([]);}}>Chart</Button>
                    <Button variant={addItemIndex === 1 ? 'contained' : 'outlined'} onClick={() => {setAddItemIndex(1); setItemType(''); setItemData([]);}}>Table</Button>
                </ButtonGroup>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 400 }}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Type</InputLabel>
                    {addItemIndex === 0 && 
                        <Select
                            value={itemType}
                            label="Type"
                            onChange={(e) => setItemType(e.target.value)}
                        >
                            <MenuItem value='pie_chart'>Pie</MenuItem>
                        </Select>
                    }
                    {addItemIndex === 1 && 
                        <Select
                            value={itemType}
                            label="Type"
                            onChange={(e) => {setItemType(e.target.value); setItemData([]);}}
                        >
                            <MenuItem value='list_table'>List</MenuItem>
                            <MenuItem value='historical_table'>Historical</MenuItem>
                            <MenuItem value='data_list_table'>Data Point List</MenuItem>
                        </Select>
                    }
                </FormControl>
                <DialogContentText sx={{ mb: 2 }}>
                    Data Source
                </DialogContentText>

                {addItemIndex === 0 && itemData.map((d, i) => (
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Appointment Type"
                        value={d ?? ''}
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setItemData([...itemData.slice(0, i), ...itemData.slice(i+1)]);
                            } else {
                                setItemData([...itemData.slice(0, i), e.target.value, ...itemData.slice(i+1)]);
                            }
                        }}
                    >
                        {!!data && !!data.appointment_type && data.appointment_type.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {`${type.name} - ${type.source_name}`}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                ))}

                {addItemIndex === 0 && 
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Appointment Type"
                        value={''}
                        onChange={(e) => setItemData([...itemData, e.target.value])}
                    >
                        {!!data && !!data.appointment_type && data.appointment_type.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {`${type.name} - ${type.source_name}`}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                }

                {addItemIndex === 1 && itemType === 'list_table' && itemData.map((d, i) => (
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Appointment Type"
                        value={d ?? ''}
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setItemData([...itemData.slice(0, i), ...itemData.slice(i+1)]);
                            } else {
                                setItemData([...itemData.slice(0, i), e.target.value, ...itemData.slice(i+1)]);
                            }
                        }}
                    >
                        {!!data && !!data.appointment_type && data.appointment_type.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {`${type.name} - ${type.source_name}`}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                ))}

                {addItemIndex === 1 && itemType === 'list_table' &&
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Appointment Type"
                        value={''}
                        onChange={(e) => setItemData([...itemData, e.target.value])}
                    >
                        {!!data && !!data.appointment_type && data.appointment_type.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {`${type.name} - ${type.source_name}`}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                }

                {addItemIndex === 1 && itemType === 'historical_table' && itemData.map((d, i) => (
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Data Point"
                        value={d ?? ''}
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setItemData([...itemData.slice(0, i), ...itemData.slice(i+1)]);
                            } else {
                                setItemData([...itemData.slice(0, i), e.target.value, ...itemData.slice(i+1)]);
                            }
                        }}
                    >
                        {!!data && !!data.data_point && data.data_point.map((dp) => (
                            <MenuItem key={dp.id} value={dp.id}>
                                {dp.name}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                ))}

                {addItemIndex === 1 && itemType === 'historical_table' && 
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Data Point"
                        value={''}
                        onChange={(e) => setItemData([...itemData, e.target.value])}
                    >
                        {!!data && !!data.data_point && data.data_point.map((dp) => (
                            <MenuItem key={dp.id} value={dp.id}>
                                {dp.name}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                }

                {addItemIndex === 1 && itemType === 'data_list_table' && itemData.map((d, i) => (
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Data Point"
                        value={d ?? ''}
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setItemData([...itemData.slice(0, i), ...itemData.slice(i+1)]);
                            } else {
                                setItemData([...itemData.slice(0, i), e.target.value, ...itemData.slice(i+1)]);
                            }
                        }}
                    >
                        {!!data && !!data.data_point && data.data_point.map((dp) => (
                            <MenuItem key={dp.id} value={dp.id}>
                                {dp.name}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                ))}

                {addItemIndex === 1 && itemType === 'data_list_table' && 
                    <TextField
                        id="appointment-type-input"
                        select
                        fullWidth
                        label="Data Point"
                        value={''}
                        onChange={(e) => setItemData([...itemData, e.target.value])}
                    >
                        {!!data && !!data.data_point && data.data_point.map((dp) => (
                            <MenuItem key={dp.id} value={dp.id}>
                                {dp.name}
                            </MenuItem>
                        ))}
                        <MenuItem key='none' value='' />
                    </TextField>
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAddNewItem}>Add</Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
}