import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlined';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

import AddServiceTable from '../Widgets/AddServiceTable';
import AddProductTable from '../Widgets/AddProductTable';
import AddPricingTable from '../Widgets/AddPricingTable';

const GET_CARE_PLANS = gql`
query GetCarePlansTable($orgId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  care_plan(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}, name: {_ilike: $searchFilter}}, order_by: {created_at: desc}) {
    id
    name
    description
    status
    services(order_by: {created_at: desc}) {
      id
      quantity
      service {
        name
        description
        charge_amount
        insurance_charge_amount
        insurance_charge_quantity
      }
    }
    products(order_by: {created_at: desc}) {
      id
      quantity
      product {
        id
        name
        description
        charge_amount
        is_supply_item
      }
    }
    pricings(order_by: { pricing: {name: asc}}) {
      id
      amount
      pricing {
        id
        name
        status
      }
    }
  }
  care_plan_aggregate {
    aggregate {
      count
    }
  }
}`;

const ADD_CARE_PLAN = gql`mutation AddCarePlan($name: String!, $description: String!, $orgId: uuid!) {
  insert_care_plan_one(object: {name: $name, description: $description, organization_id: $orgId}) {
    created_at
  }
}`;

const ADD_CARE_PLAN_PRICE = gql`mutation AddCarePlanPrice($care_plan_id: uuid!, $pricing_id: uuid!) {
  insert_care_plan_pricing_one(object: {care_plan_id: $care_plan_id, pricing_id: $pricing_id}) {
    created_at
  }
}`;

const ADD_CARE_PLAN_SERVICE = gql`mutation AddCarePlanService($care_plan_id: uuid!, $service_id: uuid!) {
  insert_care_plan_service_one(object: {care_plan_id: $care_plan_id, service_id: $service_id}) {
    id
  }
}`;

const ADD_CARE_PLAN_PRODUCT = gql`mutation AddCarePlanProduct($care_plan_id: uuid!, $product_id: uuid!) {
  insert_care_plan_product_one(object: {care_plan_id: $care_plan_id, product_id: $product_id}) {
    id
  }
}`;

const DELETE_CARE_PLAN_SERVICE = gql`mutation DeleteCarePlanService($id: uuid!) {
  delete_care_plan_service_by_pk(id: $id) {
    id
  }
}`;

const DELETE_CARE_PLAN_PRODUCT = gql`mutation DeleteCarePlanProduct($id: uuid!) {
  delete_care_plan_product_by_pk(id: $id) {
    id
  }
}`;

const DELETE_CARE_PLAN_PRICE = gql`mutation DeleteCarePlanPrice($id: uuid!) {
  delete_care_plan_pricing_by_pk(id: $id) {
    id
  }
}`;

const UPDATE_CARE_PLAN = gql`mutation UpdateCarePlan($name: String!, $description: String!, $id: uuid!) {
  update_care_plan_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description}) {
    created_at
  }
}`;

const UPDATE_CARE_PLAN_SERVICE = gql`mutation UpdateCarePlanService($quantity: Int!, $id: uuid!) {
  update_care_plan_service_by_pk(pk_columns: {id: $id}, _set: {quantity: $quantity}) {
    created_at
  }
}`;

const UPDATE_CARE_PLAN_PRODUCT = gql`mutation UpdateCarePlanProduct($quantity: Int!, $id: uuid!) {
  update_care_plan_product_by_pk(pk_columns: {id: $id}, _set: {quantity: $quantity}) {
    created_at
  }
}`;

const UPDATE_CARE_PLAN_PRICE = gql`mutation UpdateCarePlanPrice($id: uuid!, $amount: float8!) {
  update_care_plan_pricing_by_pk(pk_columns: {id: $id}, _set: {amount: $amount}) {
    created_at
  }
}`;

function CarePlanServicesTable(props) {
  const { services, refetchQueries, deleteService } = props;
  const [isActive, setIsActive] = React.useState('');

  const [updateCarePlan] = useMutation(UPDATE_CARE_PLAN_SERVICE, {
    refetchQueries: refetchQueries
  });

  return (
      <React.Fragment>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center" sx={{ width: 75 }}>Qty</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="right">Charge Amount</TableCell>
                  <TableCell align="right">Insurance Amount</TableCell>
                  <TableCell align="right">Insurance Qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {!!services && services.map((row, index) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      onMouseEnter={()=>{setIsActive(row.id)}}
                      onMouseLeave={()=>{setIsActive('')}}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align='left'
                        sx={{ p: 0 }}
                      >
                        <IconButton onClick={() => deleteService(row.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      {isActive ? 
                        <TableCell align="left">
                          <TextField variant="standard" value={row.quantity} onChange={(e) => updateCarePlan({ variables: { id: row.id, quantity: e.target.value } })}/>
                        </TableCell>
                        :
                        <TableCell align="center">{row.quantity}</TableCell>
                      }
                      <TableCell align='left'>{row.service.name}</TableCell>
                      <TableCell align="left">{row.service.description}</TableCell>
                      <TableCell align="right">{row.service.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell align="right">{row.service.insurance_charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell align="right">{row.service.insurance_charge_quantity}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
      </React.Fragment>
  );
};

function CarePlanProductsTable(props) {
  const { products, refetchQueries, deleteProduct } = props;
  const [isActive, setIsActive] = React.useState('');

  const [updateCarePlan] = useMutation(UPDATE_CARE_PLAN_PRODUCT, {
    refetchQueries: refetchQueries
  });

  return (
      <React.Fragment>
          <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="center" sx={{ width: 75 }}>Qty</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="right">Charge Amount</TableCell>
                      <TableCell align="center">Supply Item</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!products && products.map((row, index) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          onMouseEnter={()=>{setIsActive(row.id)}}
                          onMouseLeave={()=>{setIsActive('')}}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align='left'
                            sx={{ p: 0 }}
                          >
                            <IconButton onClick={() => deleteProduct(row.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          {isActive ? 
                            <TableCell align="left">
                              <TextField variant="standard" value={row.quantity} onChange={(e) => updateCarePlan({ variables: { id: row.id, quantity: e.target.value } })}/>
                            </TableCell>
                            :
                            <TableCell align="center">{row.quantity}</TableCell>
                          }
                          <TableCell align='left'>{row.product.name}</TableCell>
                          <TableCell align="left">{row.product.description}</TableCell>
                          <TableCell align="right">{row.product.charge_amount}</TableCell>
                          <TableCell align="center">{row.product.is_supply_item ? 'yes' : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
              </Table>
          </TableContainer>
      </React.Fragment>
  );
};

function CarePlanPricingTable(props) {
  const { prices, refetchQueries, deletePrice } = props;
  const [isActive, setIsActive] = React.useState('');

  const [updateCarePlan] = useMutation(UPDATE_CARE_PLAN_PRICE, {
    refetchQueries: refetchQueries
  });

  return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="left">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {!!prices && prices.map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onMouseEnter={()=>{setIsActive(row.id)}}
                    onMouseLeave={()=>{setIsActive('')}}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align='left'
                      sx={{ p: 0 }}
                    >
                      <IconButton onClick={() => deletePrice(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='left'>{row.pricing.name}</TableCell>
                    {isActive ? 
                      <React.Fragment>
                        <TableCell align="left">
                          <TextField variant="standard" fullWidth value={row.amount} onChange={(e) => updateCarePlan({ variables: { id: row.id, name: row.name, amount: e.target.value } })}/>
                        </TableCell>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <TableCell align="left">{row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      </React.Fragment>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
  );
};

const EnhancedTableToolbar = (props) => {
  const { setFilter, addCarePlan } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx={{ minWidth: 120 }}
        >
          Care Plans
        </Typography>
        <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
          <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addCarePlan({ variables: { name: 'New Care Plan', description: '', orgId: Memory.orgId } })}>Add Care Plan</Button>
          </Grid>
        </Grid>
        <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
      </React.Fragment>
    </Toolbar>
  );
};

export default function CarePlans(props) {
  const [index, setIndex] = React.useState(1);
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState('');
  const [isActive, setIsActive] = React.useState('');

  const { data } = useQuery(GET_CARE_PLANS, {
    variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
  });

  const refetchQueries = [
    {
        query: GET_CARE_PLANS,
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
    }
  ];

  const [addCarePlan] = useMutation(ADD_CARE_PLAN, {
    refetchQueries: refetchQueries
  });

  const [addCarePlanService] = useMutation(ADD_CARE_PLAN_SERVICE, {
    refetchQueries: refetchQueries
  });

  const [addCarePlanPrice] = useMutation(ADD_CARE_PLAN_PRICE, {
    refetchQueries: refetchQueries
  });

  const [addCarePlanProduct] = useMutation(ADD_CARE_PLAN_PRODUCT, {
    refetchQueries: refetchQueries
  });

  const [deleteCarePlanService] = useMutation(DELETE_CARE_PLAN_SERVICE, {
    refetchQueries: refetchQueries
  });

  const [deleteCarePlanProduct] = useMutation(DELETE_CARE_PLAN_PRODUCT, {
    refetchQueries: refetchQueries
  });

  const [deleteCarePlanPrice] = useMutation(DELETE_CARE_PLAN_PRICE, {
    refetchQueries: refetchQueries
  });

  const [updateCarePlan] = useMutation(UPDATE_CARE_PLAN, {
    refetchQueries: refetchQueries
  });

  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.care_plan_aggregate.aggregate.count) : 0;

  const selectedCarePlan = !!data && !!data.care_plan && selected != '' ? data.care_plan.find(cp => cp.id === selected) : {};

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid xs={6}>
          <EnhancedTableToolbar setFilter={setFilter} addCarePlan={addCarePlan} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!data && data.care_plan_aggregate.aggregate.count > 0 && !!data.care_plan && data.care_plan.length > 0 && 
                data.care_plan.map((row, index) => {
                  const labelId = `care-plan-table-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      selected={selected === row.id}
                      onMouseEnter={()=>{setIsActive(row.id)}}
                      onMouseLeave={()=>{setIsActive('')}}
                      onClick={() => setSelected(row.id)}
                    >
                      <TableCell padding='none'>
                        <Checkbox
                          disabled={selected === row.id}
                          color="primary"
                          checked={selected === row.id}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {isActive === row.id ? 
                        <React.Fragment>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                            >
                                <TextField fullWidth variant="standard" value={row.name} onChange={(e) => updateCarePlan({ variables: { ...row, name: e.target.value } })}/>
                            </TableCell>
                            <TableCell align="left">
                                <TextField fullWidth variant="standard" value={row.description} onChange={(e) => updateCarePlan({ variables: { ...row, description: e.target.value } })}/>
                            </TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                        </React.Fragment>
                      }
                    </TableRow>
                  )})
                }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (33) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[30, 60, 90]}
            component="div"
            count={!!data ? data.care_plan_aggregate.aggregate.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid xs={6}>
          <Toolbar
            sx={{
              mb: { xs: 2 },
              pr: { xs: 0 },
              pl: { xs: 0 },
            }}
          >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            </Typography>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button variant={index === 0 ? 'contained' : 'outlined'} onClick={() => setIndex(0)}>Products</Button>
              <Button variant={index === 1 ? 'contained' : 'outlined'} onClick={() => setIndex(1)}>Services</Button>
              <Button variant={index === 2 ? 'contained' : 'outlined'} onClick={() => setIndex(2)}>Pricing</Button>
            </ButtonGroup>
          </Toolbar>
          {index === 0 && !!selectedCarePlan && 
            <Box sx={{ mb: 4 }}>
              <CarePlanProductsTable products={selectedCarePlan.products} refetchQueries={refetchQueries} deleteProduct={(id) => deleteCarePlanProduct({ variables: { id: id }})} />
            </Box>
          }
          {index === 0 && 
            <AddProductTable disable={selected === ''} addProduct={(row) => addCarePlanProduct({ variables: { care_plan_id: selected, product_id: row.id }})} />
          }
          {index === 1 && !!selectedCarePlan && 
            <Box sx={{ mb: 4 }}>
              <CarePlanServicesTable services={selectedCarePlan.services} refetchQueries={refetchQueries} deleteService={(id) => deleteCarePlanService({ variables: { id: id }})} />
            </Box>
          }
          {index === 1 && 
            <AddServiceTable disable={selected === ''} addService={(row) => addCarePlanService({ variables: { care_plan_id: selected, service_id: row.id }})} />
          }
          {index === 2 && !!selectedCarePlan && 
            <Box sx={{ mb: 4 }}>
              <CarePlanPricingTable prices={selectedCarePlan.pricings} refetchQueries={refetchQueries} deletePrice={(id) => deleteCarePlanPrice({ variables: { id: id }})} />
            </Box>
          }
          {index === 2 && 
            <AddPricingTable disable={selected === ''} addPricing={(row) => addCarePlanPrice({ variables: { care_plan_id: selected, pricing_id: row.id }})} />
          }
        </Grid>
      </Grid>
    </Box>
  );
}