import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import { styled, useTheme } from '@mui/material/styles';
import Memory from '../../Utils/Memory';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';

const drawerWidth = 800;

const GET_CLIENT_FORM = gql`
query GetClientFormDetails($id: uuid!) {
    client_form_by_pk(id: $id) {
        id
        status
        submitted_date
        form {
          name
        }
        integration_provider {
          name
        }
        questions(order_by: {created_at: asc}) {
          text
          type
          answers {
            text
          }
          attachments {
            file_name
          }
          parts {
            id
            text
            answers {
              text
            }
            parts {
                id
                text
                answers {
                    text
                }
            }
          }
        }
    }
}`;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function ViewClientFormDrawer(props) {
    const { title, clientFormId, open, setOpen } = props;
    const theme = useTheme();

    const { data, loading } = useQuery(GET_CLIENT_FORM, {
        variables: { id: clientFormId },
    });

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                height: 'auto',
                width: drawerWidth,
                marginTop: '10vh',
            },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <Divider />
            <DrawerHeader>
                <IconButton onClick={handleClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Typography variant="h6">
                    {title}
                </Typography>
            </DrawerHeader>
            <Divider />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Submitted</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!data && !!data.client_form_by_pk && 
                            <TableRow hover role="checkbox" tabIndex={-1} key={data.client_form_by_pk.id}>
                                <TableCell align="left">{new Date(data.client_form_by_pk.submitted_date).toLocaleDateString()}</TableCell>
                                <TableCell align="left">{data.client_form_by_pk.form.name}</TableCell>
                                <TableCell align="left">{data.client_form_by_pk.integration_provider.name}</TableCell>
                                <TableCell align="right">{data.client_form_by_pk.status}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider />
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                {loading && <CircularProgress />}
                {!loading && !!data && 
                    <TableContainer sx={{ mt: 4, maxHeight: 650 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Data</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!data.client_form_by_pk && !!data.client_form_by_pk.questions && 
                                    data.client_form_by_pk.questions.map((row) => {
                                        return (
                                            <React.Fragment>
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell align="left">{row.text}</TableCell>
                                                <TableCell align="left">{!!row.answers && row.answers.length > 0 ? row.answers[0].text : ''}</TableCell>
                                            </TableRow>
                                            {row.parts.length > 0 && 
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Box sx={{ margin: 1 }}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell />
                                                                        {row.parts[0].parts.map((headerPart) => (
                                                                            <TableCell key={headerPart.id}>{headerPart.text}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                {row.parts.map((part) => (
                                                                    <TableRow hover key={part.id}>
                                                                        <TableCell>{part.text}</TableCell>
                                                                        {part.parts.map((subPart) => (
                                                                            <TableCell key={subPart.id}>{subPart.answers[0].text}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Paper>
            <Divider />
        </Drawer>               
    );
}