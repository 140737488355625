import * as React from 'react';
import Memory from '../../Utils/Memory';
import { useApolloClient, gql } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';

const UPDATE_APPOINTMENT = gql`
mutation UpdateAppointment($args: updateAppointmentInput!) {
    updateAppointment(args: $args) {
      error
      message
    }
}`;

export default function ClientAppointmentsTable(props) {
    const { data, refetchQueries, fullDetails = true, displayTitle = true } = props;
    const apolloClient = useApolloClient();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [isActive, setIsActive] = React.useState('');
    const [editRow, setEditRow] = React.useState('');
    const [startTime, setStartTime] = React.useState(new Date());

    const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.clients_by_pk.appointments_aggregate.aggregate.count) : 0;

    const updateAppointment = (args) => apolloClient.mutate({
        mutation: UPDATE_APPOINTMENT,
        variables: { 
            args: args
        },
        fetchPolicy: "no-cache",
        refetchQueries: refetchQueries
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const editAppointment = async (apt) => {
        const update_apt_response = await updateAppointment({
            aptId: apt.id,
            orgId: apt.organization_id,
            aptSourceId: apt.source_id,
            userId: Memory.id,
            clientIds: [],
            sourceClientIds: [],
            aptTypeId: apt.appointment_type.id,
            start: startTime,
            end: (new Date(startTime.getTime() + apt.appointment_type.minute_duration*60000)).toISOString(),
            roomId: null,
            teamMemberId: null,
        });

        setEditRow('');
    };

  return (
    <React.Fragment>
        {displayTitle && 
            <Toolbar
                sx={{
                    pl: { xs: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Appointments
                </Typography>
            </Toolbar>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 4  }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Time</TableCell>
                            <TableCell align="left">Appointment Type</TableCell>
                            {fullDetails && <TableCell align="right">Status</TableCell>}
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!data && !!data.clients_by_pk && data.clients_by_pk.appointments_aggregate.aggregate.count > 0 && !!data.clients_by_pk.appointments && data.clients_by_pk.appointments.length > 0 && 
                        (rowsPerPage > 0
                            ? data.clients_by_pk.appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data.clients_by_pk.appointments
                        ).map((row) => {
                            const today = new Date();
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.appointment.id}
                                    onMouseEnter={()=>{setIsActive(row.appointment.id)}}
                                    onMouseLeave={()=>{setIsActive('')}}
                                >
                                    {editRow === row.appointment.id ?
                                        <React.Fragment>
                                            <TableCell colSpan={2}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateTimePicker
                                                        label="Time"
                                                        value={startTime}
                                                        onChange={(newTime) => setStartTime(newTime)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <TableCell align="left">{new Date(row.appointment.start).toLocaleDateString()}</TableCell>
                                            <TableCell align="left">{new Date(row.appointment.start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                        </React.Fragment> 
                                    }
                                    <TableCell align="left">{row.appointment.appointment_type ? row.appointment.appointment_type.name : ''}</TableCell>
                                    {fullDetails && <TableCell align="right">{row.appointment.status}</TableCell>}
                                    <TableCell align="center">
                                        {editRow === row.appointment.id ?
                                                <React.Fragment>
                                                    <Tooltip title="Save">
                                                        <IconButton size='small' sx={{ fontSize: '1.25rem', color: 'green'}} onClick={() => editAppointment(row.appointment)}>
                                                            <CheckIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Cancel">
                                                        <IconButton size='small' sx={{ fontSize: '1.25rem', color: 'red'}} onClick={() => setEditRow('')}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                    {isActive === row.appointment.id ?

                                                            <Tooltip title="Edit">
                                                                <IconButton size='small' sx={{ fontSize: '1.25rem'}} onClick={() => {setEditRow(row.appointment.id); setStartTime(new Date(row.appointment.start));}}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        :
                                                            ''
                                                    }
                                                </React.Fragment>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data ? data.clients_by_pk.appointments_aggregate.aggregate.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </React.Fragment>
  );
}
