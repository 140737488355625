import * as React from 'react';
import tippy from 'tippy.js';
import { useNavigate } from "react-router-dom";
import { useMutation, useApolloClient, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import FullCalendar from '@fullcalendar/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Unstable_Grid2';

import NewAppointmentDrawer from '../Forms/NewAppointmentDrawer';
import EditAppointmentDrawer from '../Forms/EditAppointmentDrawer';
import ImportIntegrationsDrawer from '../Forms/ImportIntegrationsDrawer';
import SidePanel from '../Widgets/SidePanel';

const GET_AVAILABILITY_CALENDAR = gql`
query GetAvailability($userId: uuid!, $start: timestamptz!, $end: timestamptz!) {
  appointment(where: {organization: {users: {id: {_eq: $userId}}}, _or: [{appointment_resources: {}}, {appointment_users: {}}], status: {_in: ["Scheduled","Arrived"]}, start: {_gte: $start}, end: {_lte: $end}}, order_by: {start: asc, appointment_type: {color: desc_nulls_last}}) {
    id
    start
    end
    appointment_type {
      name
      color
    }
    appointment_clients {
      client {
        id
        first_name
        last_name
      }
    }
    appointment_resources {
        resource {
            id
            name
        }
    }
    appointment_users {
        user {
            id
            first_name
            last_name
        }
    }
  }
  time_block(where: {organization: {users: {id: {_eq: $userId}}}, start: {_gte: $start}, end: {_lte: $end}}) {
    id
    label
    start
    end
    user {
      id
    }
  }
}`;

const GET_CALENDAR_APPOINTMENTS = gql`
query GetCalendarAppointments($start: timestamptz!, $end: timestamptz!) {
  calendar_appointment(where: {appointment: {status: {_in: ["Scheduled","Arrived"]}, start: {_gte: $start}, end: {_lte: $end}, appointment_type: {show_in_marketing_view: {_eq: true}}}}, order_by: {appointment: {start: asc, appointment_type: {color: desc_nulls_last}}}) {
    appointment {
      id
      start
      end
      status
      source_id
      appointment_type {
        id
        name
        color
      }
      appointment_clients {
        client {
          id
          first_name
          last_name
          source_id
        }
      }
      appointment_resources {
        resource {
          id
          name
        }
      }
      appointment_users {
        user {
          id
          first_name
          last_name
        }
      }
    }
    calendar {
      id
    }
  }
}`;

const GET_RESOURCES = gql`
query GetResources($userId: uuid!) {
  calendar(where: {organization: {users: {id: {_eq: $userId}}}}, order_by: {name: asc_nulls_last}) {
    id
    name
  }
}`;

const IMPORT_CALENDAR = gql`mutation ImportCalendar($userId: uuid!, $orgId: uuid!, $orgIntegrationId: uuid!) {
    importCalendar(args: {userId: $userId, orgId: $orgId, orgIntegrationId: $orgIntegrationId}) {
      error
      message
    }
}`;

export default function Availability() {
    let calendarRef = React.useRef();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const [open, setOpen] = React.useState(false);
    const [searchDate, setSearchDate] = React.useState(new Date());
    const [openEditEvent, setOpenEditEvent] = React.useState(false);
    const [event, setEvent] = React.useState(null);
    const [time, setTime] = React.useState(new Date());
    const [refresh, setRefresh] = React.useState(false);
    const [openImport, setOpenImport] = React.useState(false);
    const [openSidePanel, setOpenSidePanel] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClickViewSchedule = () => {
        navigate(`../schedule`);
    };

    const handleClickWaitingRoom = () => {
        navigate(`../queue`);
    };

    const [importCalendar] = useMutation(IMPORT_CALENDAR, {
        refetchQueries: [
        //   {
        //       query: GET_APPOINTMENT_TYPES,
        //       variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
        //   }
        ]
    });

    const request = (start, end) => apolloClient.query({
        query: GET_CALENDAR_APPOINTMENTS,
        variables: { start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const getResourcesRequest = () => apolloClient.query({
        query: GET_RESOURCES,
        variables: { userId: Memory.id },
    });

    const handleMouseEnterEvent = (args) => {
        let contentStr = args.event.title;
        if(!!contentStr) {
            tippy(args.el, {
                allowHTML: true,
                content: `${args.event.title}<br/>${args.event.extendedProps.resourcesTitle ?? ''}<br/>${args.event.extendedProps.usersTitle ?? ''}`,
                theme: 'calendar',
            });
        }
    }

    const handleDateChange = (val) => {
        setSearchDate(val);
        calendarRef.current.getApi().gotoDate(val);
    }

    const handleMouseClickEvent = (args) => {
        setEvent(args.event.extendedProps);
        setTime(new Date(args.event.extendedProps.start));
        setOpen(false);
        setOpenSidePanel(true);
    }

    async function getAvailabilityData(fetchInfo, successCallback) {
        const startDate = new Date(fetchInfo.start);
        const endDate = new Date(fetchInfo.end);
        const response = await request(startDate, endDate);
        let timeBlocks = [];

        successCallback(
            response.data.calendar_appointment.map(ca => {
                let apt = ca.appointment
                let resourceIds = [];
                let resourceNames = [];
                let users = [];

                apt.appointment_resources.forEach(r => {
                    resourceNames.push(r.resource.name);
                });

                apt.appointment_users.forEach(u => {
                    users.push(`${u.user.first_name} ${u.user.last_name}`);
                });

                return {
                    id: apt.id,
                    title: `${apt.appointment_type.name} - ${apt.appointment_clients.length > 0 ? apt.appointment_clients[0].client.first_name + ' ' + apt.appointment_clients[0].client.last_name : ''}`,
                    start: apt.start,
                    end: apt.end,
                    allDay: false,
                    resourceId: ca.calendar.id,
                    backgroundColor: apt.appointment_type.color != null ? apt.appointment_type.color : '#000',
                    extendedProps: {
                        id: apt.id,
                        organization_id: apt.organization_id,
                        source_id: apt.source_id,
                        typeId: apt.appointment_type.id,
                        typeName: apt.appointment_type.name,
                        start: apt.start,
                        end: apt.end,
                        status: apt.status,
                        resourcesTitle: resourceNames.join(' - '),
                        usersTitle: users.join(', '),
                        clientIds: apt.appointment_clients.map(ac => {
                            return {
                                title: `${ac.client.first_name} ${ac.client.last_name}`,
                                value: ac.client.id,
                                key: ac.client.id,
                                source_id: ac.client.source_id,
                            }
                        }),
                    }
                };
            })
        );
    }

    async function getResourcesData(fetchInfo, successCallback) {
        let resources = [];
        const response = await getResourcesRequest();

        response.data.calendar.forEach(c => {
            resources.push({ 
                id: c.id,
                title: c.name
            });
        });

        successCallback(resources);
    }

    const handleClickImport = () => {
        setOpenImport(!openImport)
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
            }}
        >
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid xs={openSidePanel ? 7 : 12}>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                width: '250px',
                                mr: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/dd/yyy"
                                    value={searchDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Typography sx={{ flexGrow: 1 }} />
                        {!openSidePanel && 
                            <Button startIcon={<ChevronLeftIcon />} onClick={() => setOpenSidePanel(!openSidePanel)}>
                                Open Side Panel
                            </Button>
                        }
                    </Toolbar>
                    <FullCalendar
                        ref={calendarRef}
                        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                        headerToolbar={{
                            left:'title',
                            center:'importCalendar newApt',
                            right:'today prev,next'
                        }}
                        rerenderDelay={10}
                        allDaySlot={false}
                        height='100%'
                        slotMinTime='05:00:00'
                        slotDuration='00:15:00'
                        eventMinHeight={20}
                        dayMinWidth={100}
                        dayMaxEvents={1}
                        slotEventOverlap={false}
                        stickyFooterScrollbar={true}
                        nowIndicator={true}
                        editable={false}
                        eventDisplay='-color'
                        eventStartEditable={false}
                        eventDurationEditable={false}
                        plugins={[ resourceTimeGridPlugin, interactionPlugin, scrollGridPlugin ]}
                        slotLabelContent={(args) => {}}
                        initialView="resourceTimeGridDay"
                        events={(fetchInfo, successCallback, failureCallback) => getAvailabilityData(fetchInfo, successCallback, failureCallback)}
                        resourceOrder='title'
                        resourceGroupField='label'
                        resources={(fetchInfo, successCallback, failureCallback) => getResourcesData(fetchInfo, successCallback, failureCallback)}
                        eventMouseEnter={handleMouseEnterEvent}
                        eventClick={handleMouseClickEvent}
                        views={{
                            day: {
                                titleFormat: {
                                    year: 'numeric', month: 'long', day: 'numeric', weekday: 'short'
                                },
                            },
                            week:{
                                dayMaxEvents: 20
                            }
                        }}
                        customButtons= {{
                            importCalendar: {
                                text: 'Import',
                                click: handleClickImport,
                            },
                            newApt: {
                                text: 'New Appointment',
                                click: handleClickOpen,
                            },
                        }}
                    />
                </Grid>
                {openSidePanel && 
                    <Grid xs={5}>
                        <SidePanel event={event} setEvent={setEvent} setOpenSidePanel={setOpenSidePanel} />
                    </Grid>
                }
            </Grid>
            {open && 
                <NewAppointmentDrawer calendar={calendarRef} open={open} setOpen={setOpen} refresh={refresh} setRefresh={setRefresh} />
            }
            {event != null && 
                <EditAppointmentDrawer calendar={calendarRef} event={event} startTime={time} setTime={setTime} open={openEditEvent} setOpen={setOpenEditEvent} refresh={refresh} setRefresh={setRefresh} />
            }
            <ImportIntegrationsDrawer  
              open={openImport}
              setOpen={setOpenImport}
              importFunction={importCalendar}
            />
        </Box>
    );
}