import * as React from 'react';
import axios from "axios";
import { useMutation, useQuery, gql } from '@apollo/client';
import { styled, useTheme } from '@mui/material/styles';
import Memory from '../../Utils/Memory';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IntegrationAuthInput from './IntegrationAuthInput';

const drawerWidth = 800;

const GET_INTEGRATIONS = gql`
query GetIntegrationsList($userId: uuid!) {
    getOrgIntegrations(args: {userId: $userId}) {
        orgIntegrations {
            id
            integration_provider_id
            integration_provider_name
            username
            password
            auth_key
        }
    }
    user_by_pk(id: $userId) {
        organization_id
    }
}`;

const UPDATE_INTEGRATION_AUTH = gql`
mutation UpdateIntegrationAuth($userId: uuid!, $orgId: uuid!, $orgIntegrationId: uuid!, $auth_key: String, $username: String, $password: String) {
    updateOrgIntegration(args: {userId: $userId, orgId: $orgId, orgIntegrationId: $orgIntegrationId, auth_key: $auth_key, username: $username, password: $password}) {
        error
        message
    }
}`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function ImportIntegrationsDrawer(props) {
    const { open, setOpen, importFunction } = props;
    const [processing, setProcessing] = React.useState(false);
    const [snack, setSnack] = React.useState(false);
  
    const theme = useTheme();

    const { data } = useQuery(GET_INTEGRATIONS, {
        variables: { userId: Memory.id },
    });

    const [updateIntegrationAuth] = useMutation(UPDATE_INTEGRATION_AUTH, {
        refetchQueries: [
          {
              query: GET_INTEGRATIONS,
              variables: { userId: Memory.id },
          }
        ]
    });

    const handleClose = () => {
        setProcessing(false);
        setOpen(false);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnack(false);
    };

    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                height: 'auto',
                width: drawerWidth,
                marginTop: '25vh',
            },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <Divider />
            <DrawerHeader>
                <IconButton onClick={handleClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Typography variant="h6">
                    Integrations
                </Typography>
            </DrawerHeader>
            <Divider />
            <List>
                {!!data && !!data.getOrgIntegrations && data.getOrgIntegrations.orgIntegrations.length > 0 && data.getOrgIntegrations.orgIntegrations.map((row, index) => {
                    return (
                        <ListItem key={row.id}>
                            <Button variant="contained" size='small' sx={{ mr: 4 }} onClick={() => importFunction({ variables: { userId: Memory.id, orgId: data.user_by_pk.organization_id, orgIntegrationId: row.id }})}>Import</Button>
                            <ListItemText primary={row.integration_provider_name} />
                            <Grid container spacing={2} sx={{ width: '100%', pb: 2, ml: 6, pr: 1 }}>
                                <Grid xs={4}>
                                    <IntegrationAuthInput label='API Key' setAuthValue={(val) => updateIntegrationAuth({ variables: { userId: Memory.id, orgId: data.user_by_pk.organization_id, orgIntegrationId: row.id, auth_key: val, username: row.username, password: row.password }})} authValue={row.auth_key} />
                                </Grid>
                                <Grid xs={4}>
                                    <IntegrationAuthInput label='Username' setAuthValue={(val) => updateIntegrationAuth({ variables: { userId: Memory.id, orgId: data.user_by_pk.organization_id, orgIntegrationId: row.id, auth_key: row.auth_key, username: val, password: row.password }})} authValue={row.username} />
                                </Grid>
                                <Grid xs={4}>
                                    <IntegrationAuthInput label='Password' setAuthValue={(val) => updateIntegrationAuth({ variables: { userId: Memory.id, orgId: data.user_by_pk.organization_id, orgIntegrationId: row.id, auth_key: row.auth_key, username: row.username, password: val }})} authValue={row.password} />
                                </Grid>
                            </Grid>
                        </ListItem>
                    )})
                }
            </List>
            <Divider />
            <Snackbar open={snack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {}
                </Alert>
            </Snackbar>
        </Drawer>               
    );
}