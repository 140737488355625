import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const GET_APPOINTMENT_TYPES = gql`
query GetAppointmentTypesList($userId: uuid!) {
  appointment_type(where: {organization: {users: {id: {_eq: $userId}}}, enable_scheduling: {_eq: true}}, order_by: {name: asc}) {
    id
    name
    source_id
    source_name
    organization_id
    minute_duration
    requires_resource
    requires_team_member
    enable_scheduling
    enable_recommendations
    color
  }
}`;

export default function AppointmentTypesInput(props) {
    const { setType } = props;
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        setType(event.target.value);
    };

    const { data } = useQuery(GET_APPOINTMENT_TYPES, {
        variables: { userId: Memory.id },
    });

    return (
        <TextField
          id="appointment-type-input"
          select
          fullWidth
          label="Appointment Type"
          value={value}
          onChange={handleChange}
        >
            {!!data && data.appointment_type.map((type) => (
                <MenuItem key={type.id} value={type}>
                    {`${type.name} - ${type.source_name}`}
                </MenuItem>
            ))}
            <MenuItem key='none' value='' />
        </TextField>
    );
}