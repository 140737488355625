import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ViewClientFormDrawer from './ViewClientFormDrawer';

export default function ClientTransactionsTable(props) {
    const { clientId, data, fullDetails = true, displayTitle = true } = props;
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [openViewClientFormDrawer, setOpenViewClientFormDrawer] = React.useState(false);
    const [clientFormTitle, setClientFormTitle] = React.useState('');
    const [clientFormId, setClientFormId] = React.useState('');

    const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.clients_by_pk.transactions_aggregate.aggregate.count) : 0;

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

  return (
    <React.Fragment>
        {displayTitle && 
            <Toolbar
                sx={{
                    pl: { xs: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
                >
                    Transactions
                </Typography>
            </Toolbar>
        }
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 4  }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            {fullDetails && 
                                <React.Fragment>
                                    <TableCell align="right">Insurance Charge</TableCell>
                                    <TableCell align="right">Insurace Approve</TableCell>
                                </React.Fragment>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!data && !!data.clients_by_pk && data.clients_by_pk.transactions_aggregate.aggregate.count > 0 && !!data.clients_by_pk.transactions && data.clients_by_pk.transactions.length > 0 && 
                        (rowsPerPage > 0
                            ? data.clients_by_pk.transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data.clients_by_pk.transactions
                        ).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    <TableCell align="left">{new Date(row.date).toLocaleDateString()}</TableCell>
                                    <TableCell align="left">{row.service ? row.service.name : ''}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                    {fullDetails && 
                                        <React.Fragment>
                                            <TableCell align="right">{row.insurance_charge_amount}</TableCell>
                                            <TableCell align="right">{row.insurance_approved_amount}</TableCell>
                                        </React.Fragment>
                                    }
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={fullDetails ? 5 : 3} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data ? data.clients_by_pk.transactions_aggregate.aggregate.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </React.Fragment>
  );
}
