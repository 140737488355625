import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { alpha } from '@mui/material/styles';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import ImportIntegrationsDrawer from '../Forms/ImportIntegrationsDrawer';

const GET_FORMS = gql`
query GetFormsTable($userId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  form(limit: $limit, offset: $offset, where: {organization: {users: {id: {_eq: $userId}}}, name: {_ilike: $searchFilter}}, order_by: {created_at: asc}) {
    id
    name
    status
    integration_provider {
      id
      name
    }
  }
  form_aggregate {
    aggregate {
      count
    }
  }
  user_by_pk(id: $userId) {
    organization_id
  }
}`;

const ADD_FORM = gql`mutation AddForm($name: String!, $orgId: uuid!) {
  insert_form_one(object: {name: $name, organization_id: $orgId}) {
    created_at
  }
}`;

const UPDATE_FORM = gql`mutation UpdateForm($name: String!, $formId: uuid!) {
  update_form_by_pk(pk_columns: {id: $formId}, _set: {name: $name}) {
    created_at
  }
}`;

const IMPORT_FORM = gql`mutation ImportForm($userId: uuid!, $orgId: uuid!, $orgIntegrationId: uuid!) {
  importForms(args: {userId: $userId, orgId: $orgId, orgIntegrationId: $orgIntegrationId}) {
    error
    message
  }
}`;

const EnhancedTableToolbar = (props) => {
  const { openImport, setOpenImport, numSelected, setFilter, addForm, data } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <React.Fragment>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Forms
          </Typography>
          <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              {!!data && !!data.user_by_pk && 
                <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addForm({ variables: { name: 'New Form', orgId: data.user_by_pk.organization_id } })}>Add Form</Button>
              }
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => setOpenImport(!openImport)}>Import</Button>
            </Grid>
          </Grid>
          <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
        </React.Fragment>
      )}

      {numSelected > 0 && 
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
};

export default function Forms(props) {
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState([]);
  const [isActive, setIsActive] = React.useState('');
  const [openImport, setOpenImport] = React.useState(false);

  const { data } = useQuery(GET_FORMS, {
    variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
  });

  const [addForm] = useMutation(ADD_FORM, {
    refetchQueries: [
      {
          query: GET_FORMS,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [updateForm] = useMutation(UPDATE_FORM, {
    refetchQueries: [
      {
          query: GET_FORMS,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [importForms] = useMutation(IMPORT_FORM, {
    refetchQueries: [
      {
        query: GET_FORMS,
        variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.form_aggregate.aggregate.count) : 0;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.form.map((c) => c.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <EnhancedTableToolbar openImport={openImport} setOpenImport={setOpenImport} numSelected={selected.length} setFilter={setFilter} addForm={addForm} data={data} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={!!data && selected.length > 0 && selected.length < data.form.length}
                  checked={!!data && data.form.length > 0 && selected.length === data.form.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data && data.form_aggregate.aggregate.count > 0 && !!data.form && data.form.length > 0 && 
            data.form.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onMouseEnter={()=>{setIsActive(row.id)}}
                  onMouseLeave={()=>{setIsActive('')}}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  {isActive === row.id ? 
                    <React.Fragment>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align='left'
                      >
                        <TextField id="update-form-name" fullWidth autoFocus={true} variant="standard" value={row.name} onChange={(e) => {updateForm({ variables: { name: e.target.value, formId: row.id } })}} />
                      </TableCell>
                      <TableCell align="left">{!!row.integration_provider ? row.integration_provider.name : ''}</TableCell>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align='left'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{!!row.integration_provider ? row.integration_provider.name : ''}</TableCell>
                    </React.Fragment>
                  }
                </TableRow>
              )})
            }
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (33) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 60, 90]}
        component="div"
        count={!!data ? data.form_aggregate.aggregate.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ImportIntegrationsDrawer  
        open={openImport}
        setOpen={setOpenImport}
        importFunction={importForms}
      />
    </Box>
  );
}