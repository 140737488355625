import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Send from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ClientsInput from '../Forms/ClientsInput';
import ClientAppointmentsTable from '../Widgets/ClientAppointmentsTable';
import ClientTransactionsTable from '../Widgets/ClientTransactionsTable';

const GET_MESSAGES = gql`
query GetMessages($orgId: uuid!, $page: Int!, $perPage: Int!) {
    getMessages(args: {orgId: $orgId, page: $page, perPage: $perPage}) {
        messages {
            clientId
            sourceClientId
            text
            first_name
            last_name
            unread
            updatedAt
        }
    }
}`;

const GET_CLIENT_MESSAGES = gql`
query GetClientMessages($orgId: uuid!, $clientId: uuid!, $page: Int!, $perPage: Int!) {
    getClientMessages(args: {orgId: $orgId, clientId: $clientId, page: $page, perPage: $perPage}) {
        messages {
            text
            sentFromOrg
            createdAt
        }
    }
}`;

const SEND_SMS = gql`
mutation SendSMS($orgId: uuid!, $clientId: uuid!, $clientSourceId: String, $msg: String!) {
    sendSMS(args: {orgId: $orgId, clientId: $clientId, clientSourceId: $clientSourceId, msg: $msg}) {
        message
        error
    }
}`;

const GET_CLIENT_DETAILS = gql`
query GetClientDetails($id: uuid!) {
    clients_by_pk(id: $id) {
        id
        first_name
        last_name
        email
        phone
        status
        note
        medical_note
        source_id
        client_forms(order_by: {created_at: asc}) {
          id
          status
          submitted_date
          organization_id
          integration_provider_id
          form {
            name
          }
          integration_provider {
            name
          }
          questions(where: {attachments: {}}) {
            attachments {
              id
              file_name
              source_id
              content_type
              url
            }
          }
        }
        client_forms_aggregate {
          aggregate {
            count
          }
        }
        appointments(order_by: {appointment: {start: desc}}) {
            appointment {
              id
              start
              status
              appointment_type {
                id
                name
              }
            }
        }
        appointments_aggregate {
            aggregate {
                count
            }
        }
        transactions(order_by: {date: desc}) {
            id
            date
            amount
            insurance_charge_amount
            insurance_approved_amount
            service {
              name
            }
        }
        transactions_aggregate {
            aggregate {
                count
            }
        }
    }
}`;

const UPDATE_CLIENT_NOTE = gql`mutation UpdateRoomCurrentAppointment($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {note: $text}) {
      created_at
    }
}`;

export default function Messages(props) {
    const chatAreaRef = React.useRef();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [client, setClient] = React.useState(null);
    const [newMessage, setNewMessage] = React.useState('');
    const [openClientDetails, seOpenClientDetails] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    const [sendSMS] = useMutation(SEND_SMS, {
        refetchQueries: [
          {
              query: GET_CLIENT_MESSAGES,
              variables: { orgId: Memory.orgId, clientId: client != null ? client.id : '', page: 1, perPage: 25 },
          }
        ]
    });

    const { data, startPolling } = useQuery(GET_MESSAGES, {
        variables: { orgId: Memory.orgId, page: 1, perPage: 25 },
        fetchPolicy: "no-cache",
    });

    startPolling(5000);
    
    const { data: clientMessages, startPolling: pollingClientMessages } = useQuery(GET_CLIENT_MESSAGES, {
        variables: { orgId: Memory.orgId, clientId: client != null ? client.id : '', page: 1, perPage: 25 },
        fetchPolicy: "no-cache",
    });

    pollingClientMessages(5000);

    const { data: clientData } = useQuery(GET_CLIENT_DETAILS, {
        variables: { id: !!client && !!client.id ? client.id : '' },
    });

    const [updateClientNote] = useMutation(UPDATE_CLIENT_NOTE, { refetchQueries: [
        {
            query: GET_CLIENT_DETAILS,
            variables: { id: client != null ? client.id : '' },
        }
    ]});

    React.useEffect(() => {
        if (client === null && !!data && !!data.getMessages && !!data.getMessages.messages && data.getMessages.messages.length > 0) {
            setClient({
                id:  data.getMessages.messages[0].clientId,
                first_name: data.getMessages.messages[0].first_name,
                last_name: data.getMessages.messages[0].last_name,
            })
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }

        if (!!chatAreaRef && !!chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [data, clientMessages, client]);
    
    const getTimeText = time => {
        const now = new Date()
        const dateTime = new Date(time)

        if (Math.floor((now - dateTime)/1000/60) === 0) {
            return 'just now'
        } else if (Math.floor((now - dateTime)/1000/60) <= 20) {
            return `${Math.floor((now - dateTime)/1000/60)} min`
        } else if (Math.floor((now - dateTime)/1000/60/60) <= 23){
            return dateTime.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})
        }

        return dateTime.toLocaleTimeString([], { month: 'short', day: 'numeric' }).split(',')[0]
    }

    const handleSetClient = newValue => {
        if (!!newValue && !!newValue.first_name && !!newValue.last_name) {
            setClient(newValue);
        }
    }

    const handleClickThread = (id, first_name, last_name, index) => {
        setClient({
            id: id,
            first_name: first_name,
            last_name: last_name,
        });
        setSelectedIndex(index);
    }

    const handleClickChevron = () => {
        seOpenClientDetails(!openClientDetails);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Grid container spacing={0} sx={{ height: '100%' }}>
                <Grid xs={3}>
                    <Toolbar
                        sx={{
                            pr: { xs: 1 },
                            pl: { xs: 1 },
                            pb: { xs: 1 },
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <ClientsInput setClient={handleSetClient} />
                        </Box>
                    </Toolbar>
                    <List disablePadding sx={{ height: '100%', maxHeight: '90vh', overflow: 'auto'}}>
                        {!!data && !!data.getMessages && !!data.getMessages.messages && data.getMessages.messages.map((row, index) => {
                            return (
                                <React.Fragment>
                                    <ListItem disableGutters disablePadding>
                                        <ListItemButton
                                            alignItems="flex-start"
                                            selected={selectedIndex === index}
                                            onClick={() => handleClickThread(row.clientId, row.first_name, row.last_name, index)}
                                        >
                                            <ListItemText primary={`${row.first_name} ${row.last_name}`} secondary={row.text.length > 85 ? row.text.substring(0, 82) + '...' : row.text} sx={{ width: 250, maxHeight: 75, overflow: 'hidden' }}/>
                                            <ListItemText secondary={getTimeText(row.updatedAt)} sx={{ textAlign: 'end' }}/>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Grid>
                <Grid xs={openClientDetails ? 4 : 9} sx={{ bgcolor: '#e7e7e7' }}>
                    <Toolbar
                        sx={{
                            bgcolor: '#fff'
                        }}
                    >
                        <Typography
                            sx={{ flexGrow: 1 }}
                            color="inherit"
                            variant="h5"
                            component="div"
                        >
                            {!!client ? `${client.first_name} ${client.last_name}` : ''}
                        </Typography>
                        <Typography sx={{ flexGrow: 1 }} />
                        {!openClientDetails && 
                            <Button startIcon={<ChevronLeftIcon />} onClick={handleClickChevron}>
                                Details
                            </Button>
                        }
                    </Toolbar>
                    <List ref={chatAreaRef} sx={{ height: '100%', maxHeight: '90vh', overflowY: 'auto', p: openClientDetails ? 4 : 20, pb: 0}}>
                        {!!clientMessages && !!clientMessages.getClientMessages && !!clientMessages.getClientMessages.messages && clientMessages.getClientMessages.messages.map((row) => {
                            return (
                                <React.Fragment>
                                    {row.sentFromOrg === true ?
                                        <React.Fragment>
                                            <ListItem disablePadding>
                                                <ListItemText />
                                                <ListItemText align='left' primary={`${row.text}`} sx={{ p: 2, width: '20%', bgcolor: '#54c6a4' }} />
                                            </ListItem>
                                            <ListItem disablePadding sx={{ mb: 2}}>
                                                <ListItemText align='right' secondary={getTimeText(row.createdAt)} sx={{ textAlign: 'end' }} />
                                            </ListItem>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <ListItem disablePadding>
                                                <ListItemText align='left' primary={`${row.text}`} sx={{ p: 2, width: '20%', bgcolor: '#fff' }} />
                                                <ListItemText />
                                            </ListItem>
                                            <ListItem disablePadding sx={{ mb: 2}}>
                                                <ListItemText align='left' secondary={getTimeText(row.createdAt)} sx={{ textAlign: 'start' }} />
                                            </ListItem>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        }).reverse()}
                    </List>
                    <Box sx={{ pl: openClientDetails ? 4 : 20, pr: openClientDetails ? 4 : 20}}>
                        <TextField
                            fullWidth
                            label='New Message'
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            sx={{ bgcolor: '#fff' }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                sendSMS({ variables: { orgId: Memory.orgId, clientId: client.id, clientSourceId: client.source_id, msg: newMessage }});
                                                setNewMessage("");
                                            }}
                                            onMouseDown={(event) => event.preventDefault()}
                                        >
                                            <Send />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                </Grid>
                {openClientDetails && 
                    <Grid xs={5} sx={{ pl: 4, pr: 4 }}>
                        <Toolbar
                            sx={{
                                bgcolor: '#fff'
                            }}
                        >
                            <Typography sx={{ flexGrow: 1 }} />
                            {openClientDetails && 
                                <Button startIcon={<ChevronRightIcon />} onClick={handleClickChevron}>
                                    Close Details
                                </Button>
                            }
                        </Toolbar>
                        <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">First Name</TableCell>
                                        <TableCell align="left">Last Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!clientData && !!clientData.clients_by_pk && 
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={clientData.clients_by_pk.id}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {clientData.clients_by_pk.first_name}
                                            </TableCell>
                                            <TableCell align="left">{clientData.clients_by_pk.last_name}</TableCell>
                                            <TableCell align="left">{clientData.clients_by_pk.email}</TableCell>
                                            <TableCell align="left">{clientData.clients_by_pk.phone}</TableCell>
                                            <TableCell align="center">{clientData.clients_by_pk.status}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TextField
                            id="filled-multiline-static"
                            label="Office Notes"
                            multiline
                            rows={10}
                            fullWidth
                            variant="filled"
                            color="warning"
                            focused
                            value={!!clientData && !!clientData.clients_by_pk ? clientData.clients_by_pk.note : ''}
                            onChange={(e) => updateClientNote({ variables: { clientId: client != null ? client.value : '', text: e.target.value }})}
                            sx={{ mb: 4 }}
                        />

                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ mb: 4 }}>
                            <Button variant={index === 0 ? 'contained' : 'outlined'} onClick={() => setIndex(0)}>Appointments</Button>
                            <Button variant={index === 1 ? 'contained' : 'outlined'} onClick={() => setIndex(1)}>Transactions</Button>
                        </ButtonGroup>

                        {index === 0 && <ClientAppointmentsTable data={clientData} fullDetails={false} displayTitle={false} />}
                        {index === 1 && <ClientTransactionsTable data={clientData} fullDetails={false} displayTitle={false} />}
                    </Grid>
                }
            </Grid>
        </Box>
    );
}