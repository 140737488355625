import * as React from 'react';
import tippy from 'tippy.js';
import { useNavigate } from "react-router-dom";
import { useQuery, useApolloClient, gql } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Memory from '../../Utils/Memory';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListIcon from '@mui/icons-material/List';
import EventIcon from '@mui/icons-material/Event';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import NewAppointmentDrawer from '../Forms/NewAppointmentDrawer';
import EditAppointmentDrawer from '../Forms/EditAppointmentDrawer';
import SidePanel from '../Widgets/SidePanel';
import NewCarePlan from '../Widgets/NewCarePlan';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SYNC_CALENDAR = gql`query SyncCalendar($orgId: uuid!, $start: timestamptz!, $end: timestamptz!) {
    syncAppointments(args: {orgId: $orgId, start: $start, end: $end}) {
        message
        error
    }
}`;

const GET_CALENDAR = gql`
query GetCalendar($orgId: uuid!, $start: timestamptz!, $end: timestamptz!) {
  appointment(where: {organization_id: {_eq: $orgId}, status: {_in: ["Scheduled","Arrived"]}, start: {_gte: $start}, end: {_lte: $end}, appointment_type: {show_in_office_view: {_eq: true}}}, order_by: {start: asc, appointment_type: {color: desc_nulls_last}}) {
    id
    start
    end
    status
    source_id
    organization_id
    appointment_type {
        id
        name
        color
    }
    appointment_clients {
      client {
        id
        first_name
        last_name
        source_id
      }
    }
    appointment_resources {
        resource {
            id
            name
        }
    }
    appointment_users {
        user {
            id
            first_name
            last_name
        }
    }
  }
}`;

const GET_CALENDAR_WITH_FILTER = gql`
query GetCalendar($orgId: uuid!, $clientIds: [uuid!], $start: timestamptz!, $end: timestamptz!) {
  appointment(where: {organization_id: {_eq: $orgId}, appointment_clients: {client_id: {_in: $clientIds}}, status: {_in: ["Scheduled","Arrived"]}, start: {_gte: $start}, end: {_lte: $end}, appointment_type: {show_in_office_view: {_eq: true}}}, order_by: {start: asc, appointment_type: {color: desc_nulls_last}}) {
    id
    start
    end
    status
    source_id
    organization_id
    appointment_type {
        id
        name
        color
    }
    appointment_clients {
      client {
        id
        first_name
        last_name
      }
    }
    appointment_resources {
        resource {
            id
            name
        }
    }
    appointment_users {
        user {
            id
            first_name
            last_name
        }
    }
  }
}`;

const LightenDarkenColor = (col, amt) => {
  
    var usePound = false;
  
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
};

const getData = (data, selected_apt_types) => {
    let events = [];
    let selectedAptTypes = {};

    if(!!selected_apt_types) {
        selected_apt_types.forEach(aptType => {
            if (selectedAptTypes[aptType.id] === undefined) {
                selectedAptTypes[aptType.id] = 1;
            }
        });
    }

    data.appointment.forEach(apt => {
        if (selectedAptTypes[apt.appointment_type.id] === 1) {
            let resourceNames = [];
            let users = [];
    
            apt.appointment_resources.forEach(r => {
                resourceNames.push(r.resource.name);
            });
    
            apt.appointment_users.forEach(u => {
                users.push(`${u.user.first_name} ${u.user.last_name}`);
            });
    
            events.push({
                id: apt.id,
                title: `${apt.appointment_type.name} - ${apt.appointment_clients.length > 0 ? apt.appointment_clients[0].client.first_name + ' ' + apt.appointment_clients[0].client.last_name : ''}`,
                start: apt.start,
                end: apt.end,
                allDay : false,
                backgroundColor: apt.appointment_type.color != null ? apt.status != null && apt.status === 'Arrived' ? LightenDarkenColor(apt.appointment_type.color, 100) : apt.appointment_type.color : '#000',
                extendedProps: {
                    id: apt.id,
                    organization_id: apt.organization_id,
                    source_id: apt.source_id,
                    typeId: apt.appointment_type.id,
                    typeName: apt.appointment_type.name,
                    start: apt.start,
                    end: apt.end,
                    status: apt.status,
                    resourcesTitle: resourceNames.join(' - '),
                    usersTitle: users.join(', '),
                    clientIds: apt.appointment_clients.map(ac => {
                        return {
                            title: `${ac.client.first_name} ${ac.client.last_name}`,
                            value: ac.client.id,
                            key: ac.client.id,
                            source_id: ac.client.source_id,
                        }
                    }),
                }
            });
        }
    });
    
    return events;
};

export default function Calendar() {
    let calendarRef = React.useRef();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openNewCarePlan, setOpenNewCarePlan] = React.useState(false);
    const [openSidePanel, setOpenSidePanel] = React.useState(false);
    const [openEditEvent, setOpenEditEvent] = React.useState(false);
    const [event, setEvent] = React.useState(null);
    const [time, setTime] = React.useState(new Date());
    const [refresh, setRefresh] = React.useState(false);
    // const [lastKey, setLastKey] = React.useState('');
    const [client, setClient] = React.useState([]);
    const [searchDate, setSearchDate] = React.useState(new Date());
    const [searchEndDate, setSearchEndDate] = React.useState(new Date());
    const [appointment_types, setAppointmentTypes] = React.useState([]);
    const [selected_apt_types, setSelectedAptTypes] = React.useState([]);
    const [viewType, setViewType] = React.useState(0);
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    const today = new Date();
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    searchDate.setHours(0, 0, 0);
    today.setHours(0, 0, 0);
    tomorrow.setHours(0, 0, 0);
    const [start, setStart] = React.useState(today);
    const [end, setEnd] = React.useState(tomorrow);

    // const handleKeyPress = React.useCallback((event) => {
    //     // console.log(event.key);
    //     if (lastKey === 'Control' && event.key === 'n') {
    //         setOpen(!open);
    //     }

    //     if (event.key === 'Escape') {
    //         setOpen(false);
    //     }

    //     setLastKey(event.key);
    // }, [open, lastKey]);
    
    // React.useEffect(() => {
    //     // attach the event listener
    //     document.addEventListener('keydown', handleKeyPress);

    //     // remove the event listener
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, [handleKeyPress]);

    const { data, startPolling } = useQuery(client != null && client.length > 0 ?  GET_CALENDAR_WITH_FILTER : GET_CALENDAR, {
        variables: client != null && client.length > 0 ? { orgId: Memory.orgId, clientIds: client.map(c => c.value), start: start.toISOString(), end: end.toISOString() } : { orgId: Memory.orgId, start: start.toISOString(), end: end.toISOString() },
        // variables: { orgId: Memory.orgId, start: "2022-11-22T06:00:00.288Z", end: "2022-11-23T05:59:59.288Z" },
        fetchPolicy: "no-cache",
    });

    startPolling(5000);

    const { startPolling: startSync } = useQuery(SYNC_CALENDAR, {
        variables: { orgId: Memory.orgId, start: start.toISOString(), end: end.toISOString() },
        // variables: { orgId: Memory.orgId, start: "2022-11-22T06:00:00.288Z", end: "2022-11-23T05:59:59.288Z" },
        fetchPolicy: "no-cache",
    });

    startSync(15000);

    React.useEffect(() => {
        if (!!data && !!data.appointment && data.appointment.length > 0) {
            let new_apt_types = [];
            let new_selected_types = [...selected_apt_types];
            let new_apt_types_map = {};
            let old_apt_types_map = {};
            let newTypesCount = 0;

            data.appointment.forEach(apt => {
                if (new_apt_types_map[apt.appointment_type.id] === undefined) {
                    new_apt_types_map[apt.appointment_type.id] = 1;
                    new_apt_types.push(apt.appointment_type);
                } else {
                    new_apt_types_map[apt.appointment_type.id] = new_apt_types_map[apt.appointment_type.id] + 1;
                }
            });

            appointment_types.forEach(type => {
                if (old_apt_types_map[type.id] !== 1) {
                    old_apt_types_map[type.id] = 1;
                }
            });

            new_apt_types = new_apt_types.map(type => {
                if (old_apt_types_map[type.id] !== 1) {
                    new_selected_types.push(type)
                    newTypesCount++;
                }
                return {
                    ...type,
                    count: new_apt_types_map[type.id]
                }
            });

            setAppointmentTypes(new_apt_types);

            if (newTypesCount > 0) {
                setSelectedAptTypes(new_selected_types);
            }
        }
    }, [data]);

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;

        let apt_types = [];
        let apt_types_map = {};
        
        value.forEach(aptType => {
            if (apt_types_map[aptType.id] === undefined) {
                apt_types.push(aptType);
                apt_types_map[aptType.id] = 1;
            } else if (apt_types_map[aptType.id] === 1) {
                apt_types = apt_types.filter(type => type.id != aptType.id);
                apt_types_map[aptType.id] = 2;
            }
        });

        setSelectedAptTypes(apt_types);
        setOffset(0);
        setPage(0);
    };

    const handleClickChevron = () => {
        setOpenSidePanel(!openSidePanel);
    };
  
    const handleClickOpen = () => {
        setOpen(!open);
        setOpenEditEvent(false);
    };

    const handleClickOpenNewCarePlan = () => {
        setOpenNewCarePlan(true);
    };

    const handleClickAvailability = () => {
        navigate(`../schedule/availability`);
    };

    const handleClickWaitingRoom = () => {
        navigate(`../queue`);
    };

    const handleClickLiveRoom = () => {
        navigate(`../live_room`);
    };

    const handleMouseEnterEvent = (args) => {
        let contentStr = args.event.title;
        contentStr = args.event.extendedProps.resourcesTitle === '' ? contentStr : contentStr.concat('<br/>', args.event.extendedProps.resourcesTitle);
        contentStr = args.event.extendedProps.usersTitle === '' ? contentStr : contentStr.concat('<br/>', args.event.extendedProps.usersTitle);
        tippy(args.el, {
            allowHTML: true,
            content: contentStr,
            theme: 'calendar',
        });
    }

    const handleMouseClickEvent = (args) => {
        setEvent(args.event.extendedProps);
        setTime(new Date(args.event.extendedProps.start));
        setOpen(false);
        setOpenSidePanel(true);
    }

    const handleDateChange = (val) => {
        setSearchDate(val);

        if (viewType === 0) {
            setSearchEndDate(val);
            calendarRef.current.getApi().gotoDate(val);
        } else if (viewType === 1) {
            setStart(val);
        }
    };

    const handleEndDateChange = (val) => {
        setSearchEndDate(val);
        setEnd(val);
    };

    async function setDateRange(fetchInfo, successCallback) {
        const startDate = new Date(fetchInfo.start);
        const endDate = new Date(fetchInfo.end);

        if (startDate.getTime() != start.getTime() || endDate.getTime() != end.getTime()) {
            setStart(startDate);
            setEnd(endDate);
        }

        successCallback([]);
    }

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
      };

    const eventData = !!data && !!data.appointment ? getData(data, selected_apt_types) : [];
    const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.appointment.length) : 0;

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
            }}
        >
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid xs={openSidePanel ? 7 : 12}>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                width: '250px',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={viewType === 0 ? "Date" : "Start Date"}
                                    inputFormat="MM/dd/yyy"
                                    value={searchDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                        {viewType === 1 && 
                            <Box
                                sx={{
                                    width: '250px',
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="MM/dd/yyy"
                                        value={searchEndDate}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        }
                        <Box>
                            <FormControl sx={{ width: 300 }}>
                                <Select
                                    labelId="appointment-type-multiple-checkbox-label"
                                    id="appointment-type-multiple-checkbox"
                                    multiple
                                    displayEmpty
                                    value={selected_apt_types ?? []}
                                    onChange={handleChange}
                                    renderValue={(selected) => "Appointment Types"}
                                    MenuProps={MenuProps}
                                >
                                {appointment_types.sort((a, b) =>  -1 * (a.count - b.count)).map((apt_type) => (
                                    <MenuItem key={apt_type.id} value={apt_type}>
                                        <Checkbox checked={!!selected_apt_types && selected_apt_types.findIndex(aptType => aptType.id === apt_type.id) > -1} />
                                        <ListItemText primary={apt_type.name} />
                                        <Typography variant="body2" color="text.secondary">
                                            {apt_type.count}
                                        </Typography>
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography sx={{ flexGrow: 1 }} />

                        <IconButton aria-label="Calendar View" size='large' sx={{ color: viewType === 0 ? '#2C3E50' : ''}} onClick={() => {setViewType(0); handleDateChange(today);}}>
                            <EventIcon />
                        </IconButton>

                        <IconButton aria-label="List View" size='large' sx={{ color: viewType === 1 ? '#2C3E50' : ''}} onClick={() => {
                            let nextDate = new Date(searchDate);
                            nextDate.setHours(23, 59, 59);
                            setViewType(1);
                            handleEndDateChange(nextDate);
                        }}>
                            <ListIcon />
                        </IconButton>

                        {!openSidePanel && 
                            <Button startIcon={<ChevronLeftIcon />} onClick={handleClickChevron}>
                                Open Side Panel
                            </Button>
                        }
                    </Toolbar>
                    {viewType === 0 && 
                        <FullCalendar
                            ref={calendarRef}
                            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                            headerToolbar={{
                                left:'title',
                                center:'newApt newCarePlan',
                                right:'timeGridDay,dayGridWeek,dayGridMonth today prev,next'
                            }}
                            rerenderDelay={10}
                            displayEventTime={false}
                            allDaySlot={false}
                            height='100%'
                            slotMinTime='05:00:00'
                            slotDuration='00:15:00'
                            eventMinHeight={20}
                            dayMinWidth={100}
                            dayMaxEvents={1}
                            slotEventOverlap={false}
                            stickyFooterScrollbar={true}
                            nowIndicator={true}
                            editable={false}
                            eventOrder='start,-duration,-color'
                            eventStartEditable={false}
                            eventDurationEditable={false}
                            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, scrollGridPlugin ]}
                            slotLabelContent={(args) => {}}
                            initialView="timeGridDay"
                            // events={!!data && !!data.appointment ? getData(data) : []}
                            eventSources={[
                                eventData,
                                (fetchInfo, successCallback, failureCallback) => setDateRange(fetchInfo, successCallback, failureCallback)
                            ]}
                            eventMouseEnter={handleMouseEnterEvent}
                            eventClick={handleMouseClickEvent}
                            views={{
                                day: {
                                    titleFormat: {
                                        year: 'numeric', month: 'long', day: 'numeric', weekday: 'short'
                                    },
                                },
                                week:{
                                    dayMaxEvents: 20
                                }
                            }}
                            customButtons= {{
                                newApt: {
                                    text: 'New Appointment',
                                    click: handleClickOpen,
                                },
                                newCarePlan: {
                                    text: 'New Care Plan',
                                    click: handleClickOpenNewCarePlan,
                                },
                            }}
                        />
                    }
                    {viewType === 1 && 
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">End Time</TableCell>
                                        <TableCell align="left">Appointment Type</TableCell>
                                        <TableCell align="left">Client</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!data && data.appointment.length > 0 && 
                                        (rowsPerPage > 0
                                            ? eventData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : eventData
                                        ).map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {new Date(row.start).toLocaleDateString()}
                                                </TableCell>
                                                <TableCell align="left">{new Date(row.start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                                <TableCell align="left">{new Date(row.end).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                                <TableCell align="left">{row.extendedProps.typeName}</TableCell>
                                                <TableCell align="left">{row.extendedProps.clientIds.map(c => c.title).join(", ")}</TableCell>
                                                <TableCell align="right">{row.extendedProps.status}</TableCell>
                                            </TableRow>
                                        )})
                                    }
                                    {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                        height: (33) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                    )}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[30, 60, 90]}
                                component="div"
                                count={eventData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </React.Fragment>
                    }
                </Grid>
                {openSidePanel && 
                    <Grid xs={5}>
                        <SidePanel event={event} setEvent={setEvent} setOpenSidePanel={setOpenSidePanel} />
                    </Grid>
                }
            </Grid>
            {open && 
                <NewAppointmentDrawer calendar={calendarRef} open={open} setOpen={setOpen} refresh={refresh} setRefresh={setRefresh} />
            }
            {openNewCarePlan && 
                <NewCarePlan open={openNewCarePlan} setOpen={setOpenNewCarePlan} />
            }
            {event != null && 
                <EditAppointmentDrawer calendar={calendarRef} apt={event} startTime={time} setTime={setTime} open={openEditEvent} setOpen={setOpenEditEvent} refresh={refresh} setRefresh={setRefresh} />
            }
        </Box>
    );
}