import * as React from 'react';
import tippy from 'tippy.js';
import { useReactToPrint } from 'react-to-print';
import { useQuery, gql } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Calendar from 'rc-year-calendar';
import Memory from '../../Utils/Memory';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import AppointmentTypesInput from '../Forms/AppointmentTypesInput';
import ClientsInput from '../Forms/ClientsInput';
import AddServiceTable from '../Widgets/AddServiceTable';
import AddProductTable from '../Widgets/AddProductTable';

const GET_CARE_PLANS = gql`
query GetCarePlansTable($orgId: uuid!) {
    care_plan(where: {organization_id: {_eq: $orgId}}, order_by: {name: asc}) {
      id
      name
      description
      status
      services(order_by: {service: {name: asc}}) {
        id
        quantity
        service {
          name
          description
          charge_amount
          insurance_charge_amount
          insurance_charge_quantity
          products(order_by: {product: {name: asc}}) {
            id
            quantity
            product {
              id
              name
              description
              charge_amount
              is_supply_item
            }
          }
          pricings(order_by: { pricing: {name: asc}}) {
            id
            amount
            pricing {
              id
              name
              status
            }
          }
        }
      }
      products(order_by: {product: {name: asc}}) {
        id
        quantity
        product {
          id
          name
          description
          charge_amount
          is_supply_item
        }
      }
      pricings(order_by: { pricing: {name: asc}}) {
        id
        amount
        pricing {
          id
          name
          status
        }
      }
    }
    pricing(order_by: {name: asc}) {
        id
        name
        status
    }
  }`;

const DEFAULT_RECOMMENDATIONS = [
    {
        start: new Date().setHours(11, 0, 0, 0),
        s: false,
        m: false,
        t: false,
        w: false,
        th: false,
        f: false,
        sa: false,
        weeks: 0,
    },{
        start: new Date().setHours(11, 0, 0, 0),
        s: false,
        m: false,
        t: false,
        w: false,
        th: false,
        f: false,
        sa: false,
        weeks: 0,
    },{
        start: new Date().setHours(11, 0, 0, 0),
        s: false,
        m: false,
        t: false,
        w: false,
        th: false,
        f: false,
        sa: false,
        weeks: 0,
    }
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NewCarePlan(props) {
  const printRef = React.useRef();
  const { open, setOpen } = props;
  const [refreshCal, setRefreshCal] = React.useState(true);
  const [showPrintPreview, setShowPrintPreview] = React.useState(false);
  const [services, setServices] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [discounts, setDiscounts] = React.useState([]);
  const [appointments, setAppointments] = React.useState([]);
  const [recommendations, setRecommendations] = React.useState(DEFAULT_RECOMMENDATIONS);
  const [isActive, setIsActive] = React.useState('');
  const [index, setIndex] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [newAptType, setNewAptType] = React.useState(null);
  const [newAptTime, setNewAptTime] = React.useState(new Date());
  const [clients, setClients] = React.useState([]);
  const [carePlan, setCarePlan] = React.useState({});
  const [displayAppointments, setDisplayAppointments] = React.useState(true);
  const [displayServices, setDisplayServices] = React.useState(true);
  const [displayProducts, setDisplayProducts] = React.useState(true);
  const [displayDiscounts, setDisplayDiscounts] = React.useState(true);

  const servicesCostTotal = services.reduce((total, s) => total + (s.quantity * s.charge_amount), 0);
  const insuranceSavingsTotal = services.reduce((total, s) => total + (Math.min(s.insurance_charge_quantity, s.quantity) * (s.charge_amount - s.out_of_pocket)), 0);
  const productsCostTotal = products.reduce((total, p) => total + (p.quantity * p.charge_amount), 0);
  const discountsTotal = discounts.filter(d => d.amount > 0).reduce((total, d) => total + (d.amount * 1), 0);

  const months = appointments.length > 0 ? appointments[appointments.length - 1].start.getMonth() - appointments[0].start.getMonth() + 12 * (appointments[appointments.length - 1].start.getFullYear() - appointments[0].start.getFullYear()) : 0;
  let servicePricingTotals = new Map();
  let appointmentMap = new Map();

  for (let apt of appointments) {
    if (appointmentMap.has(apt.appointment_type.id)) {
        appointmentMap.set(apt.appointment_type.id, {
            name: apt.appointment_type.name,
            enable_recommendations: apt.appointment_type.enable_recommendations,
            total: appointmentMap.get(apt.appointment_type.id).total + 1,
        })
    } else {
        appointmentMap.set(apt.appointment_type.id, {
            name: apt.appointment_type.name,
            enable_recommendations: apt.appointment_type.enable_recommendations,
            total: 1,
        })
    }
  };

  for (let s of services) {
    if (!!s.pricings && s.pricings.length > 0) {
        for (let p of s.pricings) {
            if (servicePricingTotals.has(p.pricing.id)) {
                servicePricingTotals.set(p.pricing.id, {
                    id: p.pricing.id,
                    name: p.pricing.name,
                    total: servicePricingTotals.get(p.pricing.id).total + ((s.quantity - s.insurance_charge_quantity) * p.amount) + (Math.min(s.insurance_charge_quantity, s.quantity) * s.out_of_pocket),
                })
            } else {
                servicePricingTotals.set(p.pricing.id, {
                    id: p.pricing.id,
                    name: p.pricing.name,
                    total: ((s.quantity - s.insurance_charge_quantity) * p.amount) + (Math.min(s.insurance_charge_quantity, s.quantity) * s.out_of_pocket),
                })
            }
        }
    }
  };

  const servicePricingArray = Array.from(servicePricingTotals.values()).sort((a, b) => a.name < b.name);

  const { data } = useQuery(GET_CARE_PLANS, {
    variables: { orgId: Memory.orgId },
    fetchPolicy: "no-cache",
  });

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - appointments.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRecommendationRow = () => {
    setRecommendations([
        ...recommendations,
        {
            start: new Date().setHours(11, 0, 0, 0),
            s: false,
            m: false,
            t: false,
            w: false,
            th: false,
            f: false,
            sa: false,
            weeks: 0,
        }
    ]);
  };

  const handleAddService = (newService) => {
    setServices([
        ...services,
        {
            ...newService,
            quantity: 1,
            out_of_pocket: 0,
        }
    ]);
  };

  const handleAddProduct = (newProduct) => {
    setProducts([
        ...products,
        {
            ...newProduct,
            quantity: 1,
        }
    ]);
  };

  const handleAddDiscount = () => {
    setDiscounts([
        ...discounts,
        {
            description: `Discount ${discounts.length + 1}`,
            amount: 0,
        }
    ]);
  };

  const handleUpdateRecommendations = (index, row) => {
    let newRecommendations = recommendations;
    newRecommendations[index] = row;
    setRecommendations([...newRecommendations]);
  };

  const handleUpdateService = (index, row) => {
    let newServices = services;
    newServices[index] = row;
    setServices([...newServices]);
  };
  
  const handleUpdateProduct = (index, row) => {
    let newProducts = products;
    newProducts[index] = row;
    setProducts([...newProducts]);
  };

  const handleUpdateDiscount = (index, row) => {
    let newDiscounts = discounts;
    newDiscounts[index] = row;
    setDiscounts([...newDiscounts]);
  };

  const handleDeleteAppointment = (index) => {
    setAppointments([...appointments.slice(0, index), ...appointments.slice(index + 1)]);
  };

  const handleDeleteRecommenation = (index) => {
    setRecommendations([...recommendations.slice(0, index), ...recommendations.slice(index + 1)]);
  };

  const handleDeleteService = (index) => {
    setServices([...services.slice(0, index), ...services.slice(index + 1)]);
  };

  const handleDeleteProduct = (index) => {
    setProducts([...products.slice(0, index), ...products.slice(index + 1)]);
  };

  const handleDeleteDiscount = (index) => {
    setDiscounts([...discounts.slice(0, index), ...discounts.slice(index + 1)]);
  };

  const handleAddAppointment = () => {
    let newApts = appointments;

    if (!!newAptType && !newAptType.enable_recommendations) {
        newApts.push({
            start: newAptTime,
            startDate: newAptTime,
            endDate: newAptTime,
            appointment_type: newAptType,
            name: newAptType.name,
            color: newAptType.color,
        });
    }

    newApts.sort((a, b) => a.start - b.start);
    setAppointments([...newApts]);
    setRefreshCal(!refreshCal);
  };

  const handleAddAppointments = () => {
    let newApts = appointments;

    if (!!newAptType && newAptType.enable_recommendations) {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - startDate.getDay() + 7);

        for (let r of recommendations) {
            for (let i = 0; i < r.weeks; i ++) {
                if (r.su) {
                    newApts.push({
                        start: startDate,
                        startDate: startDate,
                        endDate: startDate,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                } 
                
                if (r.m) {
                    let monday = new Date(startDate);
                    monday.setDate(startDate.getDate() + 1);
                    newApts.push({
                        start: monday,
                        startDate: monday,
                        endDate: monday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                }

                if (r.t) {
                    let tuesday = new Date(startDate);
                    tuesday.setDate(startDate.getDate() + 2);
                    newApts.push({
                        start: tuesday,
                        startDate: tuesday,
                        endDate: tuesday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                } 
                
                if (r.w) {
                    let wednesday = new Date(startDate);
                    wednesday.setDate(startDate.getDate() + 3);
                    newApts.push({
                        start: wednesday,
                        startDate: wednesday,
                        endDate: wednesday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                } 
                
                if (r.th) {
                    let thursday = new Date(startDate);
                    thursday.setDate(startDate.getDate() + 4);
                    newApts.push({
                        start: thursday,
                        startDate: thursday,
                        endDate: thursday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                } 
                
                if (r.f) {
                    let friday = new Date(startDate);
                    friday.setDate(startDate.getDate() + 5);
                    newApts.push({
                        start: friday,
                        startDate: friday,
                        endDate: friday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                } 
                
                if (r.sa) {
                    let saturday = new Date(startDate);
                    saturday.setDate(startDate.getDate() + 6);
                    newApts.push({
                        start: saturday,
                        startDate: saturday,
                        endDate: saturday,
                        appointment_type: newAptType,
                        name: newAptType.name,
                        color: '#53e06a',
                    });
                }
                startDate.setDate(startDate.getDate() + 7);
            }
        }
    }

    newApts.sort((a, b) => a.start - b.start);
    setAppointments([...newApts]);
    setRefreshCal(!refreshCal);
  };

  const handleMouseEnterEvent = (e) => {
    if (!!e.events && e.events.length > 0) {
        let contentStr = e.events[0].appointment_type.name;
        tippy(e.element, {
            allowHTML: true,
            content: contentStr,
            theme: 'calendar',
        });
    }
  };

  const handlePrint = useReactToPrint({
        content: () => printRef.current,
  });

  const handleSetCarePlan = (e) => {
    const cp = e.target.value;
    let newServices = [];
    let newProducts = [];

    for(let s of cp.services) {
        newServices.push({
            ...s.service,
            quantity: s.quantity,
            charge_amount: 0,
            insurance_charge_quantity: 0,
            insurance_charge_amount: 0,
            out_of_pocket: 0,
            care_plan: true,
        });

        for(let p of s.service.products) {
            newProducts.push({
                ...p.product,
                quantity: p.quantity,
                charge_amount: 0,
                care_plan: true,
            });
        }
    }

    for(let p of cp.products) {
        newProducts.push({
            ...p.product,
            quantity: p.quantity,
            charge_amount: 0,
            care_plan: true,
        });
    }

    setCarePlan(cp);
    setServices([...newServices]);
    setProducts([...newProducts]);
    setDisplayAppointments(true);
    setDisplayServices(true);
    setDisplayProducts(true);
    setDisplayDiscounts(true);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen
        TransitionComponent={Transition}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid xs={3}>
                    <Typography gutterBottom variant="h6" sx={{ mr: 4 }}>
                        New Care Plan
                    </Typography>
                </Grid>
                <Grid xs={5}>
                </Grid>
            </Grid>
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid xs={3}>
                    {showPrintPreview === false && 
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                id="appointment-type-input"
                                select
                                fullWidth
                                label="Care Plan"
                                value={carePlan}
                                onChange={handleSetCarePlan}
                                sx={{ mb: 2 }}
                            >
                                {!!data && data.care_plan.map((cp) => (
                                    <MenuItem key={cp.id} value={cp}>{cp.name}</MenuItem>
                                ))}
                                <MenuItem key='none' value={{id: '', name: 'Custom', services: [], products: []}}>Custom</MenuItem>
                            </TextField>
                            <ClientsInput multiple={true} setClient={setClients} />
                        </Box>
                    }
                    <Toolbar
                        sx={{
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Typography gutterBottom variant="h6" sx={{ mr: 4 }}>
                            Summary
                        </Typography>
                    </Toolbar>
                    {appointments.length > 0 && 
                        <List
                            sx={{ bgcolor: 'background.paper', p: 0 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton disableRipple disableGutters onClick={() => setDisplayAppointments(!displayAppointments)} sx={{p:0}}>
                                <ListItemText primary="Appointments" />
                                {displayAppointments ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={displayAppointments} timeout="auto" unmountOnExit>
                                {
                                    Array.from(appointmentMap.values()).map(apt => (
                                        <React.Fragment>
                                            <Typography variant="subtitle1" sx={{ ml: 4 }}>{`${apt.total}x ${apt.name}`}</Typography>
                                            {apt.enable_recommendations && 
                                                recommendations.map(r => {
                                                    let frequency = 0;

                                                    if (r.su) {
                                                        frequency++;
                                                    } 
                                                    
                                                    if (r.m) {
                                                        frequency++;
                                                    }
                                    
                                                    if (r.t) {
                                                        frequency++;
                                                    } 
                                                    
                                                    if (r.w) {
                                                        frequency++;
                                                    } 
                                                    
                                                    if (r.th) {
                                                        frequency++;
                                                    } 
                                                    
                                                    if (r.f) {
                                                        frequency++;
                                                    } 
                                                    
                                                    if (r.sa) {
                                                        frequency++;
                                                    }

                                                    return (
                                                        <ListItemText disablePadding sx={{ pl: 8 }} primary={`${frequency}x week / ${r.weeks} weeks`} />
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </Collapse>
                        </List>
                    }
                    {services.length > 0 && 
                        <List
                            sx={{ bgcolor: 'background.paper', p: 0 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton disableRipple disableGutters onClick={() => setDisplayServices(!displayServices)} sx={{p:0}}>
                                <ListItemText primary="Services" />
                                {displayServices ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={displayServices} timeout="auto" unmountOnExit>
                                {
                                    services.map(s => (
                                        <ListItemText disablePadding sx={{ pl: 4 }} primary={`${s.quantity}x ${s.name}`} />
                                    ))
                                }
                            </Collapse>
                        </List>
                    }
                    {products.length > 0 && 
                        <List
                            sx={{ bgcolor: 'background.paper', p: 0 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton disableRipple disableGutters onClick={() => setDisplayProducts(!displayProducts)} sx={{p:0}}>
                                <ListItemText primary="Products" />
                                {displayProducts ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={displayProducts} timeout="auto" unmountOnExit>
                                {
                                    products.map(p => (
                                        <ListItemText disablePadding sx={{ pl: 4 }} primary={`${p.quantity}x ${p.name}`} />
                                    ))
                                }
                            </Collapse>
                        </List>
                    }
                    {discounts.filter(d => d.amount > 0).length > 0 && 
                        <List
                            sx={{ bgcolor: 'background.paper', p: 0 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton disableRipple disableGutters onClick={() => setDisplayDiscounts(!displayDiscounts)} sx={{p:0}}>
                                <ListItemText primary="Discounts" />
                                {displayDiscounts ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={displayDiscounts} timeout="auto" unmountOnExit>
                                {
                                    discounts.filter(d => d.amount > 0).map(d => (
                                        <ListItemText disablePadding sx={{ pl: 4 }} primary={`- $${d.amount} ${d.description}`} />
                                    ))
                                }
                            </Collapse>
                        </List>
                    }
                    {
                        <Box sx={{ mt: 3 }}>
                            <Typography gutterBottom variant="h7">Cost Totals</Typography>
                            <Typography variant="subtitle1" sx={{ ml: 4 }}>{`Full Charge Price: $${(servicesCostTotal - discountsTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Typography>
                            <Typography variant="subtitle1" sx={{ ml: 4 }}>{`Savings: $${(insuranceSavingsTotal + discountsTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Typography>
                            {
                                servicePricingArray.map(p => (
                                    <Typography variant="subtitle1" sx={{ ml: 4 }}>{`${p.name}: $${(p.total - discountsTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</Typography>
                                ))
                            }
                        </Box>
                    }
                </Grid>
                {showPrintPreview === false && 
                    <React.Fragment>
                        <Grid xs={5}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth size='large' sx={{ mb: 2, height: 56 }}>
                                <Button variant={index === 0 ? 'contained' : 'outlined'} onClick={() => setIndex(0)}>Appointments</Button>
                                <Button variant={index === 1 ? 'contained' : 'outlined'} onClick={() => setIndex(1)}>Services</Button>
                                <Button variant={index === 2 ? 'contained' : 'outlined'} onClick={() => setIndex(2)}>Products</Button>
                                <Button variant={index === 3 ? 'contained' : 'outlined'} onClick={() => setIndex(3)}>Discounts</Button>
                            </ButtonGroup>
                            {index === 0 && 
                                <React.Fragment>
                                    <Box sx={{ width: '100%' }}>
                                        <Stack spacing={2}>
                                            <AppointmentTypesInput setType={setNewAptType} />
                                            {!!newAptType && newAptType.enable_recommendations === false && 
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateTimePicker
                                                        label="Time"
                                                        value={newAptTime}
                                                        onChange={(newTime) => setNewAptTime(newTime)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            }
                                            {!!newAptType && newAptType.enable_recommendations === true && 
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ flex: '1 1 100%' }}
                                                        variant="h6"
                                                        id="tableTitle"
                                                        component="div"
                                                    >
                                                        Recommendations
                                                    </Typography>
                                                    {
                                                        <TableContainer sx={{ mb: 4 }}>
                                                            <Table
                                                                size="small"
                                                                aria-label="discounts table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" padding='none'></TableCell>
                                                                        <TableCell align="center" padding='none'>Time</TableCell>
                                                                        <TableCell align="center" padding='none'>Su</TableCell>
                                                                        <TableCell align="center" padding='none'>M</TableCell>
                                                                        <TableCell align="center" padding='none'>T</TableCell>
                                                                        <TableCell align="center" padding='none'>W</TableCell>
                                                                        <TableCell align="center" padding='none'>Th</TableCell>
                                                                        <TableCell align="center" padding='none'>F</TableCell>
                                                                        <TableCell align="center" padding='none'>Sa</TableCell>
                                                                        <TableCell align="center" padding='none'>Weeks</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        recommendations.map((row, index) => (
                                                                            <TableRow
                                                                                key={'discount_'+index}
                                                                                hover
                                                                                sx={{ bgcolor: '#fff' }}
                                                                                onMouseEnter={()=>{setIsActive('discount_'+index)}}
                                                                                onMouseLeave={()=>{setIsActive('')}}
                                                                            >
                                                                                <TableCell align='left' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <IconButton aria-label="delete-discount" sx={{ color: '#d10e0e' }} onClick={() => handleDeleteRecommenation(index)}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell align='center' sx={{ borderBottom: 'none' }}>
                                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                        <TimePicker
                                                                                            value={row.start}
                                                                                            onChange={(newValue) => {
                                                                                                handleUpdateRecommendations(index, {...recommendations[index], start: newValue} );
                                                                                            }}
                                                                                            renderInput={(params) => <TextField {...params} />}
                                                                                        />
                                                                                    </LocalizationProvider>
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.su} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], su: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.m} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], m: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.t} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], t: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.w} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], w: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.th} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], th: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.f} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], f: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <Checkbox checked={row.sa} onClick={(e) => handleUpdateRecommendations(index, {...recommendations[index], sa: e.target.checked})} />
                                                                                </TableCell>
                                                                                <TableCell align='center' padding='none' sx={{ borderBottom: 'none' }}>
                                                                                    <TextField variant="standard" value={row.weeks} onChange={(e) => handleUpdateRecommendations(index, {...recommendations[index], weeks: e.target.value})} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    }
                                                    <Button onClick={handleAddRecommendationRow}>Add Row</Button>
                                                </React.Fragment>
                                            }
                                        </Stack>
                                    </Box>
                                    {!!newAptType && newAptType.enable_recommendations === false && 
                                        <Button variant='contained' disabled={!!!newAptType} onClick={() => handleAddAppointment()} sx={{ mt: 4, bgcolor: '#66bb75', '&:hover': {backgroundColor: '#5da36a'} }}>Create Appointment</Button>
                                    }
                                    {!!newAptType && newAptType.enable_recommendations === true && 
                                        <Button variant='contained' disabled={!!!newAptType} onClick={() => handleAddAppointments()} sx={{ mt: 4, bgcolor: '#66bb75', '&:hover': {backgroundColor: '#5da36a'} }}>Create Appointments</Button>
                                    }
                                    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 6 }}>
                                        <TableContainer sx={{ maxHeight: 600 }}>
                                            <Table stickyHeader size="small" aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="left">Date</TableCell>
                                                        <TableCell align="left">Time</TableCell>
                                                        <TableCell align="left">Appointment Type</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        appointments.map((row, index) => (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={'appointment_'+index}>
                                                                <TableCell align='center'>
                                                                    <IconButton aria-label="delete-discount" sx={{ color: '#d10e0e' }} onClick={() => handleDeleteAppointment(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                                <TableCell align="left">{new Date(row.start).toLocaleDateString()}</TableCell>
                                                                <TableCell align="left">{new Date(row.start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                                                <TableCell align="left">{row.appointment_type ? row.appointment_type.name : ''}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    {emptyRows > 0 && 
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[30, 60, 90]}
                                            component="div"
                                            count={appointments.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </React.Fragment>
                            }
                            {index === 1 && 
                                <TableContainer component={Paper} sx={{ mb: 4 }}>
                                    <Table
                                        size="small"
                                        aria-label="services table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="center">Qty</TableCell>
                                                <TableCell align="right">Charge Amount</TableCell>
                                                <TableCell align="center">Insurance Qty</TableCell>
                                                <TableCell align="right">Out of Pocket Amount</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                services.map((row, index) => {
                                                    let chargeTotal = !!row.charge_amount ? (row.quantity - row.insurance_charge_quantity) * row.charge_amount : 0;
                                                    let insTotal = !!row.out_of_pocket ? (Math.min(row.insurance_charge_quantity, row.quantity) * row.out_of_pocket) : 0;
                                                    return (
                                                    <TableRow
                                                        key={'service_'+index}
                                                        hover
                                                        sx={{ bgcolor: '#fff' }}
                                                        onMouseEnter={()=>{setIsActive('service_'+index)}}
                                                        onMouseLeave={()=>{setIsActive('')}}
                                                    >
                                                        <TableCell align='left' sx={{ p: 0 }}>
                                                            <IconButton aria-label="delete-service" sx={{ color: '#d10e0e' }} onClick={() => handleDeleteService(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align='left'>{row.name}</TableCell>

                                                        {isActive === 'service_'+index ? 
                                                            <React.Fragment>
                                                                <TableCell align='center'>
                                                                    <TextField autoFocus={true} variant="standard" value={row.quantity.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} onChange={(e) => handleUpdateService(index, { ...services[index], quantity: e.target.value * 1 })} />
                                                                </TableCell>
                                                                <TableCell align='left'>
                                                                    <TextField variant="standard" value={row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => handleUpdateService(index, { ...services[index], charge_amount: e.target.value * 1 })} />
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    <TextField variant="standard" value={row.insurance_charge_quantity.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} onChange={(e) => handleUpdateService(index, { ...services[index], insurance_charge_quantity: e.target.value * 1})} />
                                                                </TableCell>
                                                                <TableCell align='left'>
                                                                    <TextField variant="standard" value={row.out_of_pocket.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => handleUpdateService(index, { ...services[index], out_of_pocket: e.target.value * 1 })} />
                                                                </TableCell>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <TableCell align='center'>{row.quantity}</TableCell>
                                                                <TableCell align='right'>{!!row.charge_amount ? row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                                <TableCell align='center'>{row.insurance_charge_quantity}</TableCell>
                                                                <TableCell align='right'>{!!row.out_of_pocket ? row.out_of_pocket.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                            </React.Fragment>
                                                        }

                                                        <TableCell align='right'>{(chargeTotal + insTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                    </TableRow>
                                                )})
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {index === 2 && 
                                <TableContainer component={Paper} sx={{ mb: 4 }}>
                                    <Table
                                        size="small"
                                        aria-label="products table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="right">Charge Amount</TableCell>
                                                <TableCell align="right">Total</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                products.map((row, index) => (
                                                    <TableRow
                                                        key={'product_'+index}
                                                        hover
                                                        sx={{ bgcolor: '#fff' }}
                                                        onMouseEnter={()=>{setIsActive('product_'+index)}}
                                                        onMouseLeave={()=>{setIsActive('')}}
                                                    >
                                                        <TableCell align='left' sx={{ p: 0 }}>
                                                            <IconButton aria-label="delete-service" sx={{ color: '#d10e0e' }} onClick={() => handleDeleteProduct(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align='left'>{row.name}</TableCell>

                                                        {isActive === 'product_'+index ? 
                                                            <React.Fragment>
                                                                <TableCell align='center'>
                                                                    <TextField autoFocus={true} variant="standard" value={row.quantity.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} onChange={(e) => handleUpdateProduct(index, { ...products[index], quantity: e.target.value * 1 })} />
                                                                </TableCell>
                                                                <TableCell align='left'>
                                                                    <TextField variant="standard" value={row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => handleUpdateProduct(index, { ...products[index], charge_amount: e.target.value * 1 })} />
                                                                </TableCell>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <TableCell align='center'>{row.quantity}</TableCell>
                                                                <TableCell align='right'>{!!row.charge_amount ? row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                            </React.Fragment>
                                                        }

                                                        <TableCell align='right'>{!!row.charge_amount ? (row.quantity * row.charge_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {index === 3 && 
                                <TableContainer component={Paper} sx={{ mb: 4 }}>
                                    <Table
                                        size="small"
                                        aria-label="discounts table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="center">Description</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                discounts.map((row, index) => (
                                                    <TableRow
                                                        key={'discount_'+index}
                                                        hover
                                                        sx={{ bgcolor: '#fff' }}
                                                        onMouseEnter={()=>{setIsActive('discount_'+index)}}
                                                        onMouseLeave={()=>{setIsActive('')}}
                                                    >
                                                        <TableCell align='left'>
                                                            <IconButton aria-label="delete-discount" sx={{ color: '#d10e0e' }} onClick={() => handleDeleteDiscount(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        {isActive === 'discount_'+index ? 
                                                            <React.Fragment>
                                                                <TableCell align='left'>
                                                                    <TextField fullWidth autoFocus={true} variant="standard" value={row.description} onChange={(e) => handleUpdateDiscount(index, { description: e.target.value, amount: discounts[index].amount })} />
                                                                </TableCell>
                                                                <TableCell align='right'>
                                                                    <TextField autoFocus={true} variant="standard" value={row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => handleUpdateDiscount(index, { description: discounts[index].description, amount: e.target.value })} />
                                                                </TableCell>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <TableCell align='left'>{row.description}</TableCell>
                                                                <TableCell align='right'>{row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                            </React.Fragment>
                                                        }
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Grid>
                        <Grid xs={4}>
                            {index === 0 && refreshCal === true && 
                                <Calendar
                                    style={'background'}
                                    dataSource={appointments}
                                    onDayEnter={handleMouseEnterEvent}
                                    startYear={2024}
                                />
                            }
                            {index === 0 && refreshCal === false && 
                                <Calendar
                                    style={'background'}
                                    dataSource={appointments}
                                    onDayEnter={handleMouseEnterEvent}
                                />
                            }
                            {index === 1 && 
                                <React.Fragment>
                                    <Toolbar sx={{ height: 56, mb: 1 }}
                                    />
                                    <AddServiceTable addService={handleAddService} />
                                </React.Fragment>
                            }
                            {index === 2 && 
                                <React.Fragment>
                                    <Toolbar sx={{ height: 56, mb: 1 }}
                                    />
                                    <AddProductTable addProduct={handleAddProduct} />
                                </React.Fragment>
                            }
                            {index === 3 && 
                                <React.Fragment>
                                    <Toolbar sx={{ height: 56, mb: 1 }}
                                    />
                                    <Button fullWidth variant='outlined' onClick={handleAddDiscount}>Add Discount</Button>
                                </React.Fragment>
                            }
                        </Grid>
                    </React.Fragment>
                }
                {showPrintPreview && 
                    <Grid xs={9} sx={{ overflow: 'hidden' }}>
                        <Toolbar
                            sx={{
                                mb: { xs: 0 },
                                pr: { xs: 0 },
                                pl: { xs: 0 },
                                bgcolor: '#fff',
                            }}
                        >
                            <Button variant='outlined' onClick={() => setShowPrintPreview(false)}>Back</Button>
                            <Button variant='outlined' onClick={handlePrint} sx={{ ml: 2 }}>Print</Button>
                        </Toolbar>
                        <Box sx={{ bgcolor: '#F0F0F0' }}>
                            <Stack ref={printRef}>
                                <Box sx={{ bgcolor: '#fff', m: 2, p: 4, pageBreakAfter: 'always'}}>
                                    <Typography gutterBottom variant="h5" sx={{ color: '#0270c0', fontWeight: 'bold' }}>
                                        Neuropathy Care Plan
                                    </Typography>
                                    <Typography gutterBottom variant="h7">
                                        Practice Member:
                                    </Typography>
                                    <Typography gutterBottom variant="h7">
                                        {!!clients && clients.length > 0 && ` ${clients[0].title}`}
                                    </Typography>
                                    <Grid container={true} spacing={6} justifyContent="space-evenly" sx={{ mt: 2 }}>
                                        <Grid xs={6}>
                                            <Box sx={{ p: 2, border: 1, height: 350 }}>
                                                <Typography variant="subtitle2" sx={{ mb: 2, color: '#0270c0', fontWeight: 'bold' }}>
                                                    IN OFFICE CARE
                                                </Typography>
                                                {appointments.length > 0 && 
                                                    Array.from(appointmentMap.values()).map(apt => (
                                                        <React.Fragment>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{`${apt.total}x ${apt.name}`}</Typography>
                                                            {apt.enable_recommendations && 
                                                                recommendations.map(r => {
                                                                    let frequency = 0;
                
                                                                    if (r.su) {
                                                                        frequency++;
                                                                    } 
                                                                    
                                                                    if (r.m) {
                                                                        frequency++;
                                                                    }
                                                    
                                                                    if (r.t) {
                                                                        frequency++;
                                                                    } 
                                                                    
                                                                    if (r.w) {
                                                                        frequency++;
                                                                    } 
                                                                    
                                                                    if (r.th) {
                                                                        frequency++;
                                                                    } 
                                                                    
                                                                    if (r.f) {
                                                                        frequency++;
                                                                    } 
                                                                    
                                                                    if (r.sa) {
                                                                        frequency++;
                                                                    }
                
                                                                    return (
                                                                        <Typography variant="subtitle1" sx={{ ml: 4 }}>{`${frequency}x week / ${r.weeks} weeks`}</Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                                {/* {
                                                    services.map(s => (
                                                        <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 'bold'}}>{`${s.quantity}x ${s.name}`}</Typography>
                                                    ))
                                                } */}
                                            </Box>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Box sx={{ p: 2, border: 1, height: 350 }}>
                                                <Typography variant="subtitle2" sx={{ mb: 2, color: '#0270c0', fontWeight: 'bold' }}>
                                                    AT HOME EQUIPMENT/PRODUCTS
                                                </Typography>
                                                {
                                                    products.filter(p => p.is_supply_item === false).map(p => (
                                                        <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 'bold'}}>{`${p.quantity}x ${p.name}`}</Typography>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} spacing={4} justifyContent="space-evenly" sx={{ mt: 2 }}>
                                        <Grid xs={4}>
                                            <Box sx={{ border: 1 }}>
                                                <Typography variant="body1" sx={{ p:2, color: '#0270c0', borderBottom: 1, borderColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                                    PAY IN FULL
                                                </Typography>
                                                <Box sx={{ pt: 4, pb: 4, pl: 2, pr: 2, bgcolor: '#c9daf9', height: 120 }}>
                                                    <Typography variant="body1" sx={{ bgcolor: '#fff', textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>
                                                    {servicePricingArray.length >= 2 ? `$${servicePricingArray[0].total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={4}>
                                            <Box sx={{ border: 1 }}>
                                                <Typography variant="body1" sx={{ p:2, color: '#0270c0', borderBottom: 1, borderColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
                                                    PAY OVER TIME
                                                </Typography>
                                                <Box sx={{ pt: 4, pb: 2, pl: 2, pr: 2, bgcolor: '#c9daf9', height: 120 }}>
                                                    <Typography variant="body1" sx={{ bgcolor: '#fff', textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>
                                                    {servicePricingArray.length >= 2 ? `$${servicePricingArray[1].total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', fontWeight: 'bold' }}>
                                                        {servicePricingArray.length >= 2 ? `$${(servicePricingArray[1].total / 60).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} / month` : ''}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 4, fontSize: 12 }}>
                                        I understand that this is a corrective care program and I am paying for care (equipment & services rendered) and not for results. To give myself the best chance at relief of symptoms and correction of cause, I agree to make my scheduled appointments and will make up any missed visits within 24 hours when possible. I also understand and agree that nutritional supplements, equipment, and professional protocols that I receive are non-refundable for any reason. Once equipment is ordered and/or delivered, I understand it is in my possession. I understand that to get full results with my condition that recommendations must be followed. I understand my policy coverage has been reviewed, and payment options have been discussed. In the event that the care plan is not completed, I undestand that I am responsible for services already rendered and any credit or balance due will be determined once all explanation of benefits have been received, and will be reviewed with me in person after a 24 hour notice has been given.
                                    </Typography>
                                    <Grid container={true} spacing={2} sx={{ mt: 2 }}>
                                        <Grid xs={4}>
                                            <Box sx={{ borderTop: 1 }}>
                                                <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                    Signature
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={4}>
                                            <Box sx={{ borderTop: 1 }}>
                                                <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                    Date
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={4}>
                                            <Box sx={{ borderTop: 1 }}>
                                                <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                    Team Member Signature
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ bgcolor: '#fff', m: 2, p: 4, pageBreakAfter: 'always'}}>
                                    <Box sx={{ border: 1, ml: 4, mr: 4, p: 2 }}>
                                        <Typography gutterBottom variant="h5" sx={{ color: '#0270c0', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
                                            Cost Breakdown
                                        </Typography>
                                        <TableContainer sx={{ mb: 4 }}>
                                            <Table
                                                size="small"
                                                aria-label="services table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left"></TableCell>
                                                        {
                                                            servicePricingArray.map(sp => (
                                                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>{sp.name}</TableCell>
                                                            ))
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        services.filter(s => s.pricings.length >= 2).map((row, index) => {
                                                            return (
                                                            <TableRow
                                                                key={'cost_service_'+index}
                                                                sx={{ bgcolor: '#fff' }}
                                                            >
                                                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>{row.quantity > 1 ? `${row.quantity}x - ${row.name}` : row.name}</TableCell>
                                                                <TableCell align='left' sx={{ fontSize: 14 }}>{`$${(((row.quantity - row.insurance_charge_quantity) * row.pricings[0].amount) + (Math.min(row.insurance_charge_quantity, row.quantity) * row.out_of_pocket)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${row.insurance_charge_quantity > 0 ? ` = (${row.quantity - row.insurance_charge_quantity}x @ ${row.pricings[0].amount} + ${Math.min(row.insurance_charge_quantity, row.quantity)} INS @ ${row.out_of_pocket})` : ''}`}</TableCell>
                                                                <TableCell align='left' sx={{ fontSize: 14 }}>{`$${(((row.quantity - row.insurance_charge_quantity) * row.pricings[1].amount) + (Math.min(row.insurance_charge_quantity, row.quantity) * row.out_of_pocket)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${row.insurance_charge_quantity > 0 ? ` = (${row.quantity - row.insurance_charge_quantity}x @ ${row.pricings[1].amount} + ${Math.min(row.insurance_charge_quantity, row.quantity)} INS @ ${row.out_of_pocket})` : ''}`}</TableCell>
                                                            </TableRow>
                                                        )})
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box sx={{ border: 1, ml: 4, mr: 4, mt: 8, p: 2 }}>
                                        <Typography gutterBottom variant="h5" sx={{ color: '#0270c0', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
                                            Supply LIst
                                        </Typography>
                                        <Box sx={{ p: 8 }}>
                                            {
                                                products.filter(p => p.is_supply_item === true).map(p => (
                                                    <Typography gutterBottom variant="body1" sx={{ fontSize: 14, fontWeight: 'bold'}}>{`________ ${p.quantity}x ${p.name}`}</Typography>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 8, fontSize: 12, textAlign: 'center' }}>
                                        I acknowledge that I have received the pieces of equipment listed above.
                                    </Typography>
                                    <Grid container={true} spacing={2} sx={{ mt: 8 }} justifyContent="space-evenly">
                                        <Grid xs={4}>
                                            <Box sx={{ borderTop: 1 }}>
                                                <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                    Patient Initials
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={4}>
                                            <Box sx={{ borderTop: 1 }}>
                                                <Typography variant="body1" sx={{ fontSize: 12 }}>
                                                    Team Member Initials
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ bgcolor: '#fff', m: 2, p: 4, pageBreakAfter: 'always'}}>
                                    <Box sx={{ ml: 4, mr: 4, p: 2 }}>
                                        <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
                                            NON-REFUNDABLE SUPPLIES FORM
                                        </Typography>
                                    </Box>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 8, fontWeight: 'bold', fontSize: 12 }}>
                                        __________ I understand that once I sign this agreement the Infrared Low Level Light device is my possession and therefore non-refundable.
                                    </Typography>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 4, fontWeight: 'bold', fontSize: 12 }}>
                                        __________ I understand that once I sign this agreement that the Rebuilder Medical device is my possession and therefore non-refundable.
                                    </Typography>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 4, fontWeight: 'bold', fontSize: 12 }}>
                                        __________ I understand that once I sign this agreement that the Supplements are my possession and are therefore non-refundable.
                                    </Typography>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 4, fontWeight: 'bold', fontSize: 12 }}>
                                        __________ I understand that once I sign this agreement the Nutritional Education Program through MyBodySite (Patient Portal) that what I have been given access to is the intellectual property of Vero and is therefore non-refundable.
                                    </Typography>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 4, fontWeight: 'bold', fontSize: 12 }}>
                                        __________ By selecting option 2 (if applicable), you are agreeing to make the monthly payments to the financial institution that you accepted for. If at any time you would like to pay the loan off, you will be responsible to pay the company directly.
                                    </Typography>
                                    <Typography gutterBottom variant="body1" sx={{ mt: 8, fontWeight: 'bold', fontSize: 12 }}>
                                        By signing this form, I consent to the above information and understand that the terms are non-negotiable. Any questions regarding my equipment shall be submitted through MyBodySite. Any other questions or concerns that are not able to be answered through the website need to be addressed at a scheduled time, or by phone or email.
                                    </Typography>
                                    <Grid container={true} spacing={2} sx={{ mt: 8 }}>
                                        <Grid xs={6}>
                                            <Box>
                                                <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    Patient Signature ______________________
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Box>
                                                <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    Date ______________________
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} spacing={2}>
                                        <Grid xs={6}>
                                            <Box>
                                                <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    Team Member Signature ________________
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Box>
                                                <Typography variant="body1" sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                    Date ______________________
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                }
            </Grid>
        </DialogContent>
        <DialogActions>
            {/* <Typography variant="h5" sx={{ ml: 1, width: 450 }}>
                {`Total: $${(servicesCostTotal - insuranceSavingsTotal - discountsTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            </Typography>
            <Typography variant="h5" sx={{ ml: 2, width: 450  }}>
                {`Savings: $${(insuranceSavingsTotal + discountsTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
            </Typography> */}
            <Button sx={{ width: 250 }} onClick={() => setShowPrintPreview(!showPrintPreview)}>
                Print Preview
            </Button>
            <Typography variant="body1" sx={{ flex: '1 1 100%' }}></Typography>
            <Button autoFocus sx={{ width: 350 }} onClick={handleClose}>
                Create Care Plan
            </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}