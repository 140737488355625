import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

const GET_PRODUCT_TABLE = gql`
query GetProductsTable($orgId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  product(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}, name: {_ilike: $searchFilter}}, order_by: {name: asc}) {
    id
    name
    description
    charge_amount
    is_supply_item
  }
  product_aggregate {
    aggregate {
      count
    }
  }
}`;

export default function AddProductsTable(props) {
    const { hideHead = false, disable = false, addProduct } = props;
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [filter, setFilter] = React.useState('%%');

    const { data } = useQuery(GET_PRODUCT_TABLE, {
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
        fetchPolicy: "no-cache",
    });

    const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.product_aggregate.aggregate.count) : 0;

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                {hideHead === false && 
                <TableHead>
                    <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="right">Charge Amount</TableCell>
                        <TableCell align="center">Supply Item</TableCell>
                    </TableRow>
                </TableHead>
                }
                <TableBody>
                    {!!data && data.product_aggregate.aggregate.count > 0 && !!data.product && data.product.length > 0 && 
                    data.product.map((row, index) => {
                    const labelId = `product-table-${index}`;

                    return (
                        <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        >
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                                sx={{ p: 0 }}
                            >
                                <IconButton disabled={disable} onClick={() => addProduct(row)}>
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align='left'>{row.name}</TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="right">{row.charge_amount}</TableCell>
                            <TableCell align="center">{row.is_supply_item ? 'yes' : ''}</TableCell>
                        </TableRow>
                    )})
                    }
                    {emptyRows > 0 && (
                    <TableRow
                        style={{
                        height: (33) * emptyRows,
                        }}
                    >
                        <TableCell colSpan={6} />
                    </TableRow>
                    )}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data ? data.product_aggregate.aggregate.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
};