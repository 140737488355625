import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

const GET_ANALYTICS = gql`
query GetAnalyticsTable($orgId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  data_point(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}, name: {_ilike: $searchFilter}}, order_by: {created_at: desc}) {
    id
    name
    data
    function
  }
  appointment_type(where: {organization_id: {_eq: $orgId}}, order_by: {name: asc, created_at: asc}) {
    id
    name
    source_name
  }
}`;

const ADD_DATA_POINT = gql`mutation AddDataPoint($name: String!, $orgId: uuid!) {
  insert_data_point_one(object: {name: $name, organization_id: $orgId}) {
    created_at
  }
}`;

const UPDATE_DATA_POINT = gql`mutation UpdateDataPoint($name: String!, $function: String!, $data: String!, $id: uuid!) {
  update_data_point_by_pk(pk_columns: {id: $id}, _set: {name: $name, function: $function, data: $data}) {
    created_at
  }
}`;

function EditDataPoint(props) {
  const { dataPoint, appointmentTypes = [], refetchQueries, updateDataPoint } = props;
  let data = !!dataPoint && dataPoint.data ? JSON.parse(dataPoint.data) : {};

  const handleChange = (event) => {
    updateDataPoint({ variables: { ...dataPoint, function: event.target.value } });
  };

  const handleMultiSelect = (selections, currentAptTypes) => {
    let newString = '';

    if (!!currentAptTypes) {
      currentAptTypes.split(',').forEach(id => {
        if (id !== selections[selections.length - 1]) {
          newString = newString.concat(',' + id);
        }
      })

      if (newString !== '') {
        newString = newString.substring(1);
      }
    } else {
      return selections[0];
    }

    if (newString.length === currentAptTypes.length) {
      newString = newString.concat(',' + selections[selections.length - 1]);
    }

    return newString;
  }

  return (
      <React.Fragment>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Function</InputLabel>
          <Select
            value={dataPoint.function}
            label="Function"
            onChange={handleChange}
          >
            <MenuItem value='total'>Total</MenuItem>
            <MenuItem value='status'>Appointment Status</MenuItem>
            <MenuItem value='close_total'>Close Total</MenuItem>
            <MenuItem value='close_rate'>Close Rate</MenuItem>
            <MenuItem value='avg_time'>Average Time Between</MenuItem>
          </Select>
        </FormControl>
        {!!dataPoint.function && 
          <React.Fragment>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Start Appointment Type</InputLabel>
              <Select
                multiple
                value={!!data.start_apt_type && data.start_apt_type > 0 ? data.start_apt_type.split(',') : []}
                label="Start Appointment Type"
                onChange={(e) => updateDataPoint({ variables: { ...dataPoint, data: JSON.stringify({ ...data, start_apt_type: handleMultiSelect(e.target.value, data.start_apt_type) }) } })}
              >
                {appointmentTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id} sx={{ bgcolor: !!data.start_apt_type && data.start_apt_type.split(',').some(typeId => typeId === type.id) ? '#e0e0e0' : ''}}>
                      {`${type.name} - ${type.source_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {dataPoint.function === 'status' && 
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={data.status ?? ''}
                  label="Function"
                  onChange={(e) => updateDataPoint({ variables: { ...dataPoint, data: JSON.stringify({ ...data, status: e.target.value }) } })}
                >
                  <MenuItem value='Scheduled'>Scheduled</MenuItem>
                  <MenuItem value='Arrived'>Arrived</MenuItem>
                  <MenuItem value='Canceled'>Canceled</MenuItem>
                </Select>
              </FormControl>
            }
            {['close_total', 'close_rate', 'avg_time'].some(str => str === dataPoint.function) && 
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>End Appointment Type</InputLabel>
                <Select
                  multiple
                  value={!!data.end_apt_type && data.end_apt_type > 0 ? data.end_apt_type.split(',') : []}
                  label="End Appointment Type"
                  onChange={(e) => updateDataPoint({ variables: { ...dataPoint, data: JSON.stringify({ ...data, end_apt_type: handleMultiSelect(e.target.value, data.end_apt_type) }) } })}
                >
                  {appointmentTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id} sx={{ bgcolor: !!data.end_apt_type && data.end_apt_type.split(',').some(typeId => typeId === type.id) ? '#e0e0e0' : ''}}>
                        {`${type.name} - ${type.source_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </React.Fragment>
        }
      </React.Fragment>
  );
};

const EnhancedTableToolbar = (props) => {
  const { setFilter, addDataPoint } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx={{ width: 200 }}
        >
          Data Points
        </Typography>
        <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
          <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addDataPoint({ variables: { name: '  New Data Point', orgId: Memory.orgId } })}>Add Data Point</Button>
          </Grid>
        </Grid>
        <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
      </React.Fragment>
    </Toolbar>
  );
};

export default function Analytics(props) {
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState('');
  const [isActive, setIsActive] = React.useState('');

  const { data } = useQuery(GET_ANALYTICS, {
    variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
  });

  const selectedDataPoint = !!data && !!data.data_point && selected != '' ? data.data_point.find(d => d.id === selected) : {};
  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.data_point.length) : 0;

  const refetchQueries = [
    {
        query: GET_ANALYTICS,
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
    }
  ];

  const [addDataPoint] = useMutation(ADD_DATA_POINT, {
    refetchQueries: refetchQueries
  });

  const [updateDataPoint] = useMutation(UPDATE_DATA_POINT, {
    refetchQueries: [
      {
          query: GET_ANALYTICS,
          variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid xs={6}>
          <EnhancedTableToolbar setFilter={setFilter} addDataPoint={addDataPoint} />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!data && !!data.data_point && data.data_point.length > 0 && 
                    data.data_point.map((row, index) => {
                      const labelId = `analytics-table-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          selected={selected === row.id}
                          onMouseEnter={()=>{setIsActive(row.id)}}
                          onMouseLeave={()=>{setIsActive('')}}
                          onClick={() => setSelected(row.id)}
                        >
                          {isActive === row.id ? 
                            <React.Fragment>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    align='left'
                                >
                                    <TextField fullWidth variant="standard" value={row.name} onChange={(e) => updateDataPoint({ variables: { ...row, name: e.target.value } })}/>
                                </TableCell>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                              >
                                {row.name}
                              </TableCell>
                            </React.Fragment>
                          }
                        </TableRow>
                      )})
                    }
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (33) * emptyRows,
                        }}
                      >
                        <TableCell />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data ? data.data_point.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </Grid>
        <Grid xs={6}>
          <Toolbar
            sx={{
              mb: { xs: 2 },
              pr: { xs: 0 },
              pl: { xs: 0 },
            }}
          >
          </Toolbar>
          {!!selectedDataPoint && !!selectedDataPoint.id && 
            <Box sx={{ mb: 4 }}>
              <EditDataPoint dataPoint={selectedDataPoint} appointmentTypes={data.appointment_type} updateDataPoint={updateDataPoint} />
            </Box>
          }
        </Grid>
      </Grid>
    </Box>
  );
}