import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const GET_TEAM = gql`
query GetTeamMembersList($userId: uuid!) {
    user(where: {organization: {users: {id: {_eq: $userId}}}}, order_by: {last_name: asc}) {
        id
        first_name
        last_name
    }
}`;

export default function TeamMemberInput(props) {
    const { setUserId, userId = '', label = 'Team Member' } = props;
    const [value, setValue] = React.useState(userId);

    const handleChange = (event) => {
        setValue(event.target.value);
        setUserId(event.target.value);
    };

    const { data } = useQuery(GET_TEAM, {
        variables: { userId: Memory.id },
    });

    return (
        <TextField
          id="user-input"
          select
          fullWidth
          label={label}
          value={value}
          onChange={handleChange}
        >
            {!!data && data.user.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                    {`${user.first_name} ${user.last_name}`}
                </MenuItem>
            ))}
            <MenuItem key='none' value='' />
        </TextField>
    );
}