import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const UPDATE_ROOM_STATUS = gql`mutation UpdateRoomtatus($roomId: uuid!, $status: String) {
    update_resource_by_pk(pk_columns: {id: $roomId}, _set: {status: $status}) {
      created_at
    }
}`;

export default function WaitingRoomResourceTable(props) {
    const { hideDetails = false, data, refetchQueries } = props

    const [updateRoomStatus] = useMutation(UPDATE_ROOM_STATUS, {
        refetchQueries: refetchQueries
    });

    return (
        <TableContainer component={Paper}>
            <Table
                size="large"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        {hideDetails ?
                            <React.Fragment>
                                <TableCell align="left" sx={{ width: 50 }} />
                                <TableCell align="left">Room</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="right"></TableCell>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <TableCell align="left" sx={{ width: 50 }} />
                                <TableCell align="left">Room</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="right">Time Elapsed</TableCell>
                            </React.Fragment>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!data && 
                        data.map((room) => (
                            <React.Fragment>
                                <TableRow
                                    key={room.id}
                                    hover
                                    sx={{ bgcolor: '#fff' }}
                                >
                                    {hideDetails ?
                                        <React.Fragment>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align='left'>{`${room.name}`}</TableCell>
                                            <TableCell align='left'>{!!room.resource_queues && !!room.resource_queues[0] && `${room.resource_queues[0].client.first_name} ${room.resource_queues[0].client.last_name}`}</TableCell>
                                            <TableCell align='right'></TableCell>
                                        </React.Fragment>
                                    :
                                        <React.Fragment>
                                            <TableCell align="left">
                                                {room.status === 'Locked' ?
                                                    <IconButton aria-label="lock-room" sx={{ color: '#d10e0e' }} onClick={() => updateRoomStatus({ variables: { roomId: room.id, status: null }})}>
                                                        <LockedIcon />
                                                    </IconButton>
                                                    :
                                                    <IconButton aria-label="lock-room" onClick={() => updateRoomStatus({ variables: { roomId: room.id, status: 'Locked' }})}>
                                                        <LockOpenIcon />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                            <TableCell align='left'>{`${room.name}`}</TableCell>
                                            <TableCell align='left'>{!!room.resource_queues && !!room.resource_queues[0] && (!!room.resource_queues[0].client ? `${room.resource_queues[0].client.first_name} ${room.resource_queues[0].client.last_name}` : `${room.resource_queues[0].first_name} ${room.resource_queues[0].last_name}`)}</TableCell>
                                            <TableCell align='right'>{!!room.resource_queues && !!room.resource_queues[0] && `${Math.floor(((new Date() - new Date(room.resource_queues[0].called_time))/1000)/60)} min`}</TableCell>
                                        </React.Fragment>
                                    }
                                </TableRow>
                                {!!room.resource_queues && room.resource_queues.length > 1 && 
                                    room.resource_queues.slice(1).map((rq, index) => (
                                        <TableRow
                                            key={rq.id + index}
                                            hover
                                            sx={{ bgcolor: '#fff' }}
                                        >
                                            <TableCell />
                                            <TableCell />
                                            <TableCell align='left'>{!!rq.client ? `${rq.client.first_name} ${rq.client.last_name}`: `${rq.first_name} ${rq.last_name}`}</TableCell>
                                            <TableCell align='right'>{hideDetails ? '' : `${Math.floor(((new Date() - new Date(rq.called_time))/1000)/60)} min`}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}