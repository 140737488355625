import React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

// #138954
const styles = theme => ({
  main: {
    backgroundColor: '#54c6a4',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#34ab48',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#34ab48',
    color: '#fff',
  },
  link: {
    textDecoration: 'none',
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      redirect: false,
      message: !!this.props.location && !!this.props.location.state && !!this.props.location.state.message ? this.props.location.state.message : null,
      input: {
        email: '',
        password: '',
        confirm: '',
        organization: '',
      }
    }

    this.handleSnackClose = this.handleSnackClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this)
    this.emailIsValid = this.emailIsValid.bind(this);
    this.passwordIsValid = this.passwordIsValid.bind(this);
    this.confirmIsValid = this.confirmIsValid.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  async signUp() {
    await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/signup`, {
      email: this.state.input.email,
      password: this.state.input.password,
      organization: this.state.input.organization,
    });

    this.setState({
      redirect: true,
      input: {
        email: '', 
        password: '',
        confirm: '',
        organization: '',
      }
    })
  }

  handleSnackClose() {
    this.setState({
      redirect: false,
      message: null,
      input: this.state.input,
    })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      input: {
        ...this.state.input,
        [name]: value,
      },
    });
  };

  emailIsValid() {
    let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return !!this.state.input.email && this.state.input.email.match(mailformat);
  }

  passwordIsValid() {
    return !!this.state.input.password && this.state.input.password.length >= 8;
  }

  confirmIsValid() {
    return !!this.state.input.password && !!this.state.input.confirm && this.state.input.password === this.state.input.confirm;
  }

  render() {
    const isValid = this.emailIsValid() && this.passwordIsValid() && this.confirmIsValid();

    if (this.state.redirect) {
      return <Navigate exact to="/access_code" />;
    }

    return (
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100vw',
            height: '100vh',
            bgcolor: '#2C3E50',
          }}
        >
          <Grid item='true' xs={10} sm={6} md={3}>
            <Paper sx={{p:3}}>
              <Fade in timeout={400}>
              <Box>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  key="email"
                  name="email"
                  type="email"
                  label="Email"
                  onChange={this.handleInputChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="organization"
                  key="organization"
                  name="organization"
                  label="Organization Name"
                  onChange={this.handleInputChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  key="password"
                  name="password"
                  label="New Password"
                  type="password"
                  onChange={this.handleInputChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirm"
                  key="confirm"
                  name="confirm"
                  label="Confirm Password"
                  type="password"
                  onChange={this.handleInputChange}
                  InputLabelProps={{ shrink: true }}
                />
                <Button
                  disabled={!isValid}
                  type="submit"
                  fullWidth
                  variant="contained"
                
                  onClick={this.signUp}
                >
                  Sign Up
                </Button>
                <Grid container>
                  <Grid item='true' xs>
                    <Link to="/login">
                    <Button>
                      Login
                    </Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/reset_password">
                    <Button>
                      Reset Passowrd
                    </Button>
                    </Link>
                  </Grid>
                </Grid>
                </Box>
                </Fade>
            </Paper>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!this.state.message}
            onClose={this.handleSnackClose}
            message={this.state.message}
          />
        </Grid>
      </Box>
    );
  }
}

export default SignUp;