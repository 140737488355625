import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';

const GET_PRODUCTS = gql`
query GetProductsTable($orgId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  product(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}, name: {_ilike: $searchFilter}}, order_by: {created_at: desc}) {
    id
    name
    description
    charge_amount
    is_supply_item
  }
  product_aggregate {
    aggregate {
      count
    }
  }
}`;

const ADD_PRODUCT = gql`mutation AddProduct($name: String!, $description: String!, $charge_amount: float8!, $orgId: uuid!) {
    insert_product_one(object: {name: $name, description: $description, charge_amount: $charge_amount, organization_id: $orgId}) {
      created_at
    }
}`;

const UPDATE_PRODUCT = gql`mutation UpdateProduct($name: String!, $description: String!, $charge_amount: float8, $is_supply_item: Boolean!, $productId: uuid!) {
    update_product_by_pk(pk_columns: {id: $productId}, _set: {name: $name, description: $description, charge_amount: $charge_amount, is_supply_item: $is_supply_item}) {
      created_at
    }
}`;

const EnhancedTableToolbar = (props) => {
  const { setFilter, addProduct } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products
        </Typography>
        <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
          <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addProduct({ variables: { name: ' ', description: 'New Product', charge_amount: 0, orgId: Memory.orgId } })}>Add Product</Button>
          </Grid>
        </Grid>
        <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
      </React.Fragment>
    </Toolbar>
  );
};

export default function Products(props) {
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState('');
  const [isActive, setIsActive] = React.useState('');

  const { data } = useQuery(GET_PRODUCTS, {
    variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
  });

  const [addProduct] = useMutation(ADD_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });
  

  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.product_aggregate.aggregate.count) : 0;

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleClick = (event, id) => {
    setSelected(id);
  };

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <EnhancedTableToolbar setFilter={setFilter} addProduct={addProduct} />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="right">Charge Amount</TableCell>
                    <TableCell align="center">Supply Item</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {!!data && data.product_aggregate.aggregate.count > 0 && !!data.product && data.product.length > 0 && 
                data.product.map((row, index) => {
                    const labelId = `product-table-${index}`;

                    return (
                    <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        selected={selected === row.id}
                        onMouseEnter={()=>{setIsActive(row.id)}}
                        onMouseLeave={()=>{setIsActive('')}}
                        onClick={(event) => handleClick(event, row.id)}
                    >
                        {isActive === row.id ? 
                        <React.Fragment>
                            <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                            >
                                <TextField fullWidth autoFocus={true} variant="standard" value={row.name} onChange={(e) => {updateProduct({ variables: { name: e.target.value, description: row.description, charge_amount: row.charge_amount, is_supply_item: row.is_supply_item, productId: row.id } })}} />
                            </TableCell>
                            <TableCell align="left">
                                <TextField fullWidth autoFocus={true} variant="standard" value={row.description} onChange={(e) => {updateProduct({ variables: { name: row.name, description: e.target.value, charge_amount: row.charge_amount, is_supply_item: row.is_supply_item, productId: row.id } })}} />
                            </TableCell>
                            <TableCell align="right">
                                <TextField fullWidth autoFocus={true} variant="standard" value={row.charge_amount} onChange={(e) => {updateProduct({ variables: { name: row.name, description: row.description, charge_amount: e.target.value, is_supply_item: row.is_supply_item, productId: row.id } })}} />    
                            </TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            >
                                {row.name}
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="right">{row.charge_amount}</TableCell>
                        </React.Fragment>
                        }
                        <TableCell align="center">
                          <Checkbox checked={row.is_supply_item} onClick={(e) => updateProduct({ variables: { name: row.name, description: row.description, charge_amount: row.charge_amount, is_supply_item: e.target.checked, productId: row.id } })}/>
                        </TableCell>
                    </TableRow>
                    )})
                }
                {emptyRows > 0 && (
                    <TableRow
                    style={{
                        height: (33) * emptyRows,
                    }}
                    >
                    <TableCell colSpan={6} />
                    </TableRow>
                )}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[30, 60, 90]}
            component="div"
            count={!!data ? data.product_aggregate.aggregate.count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </Box>
  );
}