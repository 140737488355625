import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const GET_CLIENTS = gql`
query GetClientsList($userId: uuid!, $searchFilter: String!) {
  clients(limit: 20, where: {organization: {users: {id: {_eq: $userId}}}, _or: [{first_name: {_ilike: $searchFilter}}, {last_name: {_ilike: $searchFilter}}]}, order_by: {last_name: asc}) {
    id
    source_id
    first_name
    last_name
    email
    phone
    note
    medical_note
  }
}`;

const GET_CLIENTS_WITH_SPACES = gql`
query GetClientsListWithSpaces($userId: uuid!, $firstFilter: String!, $lastFilter: String!) {
  clients(limit: 20, where: {organization: {users: {id: {_eq: $userId}}}, _or: [{first_name: {_ilike: $firstFilter}, last_name: {_ilike: $lastFilter}}, {first_name: {_ilike: $lastFilter}, last_name: {_ilike: $firstFilter}}]}, order_by: {last_name: asc}) {
    id
    source_id
    first_name
    last_name
    email
    phone
    note
    medical_note
  }
}`;

const getOptions = (data) => {
    let options = [];

    if (!!data && data.clients.length > 0) {
        data.clients.forEach(client => {
            if (!!client.first_name && !!client.last_name) {
                options.push({
                    title: `${client.first_name} ${client.last_name}`,
                    id: client.id,
                    value: client.id,
                    key: client.id,
                    source_id: client.source_id,
                    first_name: client.first_name,
                    last_name: client.last_name,
                    email: client.email,
                    phone: client.phone,
                    note: client.note,
                    medical_note: client.medical_note,
                    client_ehr_notes: client.client_ehr_notes,
                })
            }
        });
    }

    return options;
}

export default function ClientsInput(props) {
    const { disabled = false, multiple = false, initialValue = multiple ? [] : null, setClient } = props;
    const [value, setValue] = React.useState(initialValue);
    const [clientsOpen, setClientsOpen] = React.useState(false);
    const [clientsFilter, setClientsFilter] = React.useState('%%');
    const [options, setOptions] = React.useState([]);
    const splitFilter = clientsFilter.split(' ');

    let { data, loading } = useQuery(GET_CLIENTS, {
        variables: { userId: Memory.id, searchFilter: clientsFilter },
        fetchPolicy: "no-cache",
    });
    
    const { data: data2, loading: loading2 } = useQuery(GET_CLIENTS_WITH_SPACES, {
        variables: { userId: Memory.id, firstFilter: `${splitFilter[0]}%`, lastFilter: `%${splitFilter[1]}` },
        fetchPolicy: "no-cache",
    });

    if (splitFilter.length > 1) {
        data = data2;
        loading = loading2;
    }

    React.useEffect(() => {
        let active = true;

        if (!data) {
            return undefined;
        }
    
        if (clientsFilter === '%%') {
            setOptions(value ? [value] : []);
            return undefined;
        }
    
        if (!!data && data.clients.length > 0) {
            let newOptions = [];
    
            newOptions = getOptions(data);
    
            setOptions(newOptions);
        }
    
        return () => {
          active = false;
        };
    }, [data, clientsFilter]);

    return (
        <Autocomplete
            id="client-input"
            sx={{ width: '100%' }}
            disabled={disabled}
            multiple={multiple}
            open={clientsOpen}
            filterOptions={(x) => x}
            onOpen={() => {
                setClientsOpen(true);
            }}
            onClose={() => {
                setClientsOpen(false);
            }}
            isOptionEqualToValue={(option, value) => {
                return true;
            }}
            getOptionLabel={(option) => option.title || ""}
            options={options}
            loading={loading}
            value={value}
            onChange={(event, newValue, reason) => {
                setClient(newValue);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue, reason) => {
                setClientsFilter(`%${newInputValue}%`);
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                label="Client"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                    ),
                }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <ListItem key={option.value} disablePadding>
                        <ListItemButton onClick={() => {
                            if (multiple) {
                                setValue([...value, option]);
                                setClient([...value, option]);
                            } else {
                                setValue(option);
                                setClient(option);
                            }
                        }}>
                            <ListItemText primary={option.title} />
                        </ListItemButton>
                    </ListItem>
                );
            }}
        />
    );
}