import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function IntegrationAuthInput(props) {
    const { label, setAuthValue, authValue = '' } = props;
    const [value, setValue] = React.useState(authValue);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
        setAuthValue(event.target.value);
    };

    return (
        <TextField
            label={label}
            type={showPassword ? "text" : "password"}
            value={value ?? ''}
            variant="standard"
            onChange={handleChange}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
            }}
        />
    );
}