import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { alpha } from '@mui/material/styles';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Unstable_Grid2';

import AddProductTable from '../Widgets/AddProductTable';
import AddPricingTable from '../Widgets/AddPricingTable';

const GET_SERVICES = gql`
query GetServicesTable($orgId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  service(limit: $limit, offset: $offset, where: {organization_id: {_eq: $orgId}, name: {_ilike: $searchFilter}}, order_by: {created_at: desc}) {
    id
    name
    description
    charge_amount
    insurance_charge_amount
    insurance_charge_quantity
    enable_care_plan
    products(order_by: {created_at: desc}) {
      id
      quantity
      product {
        id
        name
        description
        charge_amount
        is_supply_item
      }
    }
    pricings(order_by: { pricing: {name: asc}}) {
      id
      amount
      pricing {
        id
        name
        status
      }
    }
  }
  service_aggregate {
    aggregate {
      count
    }
  }
}`;

const ADD_SERVICE = gql`mutation AddService($name: String!, $description: String!, $charge_amount: float8!, $orgId: uuid!) {
  insert_service_one(object: {name: $name, description: $description, charge_amount: $charge_amount, organization_id: $orgId}) {
    created_at
  }
}`;

const ADD_SERVICE_PRODUCT = gql`mutation AddServiceProduct($service_id: uuid!, $product_id: uuid!) {
  insert_service_product_one(object: {service_id: $service_id, product_id: $product_id}) {
    id
  }
}`;

const ADD_SERVICE_PRICE = gql`mutation AddServicePrice($service_id: uuid!, $pricing_id: uuid!) {
  insert_service_pricing_one(object: {service_id: $service_id, pricing_id: $pricing_id}) {
    id
  }
}`;

const UPDATE_SERVICE = gql`mutation UpdateService($name: String!, $description: String!, $charge_amount: float8!, $insurance_charge_amount: float8!, $insurance_charge_quantity: Int!, $enable_care_plan: Boolean!, $id: uuid!) {
  update_service_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description, charge_amount: $charge_amount, insurance_charge_amount: $insurance_charge_amount, insurance_charge_quantity: $insurance_charge_quantity, enable_care_plan: $enable_care_plan}) {
    created_at
  }
}`;

const UPDATE_SERVICE_PRODUCT = gql`mutation UpdateServiceProduct($id: uuid!, $quantity: Int!) {
  update_service_product_by_pk(pk_columns: {id: $id}, _set: {quantity: $quantity}) {
    created_at
  }
}`;

const UPDATE_SERVICE_PRICING = gql`mutation UpdateServicePricing($id: uuid!, $amount: float8!) {
  update_service_pricing_by_pk(pk_columns: {id: $id}, _set: {amount: $amount}) {
    created_at
  }
}`;

const DELETE_SERVICE_PRODUCT = gql`mutation DeleteServiceProduct($id: uuid!) {
  delete_service_product_by_pk(id: $id) {
    id
  }
}`;

const DELETE_SERVICE_PRICE = gql`mutation DeleteServicePrice($id: uuid!) {
  delete_service_pricing_by_pk(id: $id) {
    id
  }
}`;

function ServiceProductsTable(props) {
  const { products, refetchQueries, deleteProduct } = props;
  const [isActive, setIsActive] = React.useState('');

  const [updateService] = useMutation(UPDATE_SERVICE_PRODUCT, {
    refetchQueries: refetchQueries
  });

  return (
      <React.Fragment>
          <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="center" sx={{ width: 75 }}>Qty</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="right">Charge Amount</TableCell>
                      <TableCell align="center">Supply Item</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!products && products.map((row, index) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          onMouseEnter={()=>{setIsActive(row.id)}}
                          onMouseLeave={()=>{setIsActive('')}}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align='left'
                            sx={{ p: 0 }}
                          >
                            <IconButton onClick={() => deleteProduct(row.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          {isActive ? 
                            <TableCell align="left">
                              <TextField variant="standard" value={row.quantity} onChange={(e) => updateService({ variables: { id: row.id, quantity: e.target.value } })}/>
                            </TableCell>
                            :
                            <TableCell align="center">{row.quantity}</TableCell>
                          }
                          <TableCell align='left'>{row.product.name}</TableCell>
                          <TableCell align="left">{row.product.description}</TableCell>
                          <TableCell align="right">{row.product.charge_amount}</TableCell>
                          <TableCell align="center">{row.product.is_supply_item ? 'yes' : ''}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
              </Table>
          </TableContainer>
      </React.Fragment>
  );
};

function ServicePricingTable(props) {
  const { prices, refetchQueries, deletePrice } = props;
  const [isActive, setIsActive] = React.useState('');

  const [updateService] = useMutation(UPDATE_SERVICE_PRICING, {
    refetchQueries: refetchQueries
  });

  return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="left">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {!!prices && prices.map((row, index) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onMouseEnter={()=>{setIsActive(row.id)}}
                    onMouseLeave={()=>{setIsActive('')}}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align='left'
                      sx={{ p: 0 }}
                    >
                      <IconButton onClick={() => deletePrice(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='left'>{row.pricing.name}</TableCell>
                    {isActive ? 
                      <React.Fragment>
                        <TableCell align="left">
                          <TextField variant="standard" fullWidth value={row.amount} onChange={(e) => updateService({ variables: { id: row.id, name: row.name, amount: e.target.value } })}/>
                        </TableCell>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <TableCell align="left">{row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      </React.Fragment>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
  );
};

const EnhancedTableToolbar = (props) => {
  const { setFilter, addService } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Services
        </Typography>
        <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
          <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addService({ variables: { name: ' ', description: 'New Service', charge_amount: 0, orgId: Memory.orgId } })}>Add Service</Button>
          </Grid>
        </Grid>
        <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
      </React.Fragment>
    </Toolbar>
  );
};

export default function Services(props) {
  const [index, setIndex] = React.useState(0);
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState('');
  const [isActive, setIsActive] = React.useState('');

  const { data } = useQuery(GET_SERVICES, {
    variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
  });

  const selectedService = !!data && !!data.service && selected != '' ? data.service.find(s => s.id === selected) : {};
  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.service_aggregate.aggregate.count) : 0;

  const refetchQueries = [
    {
        query: GET_SERVICES,
        variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
    }
  ];

  const [addService] = useMutation(ADD_SERVICE, {
    refetchQueries: refetchQueries
  });

  const [addServiceProduct] = useMutation(ADD_SERVICE_PRODUCT, {
    refetchQueries: refetchQueries
  });

  const [addServicePrice] = useMutation(ADD_SERVICE_PRICE, {
    refetchQueries: refetchQueries
  });

  const [updateService] = useMutation(UPDATE_SERVICE, {
    refetchQueries: [
      {
          query: GET_SERVICES,
          variables: { orgId: Memory.orgId, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [deleteServiceProduct] = useMutation(DELETE_SERVICE_PRODUCT, {
    refetchQueries: refetchQueries
  });

  const [deleteServicePrice] = useMutation(DELETE_SERVICE_PRICE, {
    refetchQueries: refetchQueries
  });

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid xs={6}>
          <EnhancedTableToolbar setFilter={setFilter} addService={addService} />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="right">Charge Amount</TableCell>
                      <TableCell align="right">Insurance Charge Amount</TableCell>
                      <TableCell align="center">Insurance Charge Quantity</TableCell>
                      <TableCell align="center">Care Plan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!data && data.service_aggregate.aggregate.count > 0 && !!data.service && data.service.length > 0 && 
                    data.service.map((row, index) => {
                      const labelId = `service-table-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          selected={selected === row.id}
                          onMouseEnter={()=>{setIsActive(row.id)}}
                          onMouseLeave={()=>{setIsActive('')}}
                          onClick={() => setSelected(row.id)}
                        >
                          {isActive === row.id ? 
                            <React.Fragment>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    align='left'
                                >
                                    <TextField fullWidth variant="standard" value={row.name} onChange={(e) => updateService({ variables: { ...row, name: e.target.value } })}/>
                                </TableCell>
                                <TableCell align="left">
                                    <TextField fullWidth variant="standard" value={row.description} onChange={(e) => updateService({ variables: { ...row, description: e.target.value } })}/>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField fullWidth autoFocus={true} variant="standard" value={row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => updateService({ variables: { ...row, charge_amount: e.target.value } })}/>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField fullWidth variant="standard" value={row.insurance_charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} onChange={(e) => updateService({ variables: { ...row, insurance_charge_amount: e.target.value } })}/>
                                </TableCell>
                                <TableCell align="right">
                                    <TextField fullWidth variant="standard" value={row.insurance_charge_quantity} onChange={(e) => updateService({ variables: { ...row, insurance_charge_quantity: e.target.value } })}/>
                                </TableCell>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                align='left'
                              >
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{row.description}</TableCell>
                              <TableCell align="right">{row.charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                              <TableCell align="right">{row.insurance_charge_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                              <TableCell align="center">{row.insurance_charge_quantity}</TableCell>
                            </React.Fragment>
                          }
                          <TableCell align="center">
                            <Checkbox checked={row.enable_care_plan} onClick={(e) => updateService({ variables: { ...row, enable_care_plan: e.target.checked } })}/>
                          </TableCell>
                        </TableRow>
                      )})
                    }
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (33) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data ? data.service_aggregate.aggregate.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </Grid>
        <Grid xs={6}>
          <Toolbar
            sx={{
              mb: { xs: 2 },
              pr: { xs: 0 },
              pl: { xs: 0 },
            }}
          >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
            </Typography>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button variant={index === 0 ? 'contained' : 'outlined'} onClick={() => setIndex(0)}>Products</Button>
              <Button variant={index === 1 ? 'contained' : 'outlined'} onClick={() => setIndex(1)}>Pricing</Button>
            </ButtonGroup>
          </Toolbar>
          {index === 0 && !!selectedService && 
            <Box sx={{ mb: 4 }}>
              <ServiceProductsTable products={selectedService.products} refetchQueries={refetchQueries} deleteProduct={(id) => deleteServiceProduct({ variables: { id: id }})} />
            </Box>
          }
          {index === 0 && 
            <AddProductTable disable={selected === ''} addProduct={(row) => addServiceProduct({ variables: { service_id: selected, product_id: row.id }})} />
          }
          {index === 1 && !!selectedService && 
            <Box sx={{ mb: 4 }}>
              <ServicePricingTable prices={selectedService.pricings} refetchQueries={refetchQueries} deletePrice={(id) => deleteServicePrice({ variables: { id: id }})} />
            </Box>
          }
          {index === 1 && 
            <AddPricingTable disable={selected === ''} addPricing={(row) => addServicePrice({ variables: { service_id: selected, pricing_id: row.id }})} />
          }
        </Grid>
      </Grid>
    </Box>
  );
}