import * as React from 'react';
import tippy from 'tippy.js';
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ClientFormsTable from '../Widgets/ClientFormsTable';
import ClientImagesList from '../Widgets/ClientImagesList';
import ClientAppointmentsTable from '../Widgets/ClientAppointmentsTable';
import ClientTransactionsTable from '../Widgets/ClientTransactionsTable';

const GET_CLIENT_DETAILS = gql`
query GetClientDetails($id: uuid!) {
    clients_by_pk(id: $id) {
        id
        first_name
        last_name
        email
        phone
        status
        note
        medical_note
        client_forms(order_by: {created_at: asc}) {
          id
          status
          submitted_date
          organization_id
          integration_provider_id
          form {
            name
          }
          integration_provider {
            name
          }
          questions(where: {attachments: {}}) {
            attachments {
              id
              file_name
              source_id
              content_type
              url
            }
          }
        }
        client_forms_aggregate {
          aggregate {
            count
          }
        }
        appointments(order_by: {appointment: {start: desc}}) {
            appointment {
              id
              start
              status
              appointment_type {
                id
                name
              }
            }
        }
        appointments_aggregate {
            aggregate {
                count
            }
        }
        transactions(order_by: {date: desc}) {
            id
            date
            amount
            insurance_charge_amount
            insurance_approved_amount
            service {
              name
            }
        }
        transactions_aggregate {
            aggregate {
                count
            }
        }
    }
}`;

const GET_CLIENT_CALENDAR = gql`
query GetClientCalendar($id: uuid!, $userId: uuid!, $start: timestamptz!, $end: timestamptz!) {
    clients_by_pk(id: $id) {
        id
        first_name
        last_name
        email
        phone
        status
        appointments(where: {appointment: {organization: {users: {id: {_eq: $userId}}}, start: {_gte: $start}, end: {_lte: $end}}}, order_by: {appointment: {start: asc}}) {
            appointment {
                id
                start
                end
                appointment_type {
                    name
                    color
                }
                appointment_clients {
                    client {
                        id
                        first_name
                        last_name
                    }
                }
                appointment_resources {
                    resource {
                        id
                        name
                    }
                }
                appointment_users {
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        }
    }
}`;

const UPDATE_CLIENT_NOTE = gql`mutation UpdateRoomCurrentAppointment($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {note: $text}) {
      created_at
    }
}`;

const UPDATE_CLIENT_MEDICAL_NOTE = gql`mutation UpdateRoomCurrentAppointment($clientId: uuid!, $text: String!) {
    update_clients_by_pk(pk_columns: {id: $clientId}, _set: {medical_note: $text}) {
      created_at
    }
}`;

export default function ClientDetails() {
    const { id } = useParams();
    const calendarRef = React.useRef();
    const apolloClient = useApolloClient();
    const [openNewApt, setOpenNewApt] = React.useState(false);
    const [searchDate, setSearchDate] = React.useState(new Date());

    const { data } = useQuery(GET_CLIENT_DETAILS, {
        variables: { id: id, userId: Memory.id },
    });

    const [updateClientNote] = useMutation(UPDATE_CLIENT_NOTE, { refetchQueries: [
        {
            query: GET_CLIENT_DETAILS,
            variables: { id: id },
        }
    ]});

    const [updateClientMedicalNote] = useMutation(UPDATE_CLIENT_MEDICAL_NOTE, { refetchQueries: [
        {
            query: GET_CLIENT_DETAILS,
            variables: { id: id },
        }
    ]});

    const request = (start, end) => apolloClient.query({
        query: GET_CLIENT_CALENDAR,
        variables: { userId: Memory.id, id: id, start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const handleDateChange = (val) => {
        setSearchDate(val);
        calendarRef.current.getApi().gotoDate(val);
    };

    const handleClickNewApt = () => {
        setOpenNewApt(!openNewApt);
    };

    async function getCalendarData(fetchInfo, successCallback) {
        const startDate = new Date(fetchInfo.start);
        const endDate = new Date(fetchInfo.end);
        const response = await request(startDate, endDate);

        successCallback(
            response.data.clients_by_pk.appointments.map(appointment => {
                const apt = appointment.appointment;
                let resourceNames = [];
                let users = [];

                apt.appointment_resources.forEach(r => {
                    resourceNames.push(r.resource.name);
                });

                apt.appointment_users.forEach(u => {
                    users.push(`${u.user.first_name} ${u.user.last_name}`);
                });

                return {
                    id: apt.id,
                    title: `${apt.appointment_type.name} - ${apt.appointment_clients.length > 0 ? apt.appointment_clients[0].client.first_name + ' ' + apt.appointment_clients[0].client.last_name : ''}`,
                    start: apt.start,
                    end: apt.end,
                    allDay: false,
                    editable: false,
                    backgroundColor: apt.appointment_type.color != null ? apt.appointment_type.color : '#000',
                    extendedProps: {
                        id: apt.id,
                        start: apt.start,
                        end: apt.end,
                        resourcesTitle: resourceNames.join(' - '),
                        usersTitle: users.join(', '),
                    }
                };
            })
        );
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
            }}
        >
            <Toolbar
                sx={{
                    mb: { xs: 2 },
                    pr: { xs: 0 },
                    pl: { xs: 0 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h4"
                    id="tableTitle"
                    component="div"
                >
                    {!!data && !!data.clients_by_pk && `${data.clients_by_pk.first_name} ${data.clients_by_pk.last_name}`}
                </Typography>
                <Box
                    sx={{
                        width: '200px',
                    }}
                >
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Date"
                            inputFormat="MM/dd/yyy"
                            value={searchDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider> */}
                </Box>
            </Toolbar>
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid xs={6}>
                    <TableContainer component={Paper} sx={{ mt: 8, mb: 4 }}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">First Name</TableCell>
                                    <TableCell align="left">Last Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Phone</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!data && !!data.clients_by_pk && 
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={data.clients_by_pk.id}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {data.clients_by_pk.first_name}
                                        </TableCell>
                                        <TableCell align="left">{data.clients_by_pk.last_name}</TableCell>
                                        <TableCell align="left">{data.clients_by_pk.email}</TableCell>
                                        <TableCell align="left">{data.clients_by_pk.phone}</TableCell>
                                        <TableCell align="center">{data.clients_by_pk.status}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TextField
                        id="filled-multiline-static"
                        label="Office Notes"
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        color="warning"
                        focused
                        value={!!data && !!data.clients_by_pk ? data.clients_by_pk.note : ''}
                        onChange={(e) => updateClientNote({ variables: { clientId: id, text: e.target.value }})}
                        sx={{ mb: 4 }}
                    />
                    <TextField
                        id="filled-multiline1-static"
                        label="Patient Notes"
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                        color="secondary"
                        focused
                        value={!!data && !!data.clients_by_pk ? data.clients_by_pk.medical_note : ''}
                        onChange={(e) => updateClientMedicalNote({ variables: { clientId: id, text: e.target.value }})}
                    />
                    <ClientFormsTable data={data} />
                    <ClientImagesList data={data} />
                </Grid>
                <Grid xs={6}>
                    <ClientAppointmentsTable data={data} />
                    <ClientTransactionsTable data={data} />
                    {/* <FullCalendar
                        ref={calendarRef}
                        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                        headerToolbar={{
                            left:'title',
                            // center:'newApt',
                            right:'timeGridWeek,dayGridMonth today prev,next'
                        }}
                        rerenderDelay={10}
                        eventStartEditable={false}
                        eventDurationEditable={false}
                        editable={false}
                        droppable={false}
                        allDaySlot={true}
                        height='100%'
                        slotMinTime='05:00:00'
                        slotDuration='00:15:00'
                        eventMinHeight={20}
                        dayMinWidth={100}
                        slotEventOverlap={false}
                        stickyFooterScrollbar={true}
                        nowIndicator={true}
                        eventDisplay='-color'
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, scrollGridPlugin ]}
                        slotLabelContent={(args) => {}}
                        initialView="timeGridWeek"
                        eventOverlap={false}
                        eventSources={[
                            {
                                id: 'api123',
                                events: (fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)
                            }
                        ]}
                        views={{
                            day: {
                                titleFormat: {
                                    year: 'numeric', month: 'long', day: 'numeric', weekday: 'short'
                                },
                            },
                            week:{
                                dayMaxEvents: 20
                            }
                        }}
                        customButtons= {{
                            newApt: {
                                text: 'New Appointment',
                                click: handleClickNewApt,
                            },
                        }}
                    /> */}
                </Grid>
            </Grid>
        </Box>
    );
}