import * as React from 'react';
import tippy from 'tippy.js';
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient, useMutation, useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';

import NewAppointmentDrawer from '../Forms/NewAppointmentDrawer';

const GET_CALENDAR = gql`
query GetUserSchedule($userId: uuid!, $id: uuid!, $start: timestamptz!, $end: timestamptz!) {
    user_by_pk(id: $id) {
        id
        first_name
        last_name
        appointments(where: {appointment: {organization: {users: {id: {_eq: $userId}}}, start: {_gte: $start}, end: {_lte: $end}}}, order_by: {appointment: {start: asc}}) {
            appointment {
                id
                start
                end
                appointment_type {
                    name
                    color
                }
                appointment_clients {
                    client {
                        id
                        first_name
                        last_name
                    }
                }
                appointment_resources {
                    resource {
                        id
                        name
                    }
                }
                appointment_users {
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        }
        time_blocks {
          id
          label
          start
          end
        }
    }
}`;

const GET_USER_DETAILS = gql`
query GetUserDetails($id: uuid!) {
    user_by_pk(id: $id) {
        id
        first_name
        last_name
        organization_id
    }
}`;

const ADD_TIME_BLOCK = gql`mutation AddBlock($label: String!, $start: timestamptz!, $end: timestamptz!, $userId: uuid!, $orgId: uuid!) {
    insert_time_block_one(object: {label: $label, start: $start, end: $end, user_id: $userId, organization_id: $orgId}) {
        id
    }
}`;

const UPDATE_TIME_BLOCK = gql`mutation UpdateBlock($id: uuid!, $start: timestamptz!, $end: timestamptz!) {
    update_time_block_by_pk(pk_columns: {id: $id}, _set: {start: $start, end: $end}) {
        id
    }
}`;

const DELETE_TIME_BLOCK = gql`mutation DeleteBlock($id: uuid!) {
    delete_time_block_by_pk(id: $id) {
        id
    }
}`;

export default function UserSchedule() {
    const { id } = useParams();
    const calendarRef = React.useRef();
    const deleteCalendarRef = React.useRef();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const [open, setOpen] = React.useState(false);
    const [openEditEvent, setOpenEditEvent] = React.useState(false);
    const [event, setEvent] = React.useState(null);
    const [time, setTime] = React.useState(new Date());
    const [searchDate, setSearchDate] = React.useState(new Date());
    const [refresh, setRefresh] = React.useState(false);
    const [firstRender, setFirstRender] = React.useState(true);

    React.useEffect(() => {
        if (firstRender) {
            let draggableEl = document.getElementById("external-events");
            new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function(eventEl) {
                let title = eventEl.getAttribute("title");
                let eventId = eventEl.getAttribute("eventid");
                let bgColor = eventEl.getAttribute("bgcolor");
                return {
                    title: title,
                    id: eventId,
                    backgroundColor: bgColor,
                    eventOverlap: false,
                };
            }
            });
        }

        setFirstRender(false);
    }, [firstRender]);

    const { data } = useQuery(GET_USER_DETAILS, {
        variables: { id: id },
    });

    const [addTimeBlock] = useMutation(ADD_TIME_BLOCK);

    const [updateTimeBlock] = useMutation(UPDATE_TIME_BLOCK);

    const [deleteTimeBlock] = useMutation(DELETE_TIME_BLOCK);

    const handleDateChange = (val) => {
        setSearchDate(val);
        calendarRef.current.getApi().gotoDate(val);
    }
  
    const handleClickOpen = () => {
        setOpen(!open);
    };

    const handleClickAvailability = () => {
        navigate(`../schedule/availability`);
    };

    const request = (start, end) => apolloClient.query({
        query: GET_CALENDAR,
        variables: { userId: Memory.id, id: id, start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const handleMouseEnterEvent = (args) => {
        let contentStr = args.event.title;
        contentStr = !args.event.extendedProps.resourcesTitle ? contentStr : contentStr.concat('<br/>', args.event.extendedProps.resourcesTitle);
        contentStr = !args.event.extendedProps.usersTitle ? contentStr : contentStr.concat('<br/>', args.event.extendedProps.usersTitle);
        tippy(args.el, {
            allowHTML: true,
            content: contentStr,
            theme: 'calendar',
        });
    }

    async function handleEventReceive(args) {
        // console.log('receive event', args.event);
        let startDate = new Date(args.event.start);
        let endDate = new Date(startDate.getTime() + 3600000);

        if (!!data) {
            await addTimeBlock({ variables: {
                label: args.event.title,
                start: startDate.toISOString(),
                end: endDate.toISOString(),
                label: args.event.title,
                userId: id,
                orgId: data.user_by_pk.organization_id,
            }});
        }

        if (args.event.id === 'busy123' || args.event.id === 'avail123') {
            calendarRef.current.getApi().getEventById(args.event.id).remove();
        }
    }

    async function handleDeleteEvent(args) {
        deleteTimeBlock({ variables: { id: args.event.id } });
        deleteCalendarRef.current.getApi().getEventById(args.event.id).remove();
    }

    const handleEventDidMount = (args) => {
        // console.log('drop', args);
        // console.log('event did mount', args.event);
    }

    const handleEventDrop = (args) => {
    }

    const handleEventChange = (args) => {
        let startDate = new Date(args.event.start);
        let endDate = new Date(args.event.end);
        updateTimeBlock({ variables: { id: args.event.id, start: startDate.toISOString(), end: endDate.toISOString() }});
    }

    const handleMouseClickEvent = (args) => {
        // setEvent(args.event.extendedProps);
        // setTime(new Date(args.event.extendedProps.start));
        // setOpenEditEvent(true);
    }

    async function getCalendarData(fetchInfo, successCallback) {
        const startDate = new Date(fetchInfo.start);
        const endDate = new Date(fetchInfo.end);
        const response = await request(startDate, endDate);

        successCallback(
            response.data.user_by_pk.appointments.map(appointment => {
                const apt = appointment.appointment;
                let resourceNames = [];
                let users = [];

                apt.appointment_resources.forEach(r => {
                    resourceNames.push(r.resource.name);
                });

                apt.appointment_users.forEach(u => {
                    users.push(`${u.user.first_name} ${u.user.last_name}`);
                });

                return {
                    id: apt.id,
                    title: `${apt.appointment_type.name} - ${apt.appointment_clients.length > 0 ? apt.appointment_clients[0].client.first_name + ' ' + apt.appointment_clients[0].client.last_name : ''}`,
                    start: apt.start,
                    end: apt.end,
                    allDay: false,
                    editable: false,
                    backgroundColor: apt.appointment_type.color != null ? apt.appointment_type.color : '#000',
                    extendedProps: {
                        id: apt.id,
                        start: apt.start,
                        end: apt.end,
                        resourcesTitle: resourceNames.join(' - '),
                        usersTitle: users.join(', '),
                    }
                };
            }).concat(response.data.user_by_pk.time_blocks.map(time_block => {
                return {
                    id: time_block.id,
                    title: time_block.label,
                    start: time_block.start,
                    end: time_block.end,
                    allDay : false,
                    backgroundColor: time_block.label === 'Available' ? '#66bb75' : '#d10e0e',
                    extendedProps: {
                        id: time_block.id,
                        start: time_block.start,
                        end: time_block.end,
                    }
                };
            }))
        );
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '90vh',
            }}
        >
            <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid xs={10}>
                    <Toolbar
                        sx={{
                            mb: { xs: 2 },
                            pr: { xs: 0 },
                            pl: { xs: 0 },
                        }}
                    >
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h4"
                            id="tableTitle"
                            component="div"
                        >
                            {!!data && !!data.user_by_pk && `${data.user_by_pk.first_name} ${data.user_by_pk.last_name}`}
                        </Typography>
                        <Box
                            sx={{
                                width: '200px',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Date"
                                    inputFormat="MM/dd/yyy"
                                    value={searchDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Toolbar>
                    <FullCalendar
                        ref={calendarRef}
                        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                        headerToolbar={{
                            left:'title',
                            center:'newApt',
                            right:'timeGridWeek,dayGridMonth today prev,next'
                        }}
                        rerenderDelay={10}
                        eventStartEditable={true}
                        eventDurationEditable={true}
                        editable={true}
                        droppable={true}
                        allDaySlot={true}
                        height='100%'
                        slotMinTime='05:00:00'
                        slotDuration='00:15:00'
                        eventMinHeight={20}
                        dayMinWidth={100}
                        slotEventOverlap={false}
                        stickyFooterScrollbar={true}
                        nowIndicator={true}
                        eventDisplay='-color'
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, scrollGridPlugin ]}
                        slotLabelContent={(args) => {}}
                        initialView="timeGridWeek"
                        eventOverlap={false}
                        eventSources={[
                            {
                                id: 'api123',
                                events: (fetchInfo, successCallback, failureCallback) => getCalendarData(fetchInfo, successCallback, failureCallback)
                            }
                        ]}
                        eventMouseEnter={handleMouseEnterEvent}
                        eventClick={handleMouseClickEvent}
                        eventDidMount={handleEventDidMount}
                        eventReceive={handleEventReceive}
                        eventDrop={handleEventDrop}
                        eventChange={handleEventChange}
                        views={{
                            day: {
                                titleFormat: {
                                    year: 'numeric', month: 'long', day: 'numeric', weekday: 'short'
                                },
                            },
                            week:{
                                dayMaxEvents: 20
                            }
                        }}
                        customButtons= {{
                            newApt: {
                                text: 'New Appointment',
                                click: handleClickOpen,
                            },
                        }}
                    />
                </Grid>
                <Grid xs={2}>
                    <Paper sx={{ mt: 18, mb: 4 }}>
                        <List
                            id="external-events"
                            component="div"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Drag and drop new time blocks
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemButton sx={{ backgroundColor: '#66bb75', color: '#fff', '&:hover': {backgroundColor: '#5da36a'} }}>
                                    <ListItemText
                                        className="fc-event"
                                        primary="Available"
                                        title='Available'
                                        bgcolor='#66bb75'
                                        eventid='avail123'
                                    />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton sx={{ backgroundColor: '#d10e0e', color: '#fff', '&:hover': {backgroundColor: '#ac1a1a'} }}>
                                    <ListItemText className="fc-event" primary="Unavailable" title='Unavailable' bgcolor='#d10e0e' eventid='busy123'/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Paper>
                    <Paper>
                        <FullCalendar
                            ref={deleteCalendarRef}
                            schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                            headerToolbar={false}
                            // dayHeaders={false}
                            dayHeaderContent='Drag here to delete'
                            height='150px'
                            contentHeight='150px'
                            rerenderDelay={10}
                            eventStartEditable={true}
                            eventDurationEditable={true}
                            editable={true}
                            droppable={true}
                            eventMinHeight={200}
                            dayMinWidth={100}
                            // contentHeight='100px'
                            slotEventOverlap={true}
                            stickyFooterScrollbar={true}
                            nowIndicator={false}
                            eventDisplay='-color'
                            plugins={[ dayGridPlugin, interactionPlugin, scrollGridPlugin ]}
                            initialView="dayGrid"
                            events={[]}
                            eventOverlap={true}
                            eventReceive={handleDeleteEvent}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {open && 
                <NewAppointmentDrawer calendar={calendarRef} open={open} setOpen={setOpen} refresh={refresh} setRefresh={setRefresh} />
            }
        </Box>
    );
}