import React, { Component } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router } from "react-router-dom";

import Memory from './Utils/Memory';
import Main from './Containers/Main';

axios.defaults.withCredentials = true

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Memory.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const theme = createTheme({
  palette: {
    primary: { main:'#2a4166' },
    // secondary: { main:'#FCAB10' },
    // error: { main:'#CC2936' },
    accent: { main:'#34ab48' },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: 'rgb(0 40 100 / 4%) 0px 4px 8px',
      }
    }
  }
});

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <Router>
              <Main/>
            </Router>
          </ThemeProvider>
        </ApolloProvider>
      </React.Fragment>
    );
  }
}

export default App;
