import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import { useSpeechSynthesis } from "react-speech-kit";
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

const GET_RESOURCES = gql`
query GetResourcessTable($userId: uuid!, $limit: Int!, $offset: Int!, $searchFilter: String!) {
  resource(limit: $limit, offset: $offset, where: {organization: {users: {id: {_eq: $userId}}}, name: {_ilike: $searchFilter}}, order_by: {created_at: asc}) {
    id
    name
    status
    enable_routing
    calling_text
  }
  resource_aggregate {
    aggregate {
      count
    }
  }
  user_by_pk(id: $userId) {
    organization_id
  }
}`;

const ADD_RESOURCE = gql`mutation AddResource($name: String!, $orgId: uuid!) {
  insert_resource_one(object: {name: $name, organization_id: $orgId}) {
    created_at
  }
}`;

const UPDATE_RESOURCE = gql`mutation UpdateResource($name: String!, $calling_text: String!, $enable_routing: Boolean!, $resourceId: uuid!) {
  update_resource_by_pk(pk_columns: {id: $resourceId}, _set: {name: $name, calling_text: $calling_text, enable_routing: $enable_routing}) {
    created_at
  }
}`;

const EnhancedTableToolbar = (props) => {
  const { numSelected, setFilter, addResource, data } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 2 },
        pr: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <React.Fragment>
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Rooms
          </Typography>
          <Grid container spacing={3} sx={{ flex: '1 1 100%' }}>
            <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
              {!!data && !!data.user_by_pk && 
                <Button variant="contained" size='small' sx={{ ml: 4 , width: 200 }} onClick={() => addResource({ variables: { name: 'New Room', orgId: data.user_by_pk.organization_id } })}>Add Room</Button>
              }
            </Grid>
          </Grid>
          <TextField id="search-basic" autoFocus={true} label="search" variant="outlined" onChange={(e) => {setFilter(`%${e.target.value}%`)}} />
        </React.Fragment>
      )}

      {numSelected > 0 && 
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
};

export default function Resources(props) {
  const navigate = useNavigate();
  const { speak, voices } = useSpeechSynthesis();
  const [limit, setLimit] = React.useState(30);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [filter, setFilter] = React.useState('%%');
  const [selected, setSelected] = React.useState([]);
  const [isActive, setIsActive] = React.useState('');

  const { data } = useQuery(GET_RESOURCES, {
    variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
  });

  const [addResource] = useMutation(ADD_RESOURCE, {
    refetchQueries: [
      {
          query: GET_RESOURCES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const [updateResource] = useMutation(UPDATE_RESOURCE, {
    refetchQueries: [
      {
          query: GET_RESOURCES,
          variables: { userId: Memory.id, limit: limit, offset: offset, searchFilter: filter },
      }
    ]
  });

  const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.resource_aggregate.aggregate.count) : 0;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.resource.map((c) => c.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setOffset(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setOffset(0);
    setPage(0);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
      <EnhancedTableToolbar numSelected={selected.length} setFilter={setFilter} addResource={addResource} data={data} />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={!!data && selected.length > 0 && selected.length < data.resource.length}
                  checked={!!data && data.resource.length > 0 && selected.length === data.resource.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Calling Text</TableCell>
              <TableCell align="center">Enable Office Routing</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data && data.resource_aggregate.aggregate.count > 0 && !!data.resource && data.resource.length > 0 && 
            data.resource.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onMouseEnter={()=>{setIsActive(row.id)}}
                  onMouseLeave={()=>{setIsActive('')}}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  </TableCell>
                  {isActive === row.id ? 
                    <React.Fragment>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align='left'
                      >
                        <TextField id="update-resource-name" fullWidth autoFocus={true} variant="standard" value={row.name} onChange={(e) => {updateResource({ variables: { name: e.target.value, calling_text: row.calling_text, enable_routing: row.enable_routing, resourceId: row.id } })}} />
                      </TableCell>
                      <TableCell
                        align='left'
                      >
                        <TextField id="update-resource-calling-text" fullWidth variant="standard" value={row.calling_text} onChange={(e) => {updateResource({ variables: { name: row.name, calling_text: e.target.value, enable_routing: row.enable_routing, resourceId: row.id } })}} />
                      </TableCell>
                    </React.Fragment> 
                    :
                    <React.Fragment>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align='left'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align='left'
                      >
                        {row.calling_text}
                      </TableCell>
                    </React.Fragment>
                  }
                  <TableCell align="center">
                    <Checkbox checked={row.enable_routing} onClick={(e) => updateResource({ variables: { name: row.name, calling_text: row.calling_text, enable_routing: e.target.checked, resourceId: row.id } })}/>
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="text" size='small' disabled={!!!row.calling_text || !row.enable_routing} onClick={() => speak({ text: row.calling_text, voice: voices[0], rate: .85, pitch: 1 })}>Test Call</Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="text" size='small' onClick={() => navigate(`../room/${row.id}/live`)}>Live View</Button>
                  </TableCell>
                </TableRow>
              )})
            }
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (33) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[30, 60, 90]}
        component="div"
        count={!!data ? data.resource_aggregate.aggregate.count : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}