import * as React from 'react';
import { useApolloClient, useQuery, gql } from '@apollo/client';
import axios from "axios";
import { styled, useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ClientsInput from './ClientsInput';
import AppointmentTypesInput from './AppointmentTypesInput';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const drawerWidth = 400;

const GET_APPOINTMENT_DETAILS = gql`
query GetAppointmentDetails($id: uuid!, $userId: uuid!) {
    appointment_by_pk(id: $id) {
        id
        organization_id
        start
        end
        status
        type_id
        appointment_type {
            id
            name
            source_id
            source_name
            organization_id
            minute_duration
        }
        appointment_clients {
            id
            client {
                id
                source_id
                first_name
                last_name
            }
        }
    }
    appointment_type(where: {organization: {users: {id: {_eq: $userId}}}, enable_scheduling: {_eq: true}}, order_by: {name: asc}) {
      id
      name
      source_id
      source_name
      organization_id
      minute_duration
      requires_resource
      requires_team_member
    }
}`;

const UPDATE_APPOINTMENT = gql`
mutation UpdateAppointment($args: updateAppointmentInput!) {
    updateAppointment(args: $args) {
      error
      message
    }
}`;

const CANCEL_APPOINTMENT = gql`
mutation CancelAppointment($aptId: uuid!, $orgId: uuid!, $aptSourceId: String!) {
    cancelAppointment(args: {aptId: $aptId, orgId: $orgId, aptSourceId: $aptSourceId}) {
        error
        message
    }
}`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function EditAppointmentDrawer(props) {
    const { apt, startTime, setTime, open, setOpen, refresh, setRefresh } = props;
    const apolloClient = useApolloClient();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [client, setClient] = React.useState([]);
    const [typeId, setTypeId] = React.useState('');
    const [roomId, setRoomId] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [processing, setProcessing] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
  
    const theme = useTheme();

    const updateAppointment = (args) => apolloClient.mutate({
        mutation: UPDATE_APPOINTMENT,
        variables: { 
            args: args
        },
        fetchPolicy: "no-cache",
    });

    const cancelAppointment = (aptId, aptSourceId) => apolloClient.mutate({
        mutation: CANCEL_APPOINTMENT,
        variables: { 
            aptId: aptId,
            aptSourceId: aptSourceId,
        },
        fetchPolicy: "no-cache",
    })

    let { data, loading } = useQuery(GET_APPOINTMENT_DETAILS, {
        variables: { id: apt ? apt.id : '', userId: Memory.id },
        fetchPolicy: "no-cache",
    });

    React.useEffect(() => {
        let active = true;

        if (!data) {
            return undefined;
        }
    
        if (!!data && !!data.appointment_by_pk) {
            setClient(data.appointment_by_pk.appointment_clients.map(
                ap => {
                    return {
                        title: `${ap.client.first_name} ${ap.client.last_name}`,
                        value: ap.client.id,
                        key: ap.client.id,
                        source_id: ap.client.source_id,
                    }
                })
            );

            setTypeId(data.appointment_by_pk.appointment_type.id);
        }
    
        return () => {
          active = false;
        };
    }, [data]);

    const handleCancelAppointment = async () => {
        const cancel_apt_response = await cancelAppointment(apt.id, apt.organization_id, apt.source_id);

        if (cancel_apt_response.data.cancelAppointment.message === 'success') {
            setProcessing(false);
            setOpenDeleteDialog(false);
            setRefresh(!refresh);
            setSnackMessage('Appointment canceled');
        }
    };

    const editAppointment = async () => {
        if (!!data && !!data.appointment_type && !!client && !!typeId) {
            const type = data.appointment_type.find(at => at.id === typeId);
            const update_apt_response = await updateAppointment({
                aptId: apt.id,
                orgId: apt.organization_id,
                aptSourceId: apt.source_id,
                userId: Memory.id,
                clientIds: client.map(c => c.value),
                sourceClientIds: client.map(c => c.source_id),
                aptTypeId: typeId,
                start: startTime,
                end: (new Date(startTime.getTime() + type.minute_duration*60000)).toISOString(),
                roomId: type.requires_resource ? roomId : null,
                teamMemberId: type.requires_team_member ? userId : null,
            });

            if (update_apt_response.data.updateAppointment.message === 'success') {
                setProcessing(false);
                setRefresh(!refresh);
                setSnackMessage('Appointment updated');
            }
        }
    };

    const handleClose = () => {
        setClient([]);
        setTypeId('');
        setOpen(false);
    };

    const handleCloseDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackMessage('');
    };

    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                height: 'auto',
                width: drawerWidth,
                marginTop: '25vh',
            },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            {!loading && !!data ? 
                <React.Fragment>
                    <Divider />
                    <DrawerHeader>
                        <IconButton onClick={handleClose}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                        <Box>
                            <Typography variant="h6">
                                Edit Appointment
                            </Typography>
                            <Typography variant="subtitle1">
                                {data.appointment_by_pk.appointment_type.name}
                            </Typography>
                        </Box>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem>
                            <TextField
                                id="appointment-type-input"
                                disabled
                                select
                                fullWidth
                                label="Appointment Type"
                                value={typeId === '' ? data.appointment_by_pk.appointment_type.id : typeId}
                                onChange={(e => setTypeId(e.target.value))}
                            >
                                {!!data && data.appointment_type.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {`${type.name} - ${type.source_name}`}
                                    </MenuItem>
                                ))}
                                <MenuItem key='none' value='' />
                            </TextField>
                        </ListItem>
                        <ListItem>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Time"
                                    value={startTime}
                                    onChange={(newTime) => setTime(newTime)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </ListItem>
                        <ListItem>
                            <ClientsInput
                                disabled={true}
                                multiple={true}
                                initialValue={data.appointment_by_pk.appointment_clients.map(
                                    ap => {
                                        return {
                                            title: `${ap.client.first_name} ${ap.client.last_name}`,
                                            value: ap.client.id,
                                            key: ap.client.id,
                                            source_id: ap.client.source_id,
                                        }
                                    })
                                }
                                setClient={setClient}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                sx={{ backgroundColor: '#d10e0e', color: '#fff', mt: 1, textAlign: 'center', '&:hover': {backgroundColor: '#ac1a1a'} }}
                                onClick={() => setOpenDeleteDialog(true)}
                            >
                                <ListItemText primary='Cancel Appointment' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                sx={{ backgroundColor: '#66bb75', color: '#fff', mt: 1, textAlign: 'center', '&:hover': {backgroundColor: '#5da36a'} }}
                                disabled={!client || !typeId || processing}
                                onClick={() => {setProcessing(true); editAppointment(client, typeId, startTime);}}
                            >
                                {processing ? 
                                    <CircularProgress />
                                    :
                                    <ListItemText primary='Save' />
                                }
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </React.Fragment>
                :
                <CircularProgress />
            }
            <Snackbar open={snackMessage != ''} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <Dialog onClose={handleCloseDialog} open={openDeleteDialog}>
                <DialogTitle>Are you sure?</DialogTitle>
                {/* <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent> */}
                <DialogActions>
                    <Button onClick={handleCancelAppointment} sx={{ backgroundColor: '#66bb75', color: '#fff', '&:hover': {backgroundColor: '#5da36a' }}}>Yes</Button>
                    <Button onClick={handleCloseDialog} sx={{ backgroundColor: '#d10e0e', color: '#fff', '&:hover': {backgroundColor: '#ac1a1a' }}}>No</Button>
                </DialogActions>
            </Dialog>
        </Drawer>               
    );
}