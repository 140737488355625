import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import Memory from '../../Utils/Memory';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ViewClientFormDrawer from './ViewClientFormDrawer';

export default function ClientFormsTable(props) {
    const { data } = props;
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);
    const [openViewClientFormDrawer, setOpenViewClientFormDrawer] = React.useState(false);
    const [clientFormTitle, setClientFormTitle] = React.useState('');
    const [clientFormId, setClientFormId] = React.useState('');

    const emptyRows = !!data && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.clients_aggregate.aggregate.count) : 0;

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

  return (
    <React.Fragment>
        <Toolbar
            sx={{
                pl: { xm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
            >
                Forms
            </Typography>
        </Toolbar>
        <Paper sx={{ width: '100%', overflow: 'hidden', mb: 4  }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Submitted</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!!data && !!data.clients_by_pk && data.clients_by_pk.client_forms_aggregate.aggregate.count > 0 && !!data.clients_by_pk.client_forms && data.clients_by_pk.client_forms.length > 0 && 
                        data.clients_by_pk.client_forms.map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                    <TableCell align="left">{new Date(row.submitted_date).toLocaleDateString()}</TableCell>
                                    <TableCell align="left">{row.form.name}</TableCell>
                                    <TableCell align="left">{row.integration_provider.name}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                    <TableCell align="right">
                                        <Button variant="text" size='small' onClick={() => {
                                            setClientFormTitle(`${data.clients_by_pk.first_name} ${data.clients_by_pk.last_name} - ${row.form.name}`);
                                            setClientFormId(row.id);
                                            setOpenViewClientFormDrawer(true);
                                        }}>View</Button>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button variant="text" size='small' target="_blank" rel="noopener" href={`${process.env.REACT_APP_AUTH_SERVER_URI}/api/client_form/${row.id}/pdf?orgId=${row.organization_id}&ipId=${row.integration_provider_id}`}>Pdf</Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30, 60, 90]}
                component="div"
                count={!!data && !!data.clients_by_pk ? data.clients_by_pk.client_forms_aggregate.aggregate.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        {openViewClientFormDrawer && 
            <ViewClientFormDrawer title={clientFormTitle} clientFormId={clientFormId} open={openViewClientFormDrawer} setOpen={setOpenViewClientFormDrawer} />
        }
    </React.Fragment>
  );
}
