import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockedIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const UPDATE_RESOURCE_QUEUE_STATUS = gql`mutation UpdateQueueStatus($resourceQueueId: uuid!, $status: String) {
    update_resource_queue_by_pk(pk_columns: {id: $resourceQueueId}, _set: {status: $status}) {
      created_at
    }
}`;

export default function WaitingRoomLobbyTable(props) {
    const { data, refetchQueries } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [subAnchorEl, setSubAnchorEl] = React.useState(null);
    const openSub = Boolean(subAnchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setSubAnchorEl(null);
    };

    const handleOpenSub = (event) => {
        setSubAnchorEl(event.currentTarget);
    };

    const [updateResourceQueueStatus] = useMutation(UPDATE_RESOURCE_QUEUE_STATUS, {
        refetchQueries: refetchQueries
    });

    const handleLockClick = (status, row, data) => {
        if (!!row.group_code) {
            for (let rq of data) {
                if (rq.group_code === row.group_code) {
                    updateResourceQueueStatus({ variables: { resourceQueueId: rq.id, status: status }})
                }
            }
        } else {
            updateResourceQueueStatus({ variables: { resourceQueueId: row.id, status: status }})
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table
                size="large"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="left" sx={{ width: 50 }} />
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Group</TableCell>
                        <TableCell align="center">Code</TableCell>
                        <TableCell align="right">Appointment Time</TableCell>
                        <TableCell align="right">Wait Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((row, index) => (
                            <TableRow
                                key={row.id + index}
                                hover
                                sx={{ bgcolor: '#fff' }}
                                onClick={handleClick}
                            >
                                <TableCell align="left">
                                    {row.status === 'Locked' ?
                                        <IconButton aria-label="lock-resource-queue" sx={{ color: '#d10e0e' }} onClick={() => handleLockClick(null, row, data)}>
                                            <LockedIcon />
                                        </IconButton>
                                        :
                                        <IconButton aria-label="lock-resource-queue" onClick={() => handleLockClick('Locked', row, data)}>
                                            <LockOpenIcon />
                                        </IconButton>
                                    }
                                </TableCell>
                                <TableCell align='left'>{!!row.client ? `${row.client.first_name} ${row.client.last_name}` : `${row.first_name} ${row.last_name}`}</TableCell>
                                <TableCell align='center'>{!!row.group_code ? row.group_code : ''}</TableCell>
                                <TableCell align='center'>{!!row.apt_code ? row.apt_code : ''}</TableCell>
                                <TableCell align='right'>{!!row.start_time ? new Date(row.start_time).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) : ''}</TableCell>
                                <TableCell align='right'>{`${Math.floor(((new Date() - new Date(row.created_at))/1000)/60)} min`}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onMouseEnter={handleOpenSub} onMouseLeave={() => console.log('goodbye')}>
                    Move
                    <Menu
                        id="basic-menu"
                        anchorEl={subAnchorEl}
                        open={openSub}
                        onClose={handleClose}
                        onMouseLeave={() => setSubAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Top</MenuItem>
                        <MenuItem onClick={handleClose}>Room 1</MenuItem>
                        <MenuItem onClick={handleClose}>Room 2</MenuItem>
                        <MenuItem onClick={handleClose}>Room 3</MenuItem>
                        <MenuItem onClick={handleClose}>Bottom</MenuItem>
                    </Menu>
                </MenuItem>
                <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
        </TableContainer>
    );
}