import * as React from 'react';
import { useMutation, useQuery, useApolloClient, gql } from '@apollo/client';
import { alpha } from '@mui/material/styles';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine } from '@nivo/line'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import NewDashboardItem from '../Forms/NewDashboardItem';

const SYNC_CALENDAR = gql`query SyncCalendar($orgId: uuid!, $start: timestamptz!, $end: timestamptz!) {
    syncAppointments(args: {orgId: $orgId, start: $start, end: $end}) {
        message
        error
    }
}`;

const GET_APPOINTMENTS_BY_TYPE = gql`
query GetAppintmentsByType($orgId: uuid!, $start: timestamptz!, $end: timestamptz!, $aptTypes: [uuid]!) {
    appointment(where: {organization_id: {_eq: $orgId}, status: {_in: ["Scheduled","Arrived","Canceled"]}, start: {_gte: $start}, end: {_lte: $end}, type_id: {_in: $aptTypes}}, order_by: {start: asc}) {
        id
        start
        end
        status
        appointment_type {
            id
            name
        }
        appointment_clients {
            client_id
            client {
                first_name
                last_name
            }
        }
    }
}`;

const GET_APPOINTMENT_TYPE_TOTALS = gql`
query GetAppintmentsByType($orgId: uuid!, $start: timestamptz!, $end: timestamptz!, $aptTypes: [uuid]!) {
    appointment_type {
        id
        name
        appointments_aggregate(where: {organization_id: {_eq: $orgId}, status: {_in: ["Scheduled", "Arrived", "Canceled"]}, start: {_gte: $start}, end: {_lte: $end}, type_id: {_in: $aptTypes}}) {
            aggregate {
                count
            }
        }
    }
}`;

const GET_END_APPOINTMENTS_BY_TYPE = gql`
query GetEndAppintmentsByType($orgId: uuid!, $start: timestamptz!, $aptTypes: [uuid]!, $clientId: uuid!) {
    appointment(where: {organization_id: {_eq: $orgId}, status: {_in: ["Scheduled","Arrived","Canceled"]}, start: {_gte: $start}, type_id: {_in: $aptTypes}, appointment_clients: {client_id: {_eq: $clientId}}}, order_by: {start: asc}) {
        id
        start
        end
        status
        appointment_type {
            id
            name
        }
        appointment_clients {
            client_id
            client {
                first_name
                last_name
            }
        }
    }
}`;

const GET_CLIENT_APPOINTMENTS = gql`
query GetClientAppointments($orgId: uuid!, $start: timestamptz!, $aptTypes: [uuid]!, $clientId: uuid!) {
    clients_by_pk(id: $clientId) {
        id
        first_name
        last_name
        appointments(where: {appointment: {organization_id: {_eq: $orgId}, status: {_in: ["Scheduled","Arrived","Canceled"]}, start: {_gte: $start}, type_id: {_in: $aptTypes}}}, order_by: {appointment: {start: asc}}, limit: 1) {
            appointment {
                id
                start
                end
                status
                appointment_type {
                    id
                    name
                }
                appointment_clients {
                    client_id
                    client {
                        first_name
                        last_name
                    }
                }
            }
        }
    }
}`

const GET_DASHBOARD = gql`
query GetDashboard($orgId: uuid!) {
    dashboard(where: {organization_id: {_eq: $orgId}}) {
        id
        order_data
        charts {
            id
            name
            data
            width
            type
        }
        tables {
            id
            name
            columns
            data
            width
            type
        }
    }
    appointment_type(where: {organization_id: {_eq: $orgId}}, order_by: {name: asc, created_at: asc}) {
        id
        name
        source_name
    }
    data_point(where: {organization_id: {_eq: $orgId}}, order_by: {name: asc, created_at: asc}) {
        id
        name
        function
        data
    }
}`;

const GET_DATA_POINTS = gql`
query GetDataPoints($orgId: uuid!, $ids: [uuid]!) {
    data_point(where: {organization_id: {_eq: $orgId}, id: {_in: $ids}},order_by: {name: asc, created_at: asc}) {
        id
        name
        function
        data
    }
}`;

const DataPointDetails = (props) => {
    const { open = false, setOpen, start, end, dataPoint, apolloClient } = props;
    const jsonData = !!dataPoint.data ? JSON.parse(dataPoint.data) : {};
    const [tableRows, setTableRows] = React.useState([]);
    const [limit, setLimit] = React.useState(30);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    const { data } = useQuery(GET_APPOINTMENTS_BY_TYPE, {
        variables: { orgId: Memory.orgId, aptTypes: !!jsonData.start_apt_type ? jsonData.start_apt_type.split(',') : [], start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const requestApts = (aptTypes, clientId, start) => apolloClient.query({
        query: GET_CLIENT_APPOINTMENTS,
        variables: { orgId: Memory.orgId, aptTypes: aptTypes, clientId: clientId, start: start.toISOString() },
        fetchPolicy: "no-cache",
    });

    React.useEffect(() => {
        if (!!data && !!data.appointment && !!dataPoint) {
            getData(data);
        }
    }, [data, dataPoint, start, end, open]);

    async function getData(data) {
        if (['total', 'status'].some(status => status === dataPoint.function)) {
            setTableRows(data.appointment ?? []);
        } else if (['close_total', 'close_rate', 'avg_time'].some(status => status === dataPoint.function)) {
            let rows = [];

            for (const apt of data.appointment) {
                let rowDetails = {
                    start_apt_start: apt.start,
                    start_apt_end: apt.end,
                    start_apt_type_name: apt.appointment_type.name,
                    start_apt_clients: apt.appointment_clients.map(c => `${c.client.first_name} ${c.client.last_name}`).join(", "),
                    start_apt_status: apt.status,
                }

                const response = await requestApts(jsonData.end_apt_type.split(','), apt.appointment_clients[0].client_id, new Date(apt.end))

                if (!!response && !!response.data && !!response.data.clients_by_pk && !!response.data.clients_by_pk.appointments && response.data.clients_by_pk.appointments.length > 0) {
                    let first = new Date(apt.start);
                    let second = new Date(response.data.clients_by_pk.appointments[0].appointment.start);

                    rowDetails.days_between = Math.ceil((second.getTime() - first.getTime()) / (1000 * 3600 * 24));
                    rowDetails.end_apt_start = response.data.clients_by_pk.appointments[0].appointment.start;
                    rowDetails.end_apt_end = response.data.clients_by_pk.appointments[0].appointment.end;
                    rowDetails.end_apt_type_name = response.data.clients_by_pk.appointments[0].appointment.appointment_type.name;
                    rowDetails.end_apt_clients = response.data.clients_by_pk.appointments[0].appointment.appointment_clients.map(c => `${c.client.first_name} ${c.client.last_name}`).join(", ");
                    rowDetails.end_apt_status = response.data.clients_by_pk.appointments[0].appointment.status;
                }

                rows.push(rowDetails);
            }

            setTableRows(rows);
        }
    }

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xl'>
                <DialogTitle>
                    {dataPoint.name}
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                {['total', 'status'].some(status => status === dataPoint.function) && 
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">End Time</TableCell>
                                        <TableCell align="left">Appointment Type</TableCell>
                                        <TableCell align="left">Client</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                }
                                {['close_total', 'close_rate', 'avg_time'].some(status => status === dataPoint.function) && 
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">End Time</TableCell>
                                        <TableCell align="left">Appointment Type</TableCell>
                                        <TableCell align="left">Client</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Days Between</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Start Time</TableCell>
                                        <TableCell align="left">End Time</TableCell>
                                        <TableCell align="left">Appointment Type</TableCell>
                                        <TableCell align="left">Client</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                }
                            </TableHead>
                            <TableBody>
                                {tableRows.length === 0 && 
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                }
                                {!!dataPoint && ['total', 'status'].some(status => status === dataPoint.function) && tableRows.map(row => {
                                    return (!!row && 
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >   
                                            <TableCell align="left">{!!row.start ? new Date(row.start).toLocaleDateString() : ''}</TableCell>
                                            <TableCell align="left">{!!row.start ?new Date(row.start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) : ''}</TableCell>
                                            <TableCell align="left">{!!row.end ?new Date(row.end).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) : ''}</TableCell>
                                            <TableCell align="left">{!!row.appointment_type ? row.appointment_type.name : ''}</TableCell>
                                            <TableCell align="left">{!!row.appointment_clients ? row.appointment_clients.map(c => `${c.client.first_name} ${c.client.last_name}`).join(", ") : ''}</TableCell>
                                            <TableCell align="right">{row.status}</TableCell>
                                        </TableRow>
                                )})}
                                {!!dataPoint && ['close_total', 'close_rate', 'avg_time'].some(status => status === dataPoint.function) && tableRows.map(row => {
                                    return (!!row && 
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >   
                                            <TableCell align="left">{new Date(row.start_apt_start).toLocaleDateString()}</TableCell>
                                            <TableCell align="left">{new Date(row.start_apt_start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                            <TableCell align="left">{new Date(row.start_apt_end).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</TableCell>
                                            <TableCell align="left">{row.start_apt_type_name}</TableCell>
                                            <TableCell align="left">{row.start_apt_clients}</TableCell>
                                            <TableCell align="right">{row.start_apt_status}</TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center">{row.days_between}</TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="left">{!!row.end_apt_start ? new Date(row.end_apt_start).toLocaleDateString() : ''}</TableCell>
                                            <TableCell align="left">{!!row.end_apt_start ? new Date(row.end_apt_start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) : ''}</TableCell>
                                            <TableCell align="left">{!!row.end_apt_end ? new Date(row.end_apt_end).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) : ''}</TableCell>
                                            <TableCell align="left">{row.end_apt_type_name}</TableCell>
                                            <TableCell align="left">{row.end_apt_clients}</TableCell>
                                            <TableCell align="right">{row.end_apt_status}</TableCell>
                                        </TableRow>
                                )})}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[30, 60, 90]}
                        component="div"
                        count={tableRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const EnhancedTableToolbar = (props) => {
    const { start, setStart, end, setEnd, dashboardId, data } = props;

  return (
    <Toolbar
      sx={{
        mb: { xs: 2 },
        pl: { sm: 0 },
        pr: { xs: 0 },
      }}
    >
      <React.Fragment>
            <Typography
                sx={{
                    mr: 2,
                    pl: { xs: 0 },
                }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Dashboard
            </Typography>

            <NewDashboardItem dashboardId={dashboardId} orderData={!!data && !!data.dashboard ? data.dashboard[0].order_data : null} data={data} refetchQueries={[{ query: GET_DASHBOARD, variables: { orgId: Memory.orgId }}]} />

            <Typography sx={{ flexGrow: 1 }} />
            
            <Box
                sx={{
                    width: '200px',
                    mr: 2,
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Start"
                        inputFormat="MM/dd/yyy"
                        value={start}
                        onChange={setStart}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                sx={{
                    width: '200px',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="End"
                        inputFormat="MM/dd/yyy"
                        value={end}
                        onChange={setEnd}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
        </React.Fragment>
    </Toolbar>
  );
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach(datum => {
        total += datum.value;
    });

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill='black'
            style={{
                fontSize: '24px',
            }}
        >
            {total}
        </text>
    )
}

const DashboardPieChart = (props) => {
    const { item, start, end } = props;

    const { data } = useQuery(GET_APPOINTMENT_TYPE_TOTALS, {
        variables: { orgId: Memory.orgId, aptTypes: item.data.split(','), start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const getPieData = (data, orderData) => {
        let chartData = [];
        let dataMap = {};
    
        if (!!data && !!data.appointment_type) {
            data.appointment_type.forEach(type => {
                dataMap[type.id] = {
                    id: type.name,
                    label: type.name,
                    value: !!type.appointments_aggregate && !!type.appointments_aggregate.aggregate && !!type.appointments_aggregate.aggregate.count ? type.appointments_aggregate.aggregate.count : 0,
                }
            });

            orderData.forEach(typeId => {
                if (dataMap[typeId] !== undefined) {
                    chartData.push(dataMap[typeId]);
                }
            })
        }

        return chartData;
    };

    return (
        <ResponsivePie 
            data={getPieData(data, item.data.split(','))}
            margin={{ top: 20, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.7}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={{ scheme: 'paired' }}
            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
        />
    );
}

const DashboardListTable = (props) => {
    const { item, start, end } = props;

    const { data } = useQuery(GET_APPOINTMENT_TYPE_TOTALS, {
        variables: { orgId: Memory.orgId, aptTypes: item.data.split(','), start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const getTableData = (data, orderData) => {
        let tableData = [];
        let dataMap = {};
    
        if (!!data && !!data.appointment_type) {
            data.appointment_type.forEach(type => {
                dataMap[type.id] = {
                    id: type.name,
                    label: type.name,
                    value: !!type.appointments_aggregate && !!type.appointments_aggregate.aggregate && !!type.appointments_aggregate.aggregate.count ? type.appointments_aggregate.aggregate.count : 0,
                }
            });

            orderData.forEach(typeId => {
                if (dataMap[typeId] !== undefined) {
                    tableData.push(dataMap[typeId]);
                }
            })
        }

        return tableData;
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getTableData(data, item.data.split(',')).map((row, index) =>  (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align='left'
                                >
                                    {row.label}
                                </TableCell>
                                <TableCell align="center">{row.value}</TableCell>
                            </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}

const DataPointCell = (props) => {
    const { item, apolloClient, start, end, align = 'left' } = props;
    const [value, setValue] = React.useState('');
    const jsonData = !!item.data ? JSON.parse(item.data) : {};

    const { data } = useQuery(GET_APPOINTMENTS_BY_TYPE, {
        variables: { orgId: Memory.orgId, aptTypes: !!jsonData.start_apt_type ? jsonData.start_apt_type.split(',') : [], start: start.toISOString(), end: end.toISOString() },
        fetchPolicy: "no-cache",
    });

    const requestApts = (aptTypes, clientId, start) => apolloClient.query({
        query: GET_CLIENT_APPOINTMENTS,
        variables: { orgId: Memory.orgId, aptTypes: aptTypes, clientId: clientId, start: start.toISOString() },
        fetchPolicy: "no-cache",
    });

    React.useEffect(() => {
        if (!!data && !!data.appointment) {
            getData(data);
        }
    }, [data]);

    async function getData(data) {
        if (item.function === 'total') {
            setValue(data.appointment.length);
        } else if (item.function === 'status') {
            setValue(data.appointment.filter(apt => apt.status === jsonData.status).length);
        } else if (item.function === 'close_total') {
            let counter = 0;

            for (const apt of data.appointment) {
                if (['Arrived', 'Canceled'].some(status => status === apt.status)) {
                    const response = await requestApts(jsonData.end_apt_type.split(','), apt.appointment_clients[0].client_id, new Date(apt.end))

                    if (!!response && !!response.data && !!response.data.clients_by_pk && !!response.data.clients_by_pk.appointments && response.data.clients_by_pk.appointments.length > 0) {
                        counter++;
                    }
                }
            }

            setValue(counter);
        } else if (item.function === 'close_rate') {
            let followUpCounter = 0;
            let counter = 0;

            for (const apt of data.appointment) {
                if (['Arrived', 'Canceled'].some(status => status === apt.status)) {
                    const response = await requestApts(jsonData.end_apt_type.split(','), apt.appointment_clients[0].client_id, new Date(apt.end))

                    if (!!response && !!response.data && !!response.data.clients_by_pk && !!response.data.clients_by_pk.appointments && response.data.clients_by_pk.appointments.length > 0) {
                        counter++;
                    }
                    followUpCounter++;
                }
            }

            setValue(followUpCounter > 0 ? `${((counter / followUpCounter) * 100).toFixed(2)}%` : '-');
        } else if (item.function === 'avg_time') {
            let followUpCounter = 0;
            let dayCounter = 0;

            for (const apt of data.appointment) {
                if (apt.status === 'Arrived') {
                    const response = await requestApts(jsonData.end_apt_type.split(','), apt.appointment_clients[0].client_id, new Date(apt.end))

                    if (!!response && !!response.data && !!response.data.clients_by_pk && !!response.data.clients_by_pk.appointments && response.data.clients_by_pk.appointments.length > 0) {
                        let first = new Date(apt.start);
                        let second = new Date(response.data.clients_by_pk.appointments[0].appointment.start);
    
                        dayCounter += (second.getTime() - first.getTime()) / (1000 * 3600 * 24);
                    }

                    followUpCounter++;
                }
            }

            setValue(followUpCounter > 0 ? `${(dayCounter / followUpCounter).toFixed(2)} Days` : '-');
        }
    }

    return (
        <TableCell align={align}>{value}</TableCell>
    )
}

const DashboardDataPointListTable = (props) => {
    const { item, start, end } = props;
    const apolloClient = useApolloClient();
    const [openDetails, setOpenDetails] = React.useState(false);
    const [dataPoint, setDataPoint] = React.useState({});

    const { data } = useQuery(GET_DATA_POINTS, {
        variables: { orgId: Memory.orgId, ids: !!item.data ? item.data.split(',') : '' },
        fetchPolicy: "no-cache",
    });

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ maxHeight: 375 }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!data && !!data.data_point && data.data_point.map(row => (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={row.id}
                            onClick={() => {setDataPoint(row); setOpenDetails(true);}}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                align='left'
                            >
                                {row.name}
                            </TableCell>
                            <DataPointCell item={row} apolloClient={apolloClient} start={start} end={end} align="center" />
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
            </TableContainer>
            <DataPointDetails open={openDetails} setOpen={setOpenDetails} start={start} end={end} dataPoint={dataPoint} apolloClient={apolloClient} />
        </React.Fragment>
    );
}

const DashboardHistoricalTable = (props) => {
    const { item, start, end } = props;
    const apolloClient = useApolloClient();
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const today = new Date();
    const firstOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    firstOfWeek.setHours(0, 0, 0);
    endOfWeek.setHours(23, 59, 0);

    const tableRows = [{
        start: new Date(firstOfWeek),
        end: new Date(endOfWeek),
    }];

    for(let i = 0; i <= rowsPerPage - 2; i++) {
        tableRows.push({
            start: new Date(firstOfWeek.setDate(firstOfWeek.getDate() - 7)),
            end: new Date(endOfWeek.setDate(endOfWeek.getDate() - 7)),
        })
    }

    const { data } = useQuery(GET_DATA_POINTS, {
        variables: { orgId: Memory.orgId, ids: !!item.data ? item.data.split(',') : '' },
        fetchPolicy: "no-cache",
    });

    let data_point_map = {};

    if (!!data && !!data.data_point) {
        data.data_point.forEach(point => {
            data_point_map[point.id] = point;
        });
    }

    const handleChangePage = (event, newPage) => {
        setOffset(newPage * rowsPerPage);
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setOffset(0);
        setPage(0);
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ maxHeight: 375 }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                        <TableCell align="center">Start Date</TableCell>
                        <TableCell align="center">End Date</TableCell>
                        {!!data && !!data.data_point && data.data_point.length > 0 && item.data.split(',').map(row => (
                            <TableCell align="center">{data_point_map[row].name}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((row, index) =>  (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={row.id}
                            >
                                <TableCell align='center'>{row.start.toLocaleDateString()}</TableCell>
                                <TableCell align="center">{row.end.toLocaleDateString()}</TableCell>
                                {!!data && !!data.data_point && data.data_point.length > 0 && item.data.split(',').map(id => (
                                    <DataPointCell item={data_point_map[id]} apolloClient={apolloClient} start={row.start} end={row.end} align="center" />
                                ))}
                            </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[12, 24, 36, 52]}
                component="div"
                count={rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </React.Fragment>
    );
}

export default function Dashboard(props) {
    const today = new Date();
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const [start, setStart] = React.useState(firstOfMonth);
    const [end, setEnd] = React.useState(endOfMonth);
    const [dashboardItems, setDashboardItems] = React.useState([]);

    const { data, startPolling } = useQuery(GET_DASHBOARD, {
        variables: { orgId: Memory.orgId },
        fetchPolicy: "no-cache",
    });

    startPolling(30000);

    useQuery(SYNC_CALENDAR, {
        variables: { orgId: Memory.orgId, start: start.toISOString(), end: end.toISOString() },
        // variables: { orgId: Memory.orgId, start: "2022-11-22T06:00:00.288Z", end: "2022-11-23T05:59:59.288Z" },
        fetchPolicy: "no-cache",
    });

    React.useEffect(() => {
        if (!!data && !!data.dashboard && !!data.dashboard[0].order_data) {
            let charts = {};
            let tables = {};
            let newItems = [];
            let itemIds = data.dashboard[0].order_data.split(',');
    
            data.dashboard[0].charts.forEach(chart => {
                charts[chart.id] = chart;
            });
    
            data.dashboard[0].tables.forEach(table => {
                tables[table.id] = table;
            });
    
            newItems = itemIds.map(id => {
                if (charts[id] !== undefined) {
                    return charts[id];
                } else if (tables[id] !== undefined) {
                    return tables[id];
                }
            });

            setDashboardItems(newItems);
        }
    }, [data]);

    const getData = data => {
        let newMembers = [];
        let dataMap = {}

        if (!!data && !!data.clients) {
            data.clients.forEach(c => {
                let keyStr = new Date(c.created_at).toISOString().split('T')[0];
                if (!!dataMap[keyStr]) {
                    dataMap[keyStr] = dataMap[keyStr] + 1;
                } else {
                    dataMap[keyStr] = 1;
                }
            });

            Object.keys(dataMap).forEach(function (key){
                newMembers.push({ x: key, y: dataMap[key]})
            });
        }

        return [{
            id: 'New Practice Members',
            data: newMembers 
        }];
    };

    const getRevenueData = (data) => {
        let chartData = [];
    
        if (!!data && !!data.service) {
            data.service.forEach(s => {
                if (!!s.transactions_aggregate.aggregate.sum.amount) {
                    chartData.push({
                        id: s.name,
                        label: s.name,
                        value: s.transactions_aggregate.aggregate.sum.amount,
                    });
                }
            });
        }
    
        return chartData;
    };

    return (
    <Box
        sx={{
            width: '100%',
            height: '100%',
        }}
    >
        <EnhancedTableToolbar start={start} setStart={setStart} end={end} setEnd={setEnd} dashboardId={!!data && !!data.dashboard ? data.dashboard[0].id : ''} data={data} />
        <Grid container spacing={3} sx={{ height: '100%' }}>
            {dashboardItems.map(item => {
                return (
                    <Grid xs={item.width} sx={{ maxHeight: 400, mb: 4 }}>
                        {item.type === "pie_chart" && 
                            <React.Fragment>
                                <Toolbar
                                    sx={{
                                        mb: { xs: 2 },
                                        pr: { xs: 0 },
                                        pl: { xs: 0 },
                                    }}
                                >
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {item.name}
                                    </Typography>
                                </Toolbar>
                                <DashboardPieChart item={item} start={start} end={end} />
                            </React.Fragment>
                        }
                        {item.type === "list_table" && 
                            <React.Fragment>
                                <Toolbar
                                    sx={{
                                        mb: { xs: 2 },
                                        pr: { xs: 0 },
                                        pl: { xs: 0 },
                                    }}
                                >
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {item.name}
                                    </Typography>
                                </Toolbar>
                                <DashboardListTable item={item} start={start} end={end} />
                            </React.Fragment>
                        }
                        {item.type === "data_list_table" && 
                            <React.Fragment>
                                <Toolbar
                                    sx={{
                                        mb: { xs: 2 },
                                        pr: { xs: 0 },
                                        pl: { xs: 0 },
                                    }}
                                >
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {item.name}
                                    </Typography>
                                </Toolbar>
                                <DashboardDataPointListTable item={item} start={start} end={end} />
                            </React.Fragment>
                        }
                        {item.type === "historical_table" && 
                            <React.Fragment>
                                <Toolbar
                                    sx={{
                                        mb: { xs: 2 },
                                        pr: { xs: 0 },
                                        pl: { xs: 0 },
                                    }}
                                >
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {item.name}
                                    </Typography>
                                </Toolbar>
                                <DashboardHistoricalTable item={item} start={start} end={end} />
                            </React.Fragment>
                        }
                    </Grid>
                )
            })}
            {/* <Grid xs={4} sx={{ maxHeight: 400, mb: 4 }}>
                <Toolbar
                    sx={{
                        mb: { xs: 2 },
                        pr: { xs: 0 },
                        pl: { xs: 0 },
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        New Practice Members
                    </Typography>
                </Toolbar>
                <ResponsivePie 
                    data={getRevenueData(data)}
                    margin={{ top: 20, right: 10, bottom: 10, left: 10 }}
                    innerRadius={0.7}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    colors={{ scheme: 'paired' }}
                    layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                    valueFormat={value =>
                        `${Number(value).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`
                    }
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#997279',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#49c145',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'giving'
                            },
                            id: 'dots'
                        },
                        {
                            match: {
                                id: 'shopping'
                            },
                            id: 'lines'
                        }
                    ]}
                    // onClick={handleClick}
                />
            </Grid>
            <Grid xs={8}>
                {!!data && !!data.service && data.service.length > 0 && 
                    <React.Fragment>
                        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                            <Table
                                size="small"
                                aria-label="revenue table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="center">Description</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.service.map((r) => r).sort((a, b) => b.transactions_aggregate.aggregate.sum.amount - a.transactions_aggregate.aggregate.sum.amount).map((row, index) => (
                                            <TableRow
                                                key={row.id + index}
                                                hover
                                                sx={{ bgcolor: '#fff' }}
                                            >
                                                <TableCell align='left'>{row.name}</TableCell>
                                                <TableCell align='left'>{row.description}</TableCell>
                                                <TableCell align='right'>{!!row.transactions_aggregate && !!row.transactions_aggregate.aggregate && !!row.transactions_aggregate.aggregate.sum && !!row.transactions_aggregate.aggregate.sum.amount ? `$${row.transactions_aggregate.aggregate.sum.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                }
            </Grid>
            <Grid xs={12} sx={{ maxHeight: 400 }}>
                <Toolbar
                    sx={{
                        mb: { xs: 2 },
                        pr: { xs: 0 },
                        pl: { xs: 0 },
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        New Practice Members {!!data && !!data.clients_aggregate && !!data.clients_aggregate.aggregate ? ` - Total: ${data.clients_aggregate.aggregate.count}` : ''}
                    </Typography>
                </Toolbar>
                <ResponsiveLine
                    data={getData(data)}
                    xScale={{
                        type: 'time',
                        format: '%Y-%m-%d',
                        useUTC: false,
                        precision: 'day',
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                        type: 'linear',
                        stacked: false,
                    }}
                    axisLeft={{
                    }}
                    axisBottom={{
                        format: '%b %d',
                        tickValues: 'every 1 days',
                    }}
                    curve='linear'
                    enablePointLabel={true}
                    // pointSymbol={CustomSymbol}
                    pointSize={16}
                    pointBorderWidth={1}
                    pointBorderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.3]],
                    }}
                    useMesh={true}
                    enableSlices={false}
                />
            </Grid> */}
        </Grid>
    </Box>
  );
}