import * as React from 'react';
import axios from "axios";
import { useApolloClient, gql } from '@apollo/client';
import { styled, useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Memory from '../../Utils/Memory';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ClientsInput from '../Forms/ClientsInput';
import AppointmentTypesInput from '../Forms/AppointmentTypesInput';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RoomInput from './RoomInput';
import TeamMemberInput from './TeamMemberInput';

const drawerWidth = 400;

const ADD_APPOINTMENT = gql`
mutation AddAppointment($userId: uuid!, $orgId: uuid!, $aptTypeId: uuid!, $clientIds: [uuid]!, $sourceClientIds: [String]!, $roomId: String!, $sourceAptId: String!, $teamMemberId: String!, $start: timestamptz!, $end: timestamptz!) {
    createAppointment(args: {userId: $userId, orgId: $orgId, aptTypeId: $aptTypeId, clientId: $clientIds, sourceClientId: $sourceClientIds, roomId: $roomId, sourceAptId: $sourceAptId, teamMemberId: $teamMemberId, start: $start, end: $end}) {
        error
        message
    }
}`;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function NewAppointmentDrawer(props) {
    const { calendar = null, open, setOpen, refresh, setRefresh } = props;
    const apolloClient = useApolloClient();
    const [client, setClient] = React.useState([]);
    const [type, setType] = React.useState(null);
    const [roomId, setRoomId] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [time, setTime] = React.useState(new Date());
    const [processing, setProcessing] = React.useState(false);
    const [snack, setSnack] = React.useState(false);
  
    const theme = useTheme();

    const addAppointment = (client, type, time) => apolloClient.mutate({
        mutation: ADD_APPOINTMENT,
        variables: { 
            userId: Memory.id,
            orgId: type.organization_id,
            clientIds: client.map(c => c.value),
            sourceClientIds: client.map(c => c.source_id),
            aptTypeId: type.id,
            sourceAptId: type.source_id,
            roomId: type.requires_resource ? roomId : '',
            teamMemberId: type.requires_team_member ? userId : '',
            start: time,
            end: (new Date(time.getTime() + type.minute_duration*60000)).toISOString(),
        },
        fetchPolicy: "no-cache",
    })

    const createAppointment = async (client, type, time) => {
        if (client.length > 0 && !!type) {
            const new_apt_response = await addAppointment(client, type, time);

            if (!!new_apt_response.data && !!new_apt_response.data.createAppointment && new_apt_response.data.createAppointment.message === 'success') {
                setProcessing(false);
                setRefresh(!refresh);
                setSnack(true);
            }
        }
    };

    const handleClose = () => {
        setRoomId('');
        setUserId('');
        setClient(null);
        setType(null);
        setProcessing(false);
        setOpen(false);
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnack(false);
    };

    return (
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                height: 'auto',
                width: drawerWidth,
                marginTop: '25vh',
            },
            }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <Divider />
            <DrawerHeader>
                <IconButton onClick={handleClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Typography variant="h6">
                    New Appointment
                </Typography>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItem>
                    <ClientsInput multiple={true} setClient={setClient} />
                </ListItem>
                <ListItem>
                    <AppointmentTypesInput setType={setType} />
                </ListItem>
                {!!type && type.requires_resource && 
                <ListItem>
                    <RoomInput setRoomId={setRoomId} />
                </ListItem>
                }
                {!!type && type.requires_team_member && 
                <ListItem>
                    <TeamMemberInput setUserId={setUserId} />
                </ListItem>
                }
                <ListItem>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Time"
                            value={time}
                            onChange={(newTime) => setTime(newTime)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        sx={{ backgroundColor: '#66bb75', color: '#fff', mt: 1, mr: 1, textAlign: 'center', '&:hover': {backgroundColor: '#5da36a'} }}
                        disabled={client == null || client.length === 0 || !type || processing || (type.requires_resource && roomId==='') || (type.requires_team_member && userId==='')}
                        onClick={() => {setProcessing(true); createAppointment(client, type, time);}}
                    >
                        {processing ? 
                            <CircularProgress />
                            :
                            <ListItemText primary='Save' />
                        }
                    </ListItemButton>
                    <ListItemButton sx={{ backgroundColor: '#d10e0e', color: '#fff', mt: 1, ml: 1, textAlign: 'center', '&:hover': {backgroundColor: '#ac1a1a'} }} onClick={handleClose}>
                        <ListItemText primary='Cancel' />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <Snackbar open={snack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {!!client && client.length === 1 ? `Appointment created for ${client[0].title}!` : 'Appointment created!'}
                </Alert>
            </Snackbar>
        </Drawer>               
    );
}