import * as React from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useSpeechSynthesis } from "react-speech-kit";
import Memory from '../../Utils/Memory';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WaitingRoomResourceTable from '../Widgets/WaitingRoomResourceTable';
import WaitingRoomLobbyTable from '../Widgets/WaitingRoomLobbyTable';

const GET_ARRIVED = gql`
query GetArrivalQueue($orgId: uuid!, $start: timestamptz!, $end: timestamptz!) {
  resource_queue(where: {organization_id: {_eq: $orgId}, done_time: {_is_null: true}, called_time: {_is_null: true}, created_at: {_gte: $start, _lte: $end}}, order_by: {created_at: asc}) {
    id
    status
    start_time
    done_time
    called_time
    transaction_time
    group_code
    apt_code
    created_at
    first_name
    last_name
    client {
      id
      first_name
      last_name
    }
    appointment_type {
        id
        name
    }
    resource {
      id
    }
  }
  resource(where: {organization_id: {_eq: $orgId}}, order_by: {enable_routing: desc, name: asc}) {
    id
    name
    status
    calling_text
    enable_routing
    resource_queues(where: {called_time: {_is_null: false}, created_at: {_gte: $start, _lte: $end}, _or: [{done_time: {_is_null: true}}, {transaction_time: {_is_null: true}}]}) {
        id
        transaction_time
        called_time
        done_time
        first_name
        last_name
        client {
          first_name
          last_name
        }
        appointment_type {
            id
            name
        }
      }
  }
  getWaitTimes(args: {orgId: $orgId, start: $start, end: $end}) {
    avg_lobby_time_min
    avg_resource_time_min
  }
}`;

const UPDATE_RESOURCE_QUEUE = gql`mutation UpdateQueue($resourceQueueId: uuid!, $resource_id: uuid!, $called_time: timestamptz!) {
    update_resource_queue_by_pk(pk_columns: {id: $resourceQueueId}, _set: {resource_id: $resource_id, called_time: $called_time}) {
      created_at
    }
}`;

const getData = (queue, data) => {
    let q = [];
    let late = [];

    if (queue.length > 0 || !data || !data.resource_queue) {
        return queue;
    }

    for (let rq of data.resource_queue) {
        const isOnTime = !!rq.start_time && Math.floor(((new Date(rq.created_at) - new Date(rq.start_time))/1000/60)) <= 0;
        if (isOnTime) {
            q.push(rq);
        } else {
            late.push(rq);
        }
    }

    for (let lrq of late) {
        q.push(lrq);
    }

    return q;
}

export default function WaitingRoom(props) {
    const { speak, voices } = useSpeechSynthesis();
    const [enableCalling, setEnableCalling] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [queue, setQueue] = React.useState([]);
    const [availableRooms, setAvailableRooms] = React.useState([]);
    const [arrived, setArrived] = React.useState({});
    const [lockedMap, setLockedMap] = React.useState({});
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    today.setHours(0, 0, 0);
    tomorrow.setHours(0, 0, 0);
    const [start, setStart] = React.useState(today);
    const [end, setEnd] = React.useState(tomorrow);
    const [open, setOpen] = React.useState(false);

    const [updateResourceQueue] = useMutation(UPDATE_RESOURCE_QUEUE, {
        refetchQueries: [
          {
              query: GET_ARRIVED,
              variables: { orgId: Memory.orgId, start: start, end: end },
          }
        ]
    });

    const { data, startPolling } = useQuery(GET_ARRIVED, {
        variables: { orgId: Memory.orgId, start: start, end: end },
        fetchPolicy: "no-cache",
    });

    startPolling(500);

    React.useEffect(() => {
        if (enableCalling) {
            const interval = setInterval(() => {
                updateResourceAndSpeak();
            }, 8000);
            return () => clearInterval(interval);
        }
    }, [enableCalling, availableRooms, queue]);

    React.useEffect(() => {
        if (!!data) {
            let q = [];
            // let qMap = arrived;

            for (let rq of data.resource_queue) {
                // const earlyBird = !!rq.start_time && Math.floor(((new Date(rq.created_at) - new Date(rq.start_time))/1000/60)) <= -20; 
                // const isOnTime = !!rq.start_time && Math.floor(((new Date(rq.created_at) - new Date(rq.start_time))/1000/60)) <= 0;
                q.push(rq);

                if (enableCalling && rq.status === 'Locked' && !!rq.apt_code && lockedMap[rq.id] != 1) {
                    let newLockedMap = lockedMap;
                    speak({ text: 'Please see the front desk', voice: voices[0], rate: .85, pitch: 1 });

                    if (!!rq.group_code) {
                        for (let rqg of data.resource_queue) {
                            if (rqg.group_code === rq.group_code) {
                                newLockedMap[rqg.id] = 1;
                            }
                        }
                    } else {
                        newLockedMap[rq.id] = 1;
                    }

                    setLockedMap(newLockedMap);
                }
            }

            let rooms = !!data.resource && data.resource.filter(r => {
                if (r.status === 'Locked' || r.enable_routing === false) {
                    return false;
                }
    
                if (r.resource_queues === null) {
                    return true;
                }
    
                for (let rq of r.resource_queues) {
                    if (rq.done_time === null) {
                        return false;
                    }
                }
    
                return true;
            });

            setAvailableRooms(rooms);
            setQueue(q);
            // setArrived(qMap);
        }
    }, [data, lockedMap, enableCalling]);

    const handleSwitchChange = e => {
        setEnableCalling(e.target.checked);
    }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    async function updateResourceAndSpeak() {
        if (enableCalling && !!data && !!data.resource && data.resource.length > 0 && queue.length > 0) {
            const nextRoom = availableRooms[Math.floor(Math.random() * availableRooms.length)];
    
            if (!!nextRoom) {
                let rq = queue.find(e => e.status != 'Locked');;
    
                if (rq.called_time === null) {
                    let newLockedMap = lockedMap;

                    if(!!rq.group_code) {
                        let successCounter = 0;
                        let nonGrouped = [];

                        for(let i = 0; i < queue.length; i++) {
                            if (queue[i].group_code === rq.group_code) {
                                let resp = await updateResourceQueue({ variables: { resourceQueueId: queue[i].id, resource_id: nextRoom.id, called_time: new Date().toUTCString() } });
                                if (!!resp && !!resp.data && !!resp.data.update_resource_queue_by_pk) {
                                    successCounter++;
                                    // if (lockedMap[queue[i].id] === 1) {
                                    //     newLockedMap.delete(queue[i].id);
                                    // }
                                }
                            } else {
                                nonGrouped.push(queue[i]);
                            }
                        }
    
                        if (successCounter > 0) {
                            // setQueue(nonGrouped);
                            speak({ text: `${rq.client.first_name} ${rq.client.last_name}`, voice: voices[0], rate: .75, pitch: 1 })
                            speak({ text: nextRoom.calling_text, voice: voices[0], rate: .85, pitch: 1 })

                        }
                    } else {
                        let resp = await updateResourceQueue({ variables: { resourceQueueId: rq.id, resource_id: nextRoom.id, called_time: new Date().toUTCString() } });
    
                        if (!!resp && !!resp.data && !!resp.data.update_resource_queue_by_pk) {
                            // setQueue(queue.slice(1));
                            speak({ text: `${rq.client.first_name} ${rq.client.last_name}`, voice: voices[0], rate: .75, pitch: 1 })
                            speak({ text: nextRoom.calling_text, voice: voices[0], rate: .85, pitch: 1 })

                            // if (lockedMap[rq.id] === 1) {
                            //     newLockedMap.delete(rq.id);
                            // }
                        }
                    }
                }
            }
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
                <Grid container spacing={3} sx={{ height: '100%' }}>
                    <Grid xs={4}>
                        <Toolbar
                            sx={{
                                pr: { xs: 0 },
                                pl: { xs: 0 },
                            }}
                        >
                            <Typography
                                color="inherit"
                                variant="h5"
                                component="div"
                            >
                                {`Waiting`}
                            </Typography>
                        </Toolbar>
                        <Typography
                            sx={{ mb: 2 }}
                            color="inherit"
                            variant="h7"
                            component="div"
                        >
                            {!!data && !!data.getWaitTimes && !!data.getWaitTimes.avg_lobby_time_min ? `average time: ${data.getWaitTimes.avg_lobby_time_min} min` : ''}
                        </Typography>
                        <WaitingRoomLobbyTable data={!!data && !!data.resource_queue ? data.resource_queue : []} refetchQueries={[
                            {
                                query: GET_ARRIVED,
                                variables: { orgId: Memory.orgId, start: start, end: end },
                            }
                        ]}/>
                    </Grid>
                    <Grid xs={8}>
                        <Toolbar
                            sx={{
                                pr: { xs: 0 },
                                pl: { xs: 0 },
                            }}
                        >
                            <Typography
                                sx={{ flexGrow: 1 }}
                                color="inherit"
                                variant="h5"
                                component="div"
                            >
                                {`In Progress`}
                            </Typography>
                            <Box sx={{ mr: 5 }}>
                                <Button size='large' onClick={handleClickOpen}>Fullscreen</Button>
                            </Box>
                            <Box sx={{  }}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={enableCalling} onChange={(e) => handleSwitchChange(e)} />} label="Enable Calling" />
                                </FormGroup>
                            </Box>
                        </Toolbar>
                        <Typography
                            sx={{ mb: 2 }}
                            color="inherit"
                            variant="h7"
                            component="div"
                        >
                            {!!data && !!data.getWaitTimes && !!data.getWaitTimes.avg_resource_time_min ? `average room time: ${data.getWaitTimes.avg_resource_time_min} min` : ''}
                        </Typography>
                        {!!data && !!data.resource && data.resource.length > 0 && 
                            <WaitingRoomResourceTable data={data.resource} refetchQueries={[
                                {
                                    query: GET_ARRIVED,
                                    variables: { orgId: Memory.orgId, start: start, end: end },
                                }
                            ]}/>
                        }
                    </Grid>
                    {/* <Grid xs={2}>
                        <Typography
                            sx={{ flex: '1 1 100%', mb: 4 }}
                            color="inherit"
                            variant="h4"
                            component="div"
                        >
                            Done
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table
                                size="large"
                                aria-label="a dense table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!data && data.resource_queue.map((row, index) =>  (
                                        <TableRow
                                            hover
                                            sx={{ bgcolor: '#fff' }}
                                        >
                                            <TableCell
                                            component="th"
                                            scope="row"
                                            align='left'
                                            >
                                                {`${row.appointment_clients[0].client.first_name} ${row.appointment_clients[0].client.last_name}`}
                                            </TableCell>
                                            <TableCell align='right'>{row.appointment_type.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid> */}
                </Grid>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                >
                    {/* <AppBar sx={{ position: 'relative' }}> */}
                    <Toolbar>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                    {/* </AppBar> */}
                    {!!data && !!data.resource && data.resource.length > 0 && 
                        <WaitingRoomResourceTable hideDetails={true} data={data.resource.filter(r => r.enable_routing === true)} refetchQueries={[
                            {
                                query: GET_ARRIVED,
                                variables: { orgId: Memory.orgId, start: start, end: end },
                            }
                        ]}/>
                    }
                </Dialog>
        </Box>
    );
}