import React from 'react';
import axios from "axios";
import { useMutation, useQuery, gql } from '@apollo/client';
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Memory from '../Utils/Memory';

class Authenticator extends React.Component {
  constructor(props) {
    super();

    this.state = {
      redirect: false,
      message: undefined,
    }

    this.refreshToken = this.refreshToken.bind(this);
  }

  async refreshToken(){
    const token_response = await axios.post(`${process.env.REACT_APP_AUTH_SERVER_URI}/token/refresh`);

    if (!!token_response && !!token_response.data && !!token_response.data.access_token && !!token_response.data.id) {
      Memory.email = token_response.data.email;
      Memory.token = token_response.data.access_token;
      Memory.id = token_response.data.id;
    } else {
      Memory.email = undefined;
      Memory.token = undefined;
      Memory.id = undefined;
      this.setState({ redirect: true, message: 'Your session has expired' });
    }
  }

  render() {
    const { email, id, token } = Memory;
    let tokenIsExpired = false;

    try {
      if (token) {
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();
        tokenIsExpired = decodedToken.exp * 1000 < currentDate.getTime();
      }
    } catch(error) {
      console.log("Authenticator", error);
    }

    if (!email || !token || !id || tokenIsExpired) {
      this.refreshToken();
    }

    if (this.state.redirect) {
      return <Navigate to="/login" replace={true} state={{ message: 'Your session has expired' }}/>;
    }

    return (
      <React.Fragment>
        <GetUser id={id}>
          {this.props.children}
        </GetUser>
      </React.Fragment>
    );
  }
}

export default Authenticator;


const GET_USER = gql`
query GetUser($id: uuid!) {
  user_by_pk(id: $id) {
    last_login
    organization_id
  }
}`;

function GetUser(props) {
  const { email, id, token } = Memory;

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: props.id },
  });

  if (loading) return (
    <Grid xs={12}>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{height:'75vh'}}>
        <CircularProgress />
      </Grid>
    </Grid>
  );

  if (!email || !id || !token || !!error) {
    console.log(`Error! ${error.message}`);
    return <Navigate to="/login" replace={true} state={{ message: 'Your session has expired' }}/>;
  }

  if (!!data && !!data.user_by_pk && !!data.user_by_pk.organization_id) {
    Memory.orgId = data.user_by_pk.organization_id;
  }

  return props.children;
}